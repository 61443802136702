import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { format } from "date-fns";
import { useRecoilValue } from "recoil";

import getMonthlySales from "../../functions/getMonthlySales";
import { selectedEventIdState } from "../../recoil/events";
import { UserAccessTokenState } from "../../recoil/user";

import styles from "../../styles/dashboard/Dashboard.module.css";

export default function ProfitLossGraph() {
  const [data, setData] = useState([]);
  const selectedEvent = useRecoilValue(selectedEventIdState);
  const accessToken = useRecoilValue(UserAccessTokenState);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      if (!selectedEvent) return;
      if (!loading) {
        try {
          setLoading(true);
          const resp = await getMonthlySales(accessToken);
          setData(resp);
        } catch (err) {
          console.log(err);
        } finally {
          setLoading(false);
        }
      }
    })();
  }, [selectedEvent]);

  return (
    <div className={styles.profitLossGraphContainer}>
      <div className={styles.userDetailsItem}>Total Sales</div>
      <div className={styles.profitLossTextContainer}>
        <span className={styles.profitLossGraphItem}>USD:</span>
        <span className={styles.profitLossGraphValue}>
          $
          {Number(
            data?.reduce((acc, next) => acc + next.totalSales || 0, 0)
          ).toFixed(2)}
        </span>
      </div>
      <div className={styles.graphContainer}>
        <Graph
          data={data?.map((m) => Number(m.totalSales.toFixed(2)) || 0)}
          months={data?.map((m) =>
            format(new Date(`${m.year}/${m.month}/01`), "MMM")
          )}
        />
      </div>
    </div>
  );
}

const Graph = ({ data = [], months = [] }) => {
  // Data for the chart series
  const series = [
    {
      data,
      name: "Sales",
    },
  ];

  const options = {
    chart: {
      fontFamily: "inherit",
      id: "area-chart",
      toolbar: {
        show: false, // Hide the toolbar
      },
      zoom: {
        enabled: false, // Disable zooming
      },
    },
    dataLabels: {
      enabled: false, // Hide data labels
    },
    fill: {
      gradient: {
        colorStops: [
          {
            color: "#77F64A",
            offset: 0,
            opacity: 0.8,
          },
          {
            color: "#77F64A",
            offset: 100,
            opacity: 0,
          },
        ],
        opacityFrom: 0.8,
        opacityTo: 0,
        shade: "vertical",
        shadeIntensity: 1,
        stops: [0, 100],
      },
      type: "gradient",
    },
    grid: {
      borderColor: "#F0F0F0",
      xaxis: {
        lines: {
          show: true, // Show x-axis lines
        },
      },
    },
    stroke: {
      width: 0, // Set the border width to 0 to hide the border
    },
    theme: {
      monochrome: {
        color: "#77F64A",
        enabled: true,
        shadeIntensity: 0.65,
        shadeTo: "light",
      },
    },
    xaxis: {
      axisBorder: {
        show: false, // Hide the x-axis border
      },
      axisTicks: {
        // Hide the x-axis ticks,
        show: false,
      },
      categories: months,
      labels: {
        style: {
          colors: "#A3A3A3",
          fontSize: "10px",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "#A3A3A3",
          fontSize: "10px",
        },
      },
      tickAmount: 3,
    },
  };

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="area"
      height={"100%"}
    />
  );
};
