import React, { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";

import { selectedFairnessDataState } from "../../recoil/fairness";
import translate from "../../utils/temp/translate";
import Button from "../general/Button";
import DeleteButton from "../general/DeleteButton";
import SmallInput from "../general/SmallInput";

import styles from "../../styles/events/TicketSold.module.css";

export default function Spotify({ artistId }) {
  const [data, setData] = useState(artistId ? [{ name: artistId }] : []);
  const [search, setSearch] = useState("");
  const setFairnessData = useSetRecoilState(selectedFairnessDataState);

  useEffect(() => {
    setData(artistId ? [{ name: artistId }] : []);
  }, [artistId]);

  const handleAdd = () => {
    if (search) {
      setData([{ name: search }]);
      setFairnessData((prev) => ({ ...prev, spotifyArtistId: search }));
      setSearch("");
    }
  };

  const handleRemove = () => {
    setData([]);
    setFairnessData((prev) => ({ ...prev, spotifyArtistId: undefined }));
  };

  return (
    <div className={`${styles.bannerSection} ${styles.smHeight}`}>
      <div className={styles.header}>Spotify music tracking</div>
      <div className={styles.box}>
        <div>
          <div className="flex jc-sp">
            <SmallInput
              hideIcon
              inputStyles={{ color: "#000", fontSize: ".8125rem" }}
              containerStyles={{
                marginBottom: "10px",
                minWidth: "300px",
              }}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder={translate("Enter artist’s ID")}
            />
            <Button
              style={{
                height: "1.5rem",
              }}
              text={"Add"}
              onClick={handleAdd}
              customClass={"createCaptcha"}
            />
          </div>

          <div className={styles.boldText}>
            Artist that must be followed by all buyers in order to join the
            purchase of the event.
          </div>
        </div>

        <div>
          {data.map((item, id) => (
            <div key={id} className={styles.ipGrid}>
              <div className={styles.ipItem}>
                <div className={styles.userDetails}>
                  <div title={item.name} className={styles.userName}>
                    {item.name}
                  </div>
                </div>
              </div>
              <div>
                <DeleteButton text="Remove" onClick={handleRemove} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
