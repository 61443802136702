import React, { useState } from "react";

import Button from "../general/Button";
import SelectLabels from "../general/Select";
import CreateThemeModal from "../modals/createTheme/CreateTheme";

import styles from "../../styles/events/TicketSold.module.css";

export default function VirtualWaitingRoom({ data, dark }) {
  const [open, setOpen] = useState(false);
  const [isEditMode, toggleEditMode] = useState(false);
  const [editData, setEditData] = useState({});

  return (
    <div className={styles.bannerSection}>
      <div className={styles.header}>Virtual Waiting Room</div>
      <CreateThemeModal
        isEditMode={isEditMode}
        open={open}
        handleClose={() => setOpen(false)}
        payload={{
          editData: editData,
        }}
      />
      <div className={styles.box}>
        <div className={styles.bannerSection}>
          {/* <div className="flex">
            <div className={styles.themeSelect}>
              <SelectLabels
                sm
                placeholderText={"Select Theme"}
                value={selectedTheme}
                updateValue={(e) => setSelectedTheme(e.value)}
                options={themes}
              />
            </div>
            <Button
              text={"Create New"}
              onClick={() => {
                toggleEditMode(false);
                setEditData({});
                setOpen(true);
              }}
              customClass={"createCaptcha"}
            />
          </div> */}

          <div className={styles.banner}>
            <img className={styles.bannerImagePreview} src={data?.bgUrl} />
            <Button
              imgSrc={
                data ? (
                  <img src={require("../../assets/lock.svg").default} />
                ) : null
              }
              text={data ? "Edit theme" : "Create theme"}
              onClick={() => {
                {
                  setEditData({
                    progressDisplayOptions: {
                      "Show Progress": data?.options?.progressBar,
                      "Show Users Ahead": data?.options?.usersAhead,
                    },
                    themeData: {
                      background: data?.bgUrl,
                      logo: data?.logoUrl,
                      name: data?.themeName,
                    },
                    themeOptions: {
                      "Enable Email Notifications":
                        data?.options?.emailNotification,
                      "Show Dynamic Message": data?.options?.dynamicMessage,
                      "Show Estimate Wait Time": data?.options?.waitingTime,
                      "Show Language Selector": data?.options?.languageSelector,
                      "Show Logo": data?.options?.logo,
                      "Show Queue Number": data?.options?.queueNumber,
                    },
                  });
                  toggleEditMode(true);
                  setOpen(true);
                }
              }}
              customClass={!data ? "createCaptcha" : ""}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
