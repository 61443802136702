import React from "react";
import { toast } from "react-toastify";
import { useRecoilValue } from "recoil";

import { selectedEventDataState } from "../../recoil/events";
import { UserDataState } from "../../recoil/user";
import getDateFormatted from "../../utils/getDateFormatted";
import { ErrorToast, SuccessToast } from "../general/ToastNotification";

import styles from "../../styles/staffPass/StaffPass.module.css";

const Card = ({
  eventTitle,
  area,
  onAction,
  onDelete,
  // venue,
  time,
  isDesignCard,
}) => {
  const selectedEventData = useRecoilValue(selectedEventDataState);
  const userData = useRecoilValue(UserDataState);

  const handleScannerLinkCopy = () => {
    if (!userData._id) return ErrorToast("Error", "User not logged in");

    const data = {
      area,
      eventData: {
        dateStart: selectedEventData.eventStartDate,
        eventName: selectedEventData.eventName,
        thumbImage: selectedEventData.eventBanner,
      },
      eventId: selectedEventData._id,
      organiserId: userData._id,
    };

    const jsonString = JSON.stringify(data);
    const base64Encoded = btoa(jsonString);

    const url = `${process.env.REACT_APP_SCANNER_URL}?state=${base64Encoded}`;
    navigator.clipboard.writeText(url);
    SuccessToast("Copied to clipboard!");
  };

  return (
    <div className={styles.card}>
      <div className={styles.header}>
        {eventTitle}
        {!isDesignCard && (
          <div className={styles.time}>
            {/* {venue}, */}
            {getDateFormatted(new Date(time).toISOString())}
          </div>
        )}
      </div>
      <div className={styles.border} />
      <div className={styles.area}>
        {area}
        <div className={styles.iconChain}>
          <img
            src={require(isDesignCard
              ? "../../assets/colorPallete.webp"
              : "../../assets/link.webp")}
            alt="chain"
          />
        </div>
      </div>
      <div className={styles.actionButtons}>
        <button
          onClick={isDesignCard ? onAction : handleScannerLinkCopy}
          className={`${styles.button} ${styles.copy}`}
        >
          {isDesignCard ? "EDIT DESIGN" : "COPY SCANNER LINK"}
        </button>
        <button
          onClick={onDelete}
          className={`${styles.button} ${styles.delete}`}
        >
          DELETE {isDesignCard ? "DESIGN" : "SCANNER"}
        </button>
      </div>
    </div>
  );
};

export default Card;
