import axios from "axios";

export default async function editEventAreas(access_token, eventId, data) {
  if (!data.startTime || !data.endTime)
    throw new Error("Start Time and End Time is required");
  if (!data.areaNames || data?.areaNames.length == 0)
    throw new Error("Atleast one Area Name is required");

  const resp = await axios.post(
    `${process.env.REACT_APP_ORGANISER_API}/staff-pass/config/edit/${eventId}`,
    {
      ...data,
    },
    {
      headers: {
        token: access_token,
      },
    }
  );

  if (!resp.data) {
    throw new Error("Some error occured");
  }
  if (resp.data.error) {
    throw new Error(resp.data.error);
  }

  return resp.data;
}
