import React from "react";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import { useRecoilState, useRecoilValue } from "recoil";

import Close from "../../../assets/close.svg";
import CloseDark from "../../../assets/closedark.svg";
import { moreEventDetailsModalState } from "../../../recoil/modal";
import { darkModeState } from "../../../recoil/settings";
import translate from "../../../utils/temp/translate";

import styles from "../../../styles/modals/Modal.module.css";

export default function MoreDetailsModal({ data }) {
  const dark = useRecoilValue(darkModeState);
  const [open, setOpen] = useRecoilState(moreEventDetailsModalState);

  const handleClose = () => {
    setOpen(false);
  };

  if (!data) return null;

  return (
    <Modal
      className={`${styles.modal} ${dark ? "dark" : ""}`}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
    >
      <Fade in={open}>
        <div className={styles.moreInfoBox}>
          <div className={styles.addEventModal}>
            <div className={styles.header}>
              <div>{translate(`More Event Details`)}</div>
              <img
                onClick={handleClose}
                src={dark ? CloseDark : Close}
                alt=""
              />
            </div>
            <div className={styles.eventDetailsCardContainer}>
              {data.map((item, index) => (
                <div key={index} className={styles.eventDetailsCard}>
                  <div className={styles.planType}>
                    <img src={require("../../../assets/explorer.png")} alt="" />
                    <div className={styles.headText}>
                      <div className={styles.heading}>{item.releaseType}</div>
                      <div className="subHeading">Release Type</div>
                    </div>
                  </div>
                  <div className={styles.eventBorder} />
                  <div className={styles.details}>
                    <div className={styles.contentBox}>
                      <div className={styles.detailLabel}>Sale Name</div>
                      <div className={styles.packageValue}>{item.saleName}</div>
                    </div>
                    <div className={styles.contentBox}>
                      <div className={styles.detailLabel}>
                        Checkout Per Account
                      </div>
                      <div className={styles.packageValue}>
                        {item.maxTicketPerTransaction === 0
                          ? "No Limit"
                          : item.maxTicketPerTransaction}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}
