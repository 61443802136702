import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { RecoilRoot, useRecoilState, useRecoilValue } from "recoil";
import { recoilPersist } from "recoil-persist";

import ProtectedRoute from "./components/auth/ProtectedRoute";
import GlobalLoader from "./components/general/GlobalLoader";
import Sidebar from "./components/general/Sidebar";
import { CurrencyProvider } from "./context/CurrencyContext";
import Customers from "./pages/Customers";
import Dashboard from "./pages/Dashboard";
import Events from "./pages/Events";
import EventScanner from "./pages/EventScanner";
import Fairness from "./pages/Fairness";
import Login from "./pages/Login";
import Pos from "./pages/Pos";
import Settings from "./pages/Settings";
import StaffPass from "./pages/StaffPass";
import Subscribe from "./pages/Subscribe";
import Wallet from "./pages/Wallet";
import Webpage from "./pages/Webpage";
import { darkModeState } from "./recoil/settings";
import { UserDataState } from "./recoil/user";

import "react-toastify/dist/ReactToastify.css";
import "./App.css";

const { persist } = recoilPersist();

function App() {
  const [currency, setCurrency] = useState("sgd");
  const [loggedIn, setLoggedIn] = useState(true);

  useEffect(() => {
    localStorage.setItem("currency", "sgd");
  }, []);

  const [user, setUser] = useState({
    userImg: "",
    userName: "",
  });

  const isOwner = true;

  const THEME = createTheme({
    typography: {
      fontFamily: `"Poppins", "Helvetica", "Arial", sans-serif`,
    },
  });

  return (
    <RecoilRoot initializeState={persist}>
      <CurrencyProvider>
        <GlobalLoader />
        <ThemeProvider theme={THEME}>
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            theme="colored"
            pauseOnHover
          />
          <AppContainer
            user={user}
            currency={currency}
            setCurrency={setCurrency}
          />
        </ThemeProvider>
      </CurrencyProvider>
    </RecoilRoot>
  );
}

const AppContainer = ({ user, currency, setCurrency }) => {
  const [userData, setUserData] = useRecoilState(UserDataState);
  const dark = useRecoilValue(darkModeState);

  return (
    <div className={`App ${dark ? "dark" : ""}`}>
      <div className="main-container">
        <Routes>
          <Route path="/login" />
          <Route path="/logout" />
          <Route path="/dash" element={<Sidebar />} />
          <Route path="/events" element={<Sidebar />} />
          <Route path="/scanner" element={<Sidebar />} />
          <Route path="/fairness" element={<Sidebar />} />
          <Route path="/pos" element={<Sidebar />} />
          <Route path="/webpage" element={<Sidebar />} />
          <Route path="/staff-pass" element={<Sidebar />} />
          <Route path="/customers" element={<Sidebar />} />
          <Route path="/wallet" element={<Sidebar />} />
          <Route path="/settings" element={<Sidebar />} />
          <Route path="/subscribe" element={<Subscribe />} />
          <Route path="*" element={<Navigate to="/dash" replace />} />
        </Routes>

        <Routes>
          <Route
            path="/dash"
            element={<ProtectedRoute element={<Dashboard />} />}
          />
          <Route
            path="/customers"
            element={
              <ProtectedRoute element={<Customers userData={userData} />} />
            }
          />
          <Route
            path="/wallet"
            element={
              <ProtectedRoute element={<Wallet userData={userData} />} />
            }
          />
          <Route path="/fairness" element={<Fairness userData={userData} />} />
          <Route
            path="/pos"
            element={<ProtectedRoute element={<Pos userData={userData} />} />}
          />
          <Route
            path="/webpage"
            element={
              <ProtectedRoute element={<Webpage userData={userData} />} />
            }
          />
          <Route
            path="/staff-pass"
            element={
              <ProtectedRoute element={<StaffPass userData={userData} />} />
            }
          />
          <Route
            path="/scanner"
            element={
              <ProtectedRoute element={<EventScanner userData={userData} />} />
            }
          />
          <Route
            path="/events"
            element={
              <ProtectedRoute element={<Events userData={userData} />} />
            }
          />
          <Route
            path="/settings"
            element={
              <Settings
                currency={currency}
                setCurrency={setCurrency}
                user={userData}
              />
            }
          />
          <Route path="/login" element={<Login />} />
        </Routes>
      </div>
    </div>
  );
};

export default App;
