import React from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import { ArrowRight, CartIcon, IngeniumLogo } from "../../constants/Svgs";
import {
  footerLinksState,
  heroState,
  logoState,
  themeColorsState,
} from "../../recoil/webpage";

import AddLogo from "./Modals/AddLogo";
import EditAccentColor1 from "./Modals/EditAccentColor1";
import EditBackgroundColor from "./Modals/EditBackgroundColor";
import EditFooter from "./Modals/EditFooter";
import EditText1 from "./Modals/EditText1";
import EditText2 from "./Modals/EditText2";
import EditTextColor1 from "./Modals/EditTextColor1";
import EditTextColor2 from "./Modals/EditTextColor2";

import styles from "../../styles/webpage/Theme2.module.css";

const Banner = () => {
  const logoUrl = useRecoilValue(logoState);

  return (
    <div>
      <div className={styles.strip} />
      <div className={styles.banner}>
        <div />
        <div className={styles.logoContainer}>
          <AddLogo />
          <img className={styles.logo} src={logoUrl} alt="Banner Logo" />
        </div>
        <div className={styles.rightSection}>
          <div className={styles.textContainer}>
            <a href="#events" className={styles.description}>
              Events
            </a>
            <a href="#merchandise" className={styles.description}>
              Merchandises
            </a>
          </div>
          <div className={styles.cartContainer}>
            <CartIcon />
          </div>
        </div>
      </div>
    </div>
  );
};

const Hero = () => {
  const { heroImage, primaryText, secondaryText } = useRecoilValue(heroState);
  const [theme, setTheme] = useRecoilState(themeColorsState);

  return (
    <div className={styles.hero}>
      <div className={styles.heroTextContainer}>
        <EditBackgroundColor
          payload={{
            setValue: (value) => setTheme({ ...theme, backgroundColor: value }),
            value: theme.backgroundColor,
          }}
          positionStyles={{
            left: "35%",
            top: "1rem",
          }}
        />
        <div className={styles.heroText}>
          <div className={styles.primaryText}>
            <EditText1
              positionStyles={{
                left: "0",
                top: "-24px",
              }}
            />
            {primaryText}
          </div>
          <div className={styles.secondaryText}>
            <EditText2
              positionStyles={{
                bottom: "-40px",
                left: "0",
              }}
            />
            {secondaryText}
          </div>
        </div>
      </div>
      <div className={styles.heroImageContainer}>
        <img className={styles.heroImage} src={heroImage} alt="Card Image" />
      </div>
    </div>
  );
};

const SectionDisplay = ({ text, id }) => {
  const { accentColor1 } = useRecoilValue(themeColorsState);
  return (
    <div
      className={styles.section}
      id={id}
      style={{
        background: accentColor1,
      }}
    >
      <div className={styles.sectionTitle}>{text}</div>
    </div>
  );
};

const Event = ({ name, date, time, location, price, viewDetailsLink, img }) => {
  const { accentColor2 } = useRecoilValue(themeColorsState);

  return (
    <article className={styles.event}>
      <img
        className={styles.eventImage}
        src="https://placeholder.com/300"
        alt={img}
      />
      <div className={styles.eventDescription}>
        <div
          className={styles.eventDetailsContainer}
          style={{
            color: accentColor2,
          }}
        >
          <div>
            <div className={styles.eventName}>{name}</div>
            <div className={styles.eventDetails}>
              <p>
                {date}, {time}
              </p>
              <p>{location}</p>
              <p className={styles.eventPrice}>{price}</p>
            </div>
          </div>
          <div
            onClick={viewDetailsLink}
            role="button"
            className={styles.viewDetailsBtn}
            style={{
              borderColor: accentColor2,
              color: accentColor2,
            }}
          >
            View Details
            <ArrowRight fill={accentColor2} />
          </div>
        </div>
      </div>
    </article>
  );
};

const Product = ({ image, name, price, onAddToCart, type }) => {
  const { accentColor3 } = useRecoilValue(themeColorsState);

  return (
    <div
      className={styles.product}
      style={{
        color: accentColor3,
      }}
    >
      <img className={styles.productImage} src={image} alt={name} />
      <div className={styles.productDetails}>
        <div>
          <p className={styles.productName}>{name}</p>
          <p className={styles.productDescription}>{type}</p>
          <p className={styles.productPrice}>${price}</p>
        </div>
        <a
          onClick={onAddToCart}
          role="button"
          className={styles.viewDetailsBtn}
          style={{
            borderColor: accentColor3,
            color: accentColor3,
          }}
        >
          Add to Cart
          <ArrowRight fill={accentColor3} />
        </a>
      </div>
    </div>
  );
};

const Footer = () => {
  const logo = useRecoilValue(logoState);
  const socialLinks = useRecoilValue(footerLinksState);
  const [theme, setTheme] = useRecoilState(themeColorsState);

  return (
    <footer
      className={styles.footer}
      style={{
        backgroundColor: theme.footerBackground,
      }}
    >
      <EditFooter
        payload={{
          setValue: (value) =>
            setTheme((prev) => ({
              ...prev,
              footerBackground: value,
            })),
          value: theme.footerBackground,
        }}
      />
      <div className={styles.footerContainer}>
        <div className={styles.footerSection}>
          <h2 className={styles.footerHeading}>Events</h2>
          <h2 className={styles.footerHeading}>Merchandise</h2>
          <h2 className={styles.footerHeading}>Social</h2>
        </div>

        <div className={styles.footerSection}>
          <a href={socialLinks.facebook} className={styles.socialLink}>
            <span className={styles.socialIcon}>Facebook</span>
          </a>
          <a href={socialLinks.twitter} className={styles.socialLink}>
            <span className={styles.socialIcon}>Twitter X</span>
          </a>
          <a href={socialLinks.instagram} className={styles.socialLink}>
            <span className={styles.socialIcon}>Instagram</span>
          </a>
        </div>

        <div className={styles.footerLogoContainer}>
          <div className={styles.footerLogoWrapper}>
            <img className={styles.footerLogo} src={logo} alt="Footer Logo" />
          </div>
        </div>
      </div>
      <p className={styles.copyright}>
        <IngeniumLogo />© 2023 Ingenium. All rights reserved.
      </p>
    </footer>
  );
};

export default function Theme2() {
  const event = {
    date: "July 14, 2024",
    img: "Event Image",
    location: "Singapore Indoor Stadium",
    name: "Event Name",
    price: "$138 - $2388",
    time: "7:00 PM",
    viewDetailsLink: () => {},
  };
  const [theme, setTheme] = useRecoilState(themeColorsState);

  return (
    <div
      className={styles.page}
      style={{
        backgroundColor: theme?.backgroundColor,
      }}
    >
      <Banner />
      <Hero />
      <div
        style={{
          position: "relative",
        }}
      >
        <EditAccentColor1
          payload={{
            setValue: (value) => setTheme({ ...theme, accentColor1: value }),
            value: theme.accentColor1,
          }}
          positionStyles={{
            right: "15%",
            top: "45%",
          }}
        />
        <SectionDisplay text="Events" id="events" />
      </div>
      <div className={styles.events}>
        <EditTextColor1
          payload={{
            setValue: (value) => setTheme({ ...theme, accentColor2: value }),
            value: theme.accentColor2,
          }}
          positionStyles={{
            right: "15%",
            top: "2rem",
          }}
        />
        {[...Array(4)].map((_, i) => (
          <Event key={i} {...event} />
        ))}
      </div>
      <SectionDisplay text="Merchandises" id="merchandise" />
      <div className={styles.products}>
        <EditTextColor2
          payload={{
            setValue: (value) => setTheme({ ...theme, accentColor3: value }),
            value: theme.accentColor3,
          }}
          positionStyles={{
            left: "14%",
            top: "2rem",
          }}
        />
        <Product
          image="https://placeholder.com/300"
          name="Product Name"
          price="35"
          type={"White"}
          onAddToCart={() => {}}
        />
        <Product
          image="https://placeholder.com/300"
          name="Product Name"
          price="35"
          type={"White"}
          onAddToCart={() => {}}
        />
        <Product
          image="https://placeholder.com/300"
          name="Product Name"
          price="35"
          type={"White"}
          onAddToCart={() => {}}
        />
        <Product
          image="https://placeholder.com/300"
          name="Product Name"
          price="35"
          type={"White"}
          onAddToCart={() => {}}
        />
      </div>
      <Footer />
    </div>
  );
}
