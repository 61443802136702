import axios from "axios";

export default async function getRevenueData(access_token, id) {
  if (!id) return;
  const response = await axios.get(
    `${process.env.REACT_APP_ORGANISER_API}/event/revenue/${id}`,
    {
      headers: {
        token: access_token,
      },
    }
  );

  if (!response.data) {
    throw new Error("Some error occurred while fetching.");
  }
  if (response.data.error) {
    throw new Error(response.data.error);
  }

  return response.data;
}
