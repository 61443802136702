import axios from "axios";

export default async function getStaffList(access_token) {
  const resp = await axios.get(
    `${process.env.REACT_APP_ORGANISER_API}/pos/staff/list`,
    {
      headers: {
        token: access_token,
      },
    }
  );

  if (!resp.data) {
    throw new Error("Some error occured");
  }
  if (resp.data.error) {
    throw new Error(resp.data.error);
  }

  return resp.data;
}
