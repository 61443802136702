import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

export const UserDataState = atom({
  default: undefined,
  effects_UNSTABLE: [persistAtom],
  key: "UserDataState",
});

export const UserAccessTokenState = atom({
  default: "",
  effects_UNSTABLE: [persistAtom],
  key: "UserAccessTokenState",
});

export const FetchKeyState = atom({
  default: Date.now(),
  key: "FetchKeyState",
});
