import React, { useEffect, useState } from "react";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import { useRecoilValue, useSetRecoilState } from "recoil";

import Close from "../../../assets/close.svg";
import CloseDark from "../../../assets/closedark.svg";
import {
  saleIdState,
  selectedFairnessDataState,
} from "../../../recoil/fairness";
import { darkModeState } from "../../../recoil/settings";
import translate from "../../../utils/temp/translate";
import Button from "../../general/Button";
import CustomCheckbox from "../../general/Checkbox";
import Input from "../../general/Input";
import UploadButton from "../../general/Upload";

import styles from "../../../styles/modals/Modal.module.css";

const initialThemeData = {
  background: "",
  embedFiles: "",
  embedLinks: "",
  logo: "",
  name: "",
};

const initialProgressDisplayOptions = {
  "Show Progress": false,
  "Show Users Ahead": false,
};

const initialThemeOptions = {
  "Enable Email Notifications": false,
  "Show Dynamic Message": false,
  "Show Estimate Wait Time": false,
  "Show Language Selector": false,
  "Show Logo": false,
  "Show Queue Number": false,
};

export default function CreateThemeModal({
  isEditMode,
  open,
  handleClose,
  payload,
}) {
  const { editData } = payload;
  const dark = useRecoilValue(darkModeState);
  const selectedSaleId = useRecoilValue(saleIdState);
  const setFairnessData = useSetRecoilState(selectedFairnessDataState);
  const [themeData, setThemeData] = useState(initialThemeData);
  const [progressDisplayOptions, setProgressDisplayOptions] = useState({
    ...editData?.progressDisplayOptions,
  });
  const [themeOptions, setThemeOptions] = useState({
    ...editData?.initialThemeOptions,
  });

  // console.log({ progressDisplayOptions, themeData, themeOptions });

  const handleProgressDisplayOptionsChange = (key) => {
    setProgressDisplayOptions((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const handleThemeOptionsChange = (key) => {
    setThemeOptions((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  useEffect(() => {
    if (isEditMode) {
      setThemeData(editData?.themeData);
      setProgressDisplayOptions(editData?.progressDisplayOptions);
      setThemeOptions(editData?.themeOptions);
    } else {
      setThemeData(initialThemeData);
      setProgressDisplayOptions(initialProgressDisplayOptions);
      setThemeOptions(initialThemeOptions);
    }
  }, [isEditMode, editData]);

  const handleSave = () => {
    const options = {
      dynamicMessage: !!themeOptions["Show Dynamic Message"],
      emailNotification: !!themeOptions["Enable Email Notifications"],
      languageSelector: !!themeOptions["Show Language Selector"],
      logo: !!themeOptions["Show Logo"],
      progressBar: !!progressDisplayOptions["Show Progress"],
      queueNumber: !!themeOptions["Show Queue Number"],
      usersAhead: !!progressDisplayOptions["Show Users Ahead"],
      waitingTime: !!themeOptions["Show Estimate Wait Time"],
    };
    setFairnessData((prev) => ({
      ...prev,
      waitingRoomTheme: {
        bgUrl: themeData.background,
        logoUrl: themeData.logo,
        options,
        themeName: themeData.name,
      },
    }));
    handleClose();
  };

  return (
    <Modal className={` ${dark ? "dark" : ""}`} open={open}>
      <Fade
        style={{
          maxWidth: "72.0625rem",
        }}
        in={open}
      >
        <div className={`${styles.previewBox} ${styles.captcha}`}>
          <div className={styles.previewHeader}>
            <div>
              {translate(isEditMode ? `Edit Theme` : `Create New Theme`)}
            </div>
            <img
              onClick={() => {
                handleClose();
                setThemeData(initialThemeData);
                setProgressDisplayOptions(initialProgressDisplayOptions);
                setThemeOptions(initialThemeOptions);
              }}
              src={dark ? CloseDark : Close}
              alt=""
            />
          </div>

          <div className={styles.themeContent}>
            <div className={styles.form}>
              <div>
                <div className={styles.label}> Name </div>
                <Input
                  name={"Theme Name"}
                  placeholder={"Custom theme"}
                  value={themeData.name}
                  updateValue={(e) => {
                    setThemeData((prevState) => ({
                      ...prevState,
                      name: e.target.value,
                    }));
                  }}
                />
              </div>
              <div>
                <div className={styles.label}>Logo </div>
                <UploadButton
                  saleId={selectedSaleId}
                  content={"logo"}
                  btnName={
                    editData?.themeData?.logo
                      ? editData?.themeData?.logo
                      : "Upload Image"
                  }
                  updateThemeData={(url) => {
                    setThemeData((prev) => ({ ...prev, logo: url }));
                  }}
                />
              </div>

              <div>
                <div className={styles.label}>Background </div>
                <UploadButton
                  saleId={selectedSaleId}
                  content={"background"}
                  btnName={
                    editData?.themeData?.background
                      ? editData?.themeData?.background
                      : "Upload Image"
                  }
                  updateThemeData={(url) => {
                    setThemeData((prev) => ({ ...prev, background: url }));
                  }}
                />
              </div>

              {/* <div>
                <div className={styles.label}>Embed Files </div>
                <UploadButton btnName={"Upload File"} />
              </div>

              <div>
                <div className={styles.label}> Embed Links </div>
                <Input
                  name={"Embed Links"}
                  placeholder={"Enter url"}
                  value={themeData.embedLinks}
                  updateValue={(e) => {
                    setThemeData((prevState) => ({
                      ...prevState,
                      embedLinks: e.target.value,
                    }));
                  }}
                />
              </div> */}
            </div>

            <div className={styles.progressDisplayOptions}>
              <div className={styles.label}>Progress display option: </div>

              <div className={styles.checkboxGrid}>
                {Object.keys(progressDisplayOptions).map((key, index) => (
                  <CustomCheckbox
                    key={index}
                    label={key}
                    checked={progressDisplayOptions[key]}
                    handleChange={() => handleProgressDisplayOptionsChange(key)}
                  />
                ))}
              </div>
            </div>

            <div className={styles.themeOptions}>
              <div className={styles.label}>Theme Options: </div>
              <div className={styles.checkboxGrid}>
                {Object.keys(themeOptions).map((key, index) => (
                  <CustomCheckbox
                    key={index}
                    label={key}
                    checked={themeOptions[key]}
                    handleChange={() => handleThemeOptionsChange(key)}
                  />
                ))}
              </div>
            </div>
          </div>

          <div className={styles.btns}>
            <Button text={"Click to preview"} />
            <Button
              text={`${isEditMode ? "Save" : "Create New"}`}
              onClick={handleSave}
            />
          </div>
        </div>
      </Fade>
    </Modal>
  );
}
