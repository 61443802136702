import React from "react";
import { useRecoilValue } from "recoil";

import { eventType } from "../../recoil/events";

import BannerBottom from "./BannerBottom";
import EventDetails from "./EventDetails";
import SeatDetails from "./SeatDetails";

import styles from "../../styles/events/Banner.module.css";

function getUpcomingEvents(events) {
  const today = new Date();
  const filteredEvents = events.filter(
    (event) => new Date(event.saleStartDate) > today
  );
  return filteredEvents;
}

function getCompletedEvents(events) {
  const today = new Date();
  return events.filter((event) => new Date(event.saleEndDate) < today);
}

function getLiveSaleEvents(events) {
  const today = new Date();
  return events.filter((event) => {
    const saleStartDate = new Date(event.saleStartDate);
    const saleEndDate = new Date(event.saleEndDate);
    return saleStartDate <= today && saleEndDate >= today;
  });
}

function isEventLive(startDate, endDate) {
  const today = new Date();
  const saleStartDate = new Date(startDate);
  const saleEndDate = new Date(endDate);
  return saleStartDate <= today && saleEndDate >= today;
}

export default function Banner({
  data,
  handleSearchOpenOnInput,
  isFairnessPage,
}) {
  const allData = data?.sales;
  const eventTypeValue = useRecoilValue(eventType);

  const saleData = (() => {
    if (!allData) return [];
    else {
      if (eventTypeValue === "Upcoming Sale") {
        return getUpcomingEvents(allData);
      } else if (eventTypeValue === "Live Sale") {
        return getLiveSaleEvents(allData);
      } else if (eventTypeValue === "Completed Sales") {
        return getCompletedEvents(allData);
      } else if (
        eventTypeValue === "Live Sales" ||
        eventTypeValue === "Live Sale"
      ) {
        return isEventLive(data?.eventStartDate, data?.eventEndDate)
          ? allData
          : [];
      } else {
        return allData;
      }
    }
  })();

  return data ? (
    <div className={styles.bannerContainer}>
      <span onClick={handleSearchOpenOnInput} className={styles.left}>
        <img src={require("../../assets/Left.svg").default} alt="banner" />
      </span>
      <EventDetails data={data} />
      <div className={styles.seatDetailsContainer}>
        {saleData.map((sale, index) => (
          <SeatDetails key={index} data={sale} />
        ))}
      </div>
      <BannerBottom data={saleData} isFairnessPage={isFairnessPage} />
      <span onClick={handleSearchOpenOnInput} className={styles.right}>
        <img src={require("../../assets/Right.svg").default} alt="banner" />
      </span>
    </div>
  ) : (
    <div className={styles.bannerContainer}>
      <div className={styles.noEvents}>No Events Found</div>
    </div>
  );
}
