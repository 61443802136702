import axios from "axios";

export default async function addBankAccount(access_token, data) {
  console.log(data);
  if (!data.accountHolderName || !data.accountNumber || !data.bankName) {
    throw new Error("Please provide all required data");
  }

  const response = await axios.post(
    `${process.env.REACT_APP_ORGANISER_API}/bank/create`,
    { ...data },
    {
      headers: {
        token: access_token,
      },
    }
  );

  if (!response.data) {
    throw new Error("Some error occurred while fetching.");
  }
  if (response.data.error) {
    throw new Error(response.data.error);
  }

  return response.data;
}
