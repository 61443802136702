import axios from "axios";

export default async function createFairness(
  access_token,
  eventId,
  saleId,
  payload
) {
  if (!saleId || !eventId) throw new Error("sale id or event id not provided");
  const response = await axios.post(
    `${process.env.REACT_APP_ORGANISER_API}/fairness/create`,
    {
      accountCreationDate: payload.accountCreationDate,
      allowedCountries: payload.allowedCountries,
      captchaQuestions: payload.captchaQuestions?.map((q) => ({
        answers: q.answers,
        question: q.question,
      })),
      eventId,
      level: payload.level,
      saleId,
      spotifyArtistId: payload.spotifyArtistId,
      vipWhitelist: payload.vipWhitelist || [],
      waitingRoomTheme: payload.waitingRoomTheme,
    },
    {
      headers: {
        token: access_token,
      },
    }
  );

  if (!response.data) {
    throw new Error("Some error occurred while fetching.");
  }
  if (response.data.error) {
    throw new Error(response.data.error);
  }

  return response.data;
}
