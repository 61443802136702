import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { InfoSvg } from "../../constants/Svgs";
import { eventType } from "../../recoil/events";
import {
  acknowledgementModalState,
  moreEventDetailsModalState,
} from "../../recoil/modal";
import Button from "../general/Button";

import styles from "../../styles/events/Banner.module.css";

const UPCOMING_SALE = "Upcoming Sale";
const LIVE_SALE = "Live Sale";

const getColor = (type) => {
  switch (type) {
    case UPCOMING_SALE:
      return "#FFAA37";
    case LIVE_SALE:
      return "#12C519";
    default:
      return "#767BFA";
  }
};

export function findLatestNextSaleStartDate(sales) {
  const today = new Date();
  let latestNextSale = sales?.[0];

  for (const sale of sales) {
    const saleStartDate = new Date(sale.saleStartDate);

    if (
      saleStartDate > today &&
      (!latestNextSale ||
        saleStartDate < new Date(latestNextSale.saleStartDate))
    ) {
      latestNextSale = sale;
    }
  }

  return latestNextSale;
}

export default function BannerBottom({ data, isFairnessPage }) {
  const selectedEventType = useRecoilValue(eventType);
  const setMoreDetailsModalOpen = useSetRecoilState(moreEventDetailsModalState);
  const setAcknowledgeModalOpen = useSetRecoilState(acknowledgementModalState);

  const latestSale = findLatestNextSaleStartDate(data);

  const getSideText = () => {
    if (selectedEventType === UPCOMING_SALE) {
      return latestSale ? latestSale.saleName : "No upcoming sale";
    }
    if (selectedEventType === LIVE_SALE) {
      return latestSale ? latestSale.saleName : "No live sale";
    }
    return null;
  };

  return (
    <>
      {!isFairnessPage && <Info />}
      <div className={styles.bannerBottom}>
        <SaleType type={selectedEventType} sideText={getSideText()} />
        {!isFairnessPage && (
          <>
            <Button
              onClick={() => setMoreDetailsModalOpen(true)}
              imgSrc={
                <img
                  src={require("../../assets/batteryCharging.webp")}
                  alt="arrow-right"
                />
              }
              customClass={"bannerBottomButton"}
              text={"More event details"}
            />

            <Button
              disabled={
                selectedEventType === "Live Sales" ||
                selectedEventType === "Completed Sales"
              }
              onClick={() => setAcknowledgeModalOpen(true)}
              imgSrc={
                <img src={require("../../assets/edit.webp")} alt="edit" />
              }
              customClass={"bannerBottomButton"}
              text={"Request Edit"}
            />
          </>
        )}
      </div>
    </>
  );
}
const Info = () => {
  const [showInfo, setShowInfo] = useState(false);

  return (
    <div
      className={styles.info}
      onMouseEnter={() => setShowInfo(true)}
      onMouseLeave={() => setShowInfo(false)}
    >
      <InfoSvg />

      <AnimatePresence>
        {showInfo && (
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.5 }}
            className={styles.infoTextContainer}
          >
            <div className={styles.infoTextHeader}>Request Edit</div>
            <div className={styles.infoText}>
              -Organizers are NOT allowed to edit your events information 7 days
              before the event date.
            </div>
            <div className={styles.section}>
              <div className={styles.infoTextHeader}>Request Private Sale</div>
              <div className={styles.infoText}>
                -Organizers are allowed to request for private exclusive sale
                for early bird or allocation ticket to anyone. -A unique
                purchase link generated by Ingenium+ will be use to checkout.
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const SaleType = ({ sideText, type }) => {
  return (
    <>
      <div className={styles.saleType} style={{ color: getColor(type) }}>
        <span
          style={{
            textTransform: "uppercase",
          }}
        >
          {type}
        </span>
        <span>{`${sideText ? ": " + sideText : ""}`}</span>
      </div>
      <div
        className={styles.border}
        style={{ backgroundColor: getColor(type) }}
      />
    </>
  );
};
