import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import SmallInput from "../components/general/SmallInput";
import Topbar from "../components/general/Topbar";
import SearchModal from "../components/modals/search/SearchModal";
import RecentActivity from "../components/pos/RecentActivity";
import StaffList from "../components/pos/StaffList";
import SystemManagement from "../components/pos/SystemManagement";
import TxSummary from "../components/pos/TxSummary";
import getEvent from "../functions/getEvent";
import getCounterList from "../functions/pos/getCounterList";
import getStaffList from "../functions/pos/getStaffList";
import getTransactionSummary from "../functions/pos/getTransactionSummary";
import useSelectFirstEvent from "../hooks/useSelectFirstEvent";
import { selectedEventDataState, selectedEventIdState } from "../recoil/events";
import { globalLoadingState } from "../recoil/general";
import { searchModalState } from "../recoil/modal";
import {
  counterListState,
  counterRefetchKeyState,
  staffListState,
  staffRefetchKeyState,
} from "../recoil/pos";
import { darkModeState } from "../recoil/settings";
import { UserAccessTokenState } from "../recoil/user";
import translate from "../utils/temp/translate";

import styles from "../styles/eventScanner/EventScanner.module.css";
import posStyles from "../styles/pos/Pos.module.css";

export default function Pos({ userData }) {
  const [search, setSearch] = useState("");
  const dark = useRecoilValue(darkModeState);
  const setGlobalLoading = useSetRecoilState(globalLoadingState);
  const [counterList, setCounterList] = useRecoilState(counterListState);
  const [staffList, setStaffList] = useRecoilState(staffListState);
  const staffRefetchKey = useRecoilValue(staffRefetchKeyState);
  const counterRefetchKey = useRecoilValue(counterRefetchKeyState);
  const toggleState = useSetRecoilState(searchModalState);
  const selectedEvent = useRecoilValue(selectedEventIdState);
  const accessToken = useRecoilValue(UserAccessTokenState);
  const [counterIdFilter, setCounterIdFilter] = useState();
  const [summary, setSummary] = useState({
    totalSale: 0,
    totalTicketSold: 0,
    transactions: [],
  });

  useSelectFirstEvent();

  const handleSearchOpenOnInput = () => toggleState(true);

  useEffect(() => {
    (async () => {
      try {
        setGlobalLoading(true);
        const resp = await getCounterList(accessToken);
        setCounterList(resp);
      } catch (err) {
        console.log(err);
      } finally {
        setGlobalLoading(false);
      }
    })();
  }, [selectedEvent, counterRefetchKey]);

  useEffect(() => {
    (async () => {
      try {
        setGlobalLoading(true);
        const resp = await getTransactionSummary(
          accessToken,
          selectedEvent,
          counterIdFilter
        );
        setSummary(resp);
      } catch (err) {
        console.log(err);
      } finally {
        setGlobalLoading(false);
      }
    })();
  }, [selectedEvent, counterIdFilter]);

  useEffect(() => {
    (async () => {
      try {
        setGlobalLoading(true);
        const resp = await getStaffList(accessToken);
        setStaffList(resp);
      } catch (err) {
        console.log(err);
      } finally {
        setGlobalLoading(false);
      }
    })();
  }, [selectedEvent, staffRefetchKey]);

  return (
    <div className="page">
      <StaffList staffMembers={staffList} />
      <SearchModal />
      <Topbar
        dark={dark}
        pagename={translate("POS System")}
        userData={userData}
      />
      <div>
        <div className="add-btns mb-1 px">
          <div className="mr-14">
            <SmallInput
              disabled={true}
              onClick={handleSearchOpenOnInput}
              dark={dark}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder={translate("Search...")}
            />
          </div>
        </div>
        <div className={`${styles.summary} px`}>
          <div className={posStyles.left}>
            <SystemManagement counterList={counterList} />
            <TxSummary
              filter={counterIdFilter}
              setFilter={setCounterIdFilter}
              counterList={counterList}
              totalSale={summary?.totalSale || 0}
              transactions={summary?.transactions || []}
            />
          </div>
          <div className={styles.right}>
            <div className={`box box-container`}>
              <RecentActivity
                totalSale={summary.totalSale}
                totalTicketSold={summary.totalTicketSold}
                totalOpeningBalance={0}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
