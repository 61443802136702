import { useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";

import refreshIcon from "../assets/refresh.svg";
import refreshIconDark from "../assets/refreshdark.svg";
import CustomersSummary from "../components/customers/CustomersSummary";
import CustomerStats from "../components/customers/CustomerStats";
import Button from "../components/general/Button";
import SearchBtn from "../components/general/SearchBtn";
import Topbar from "../components/general/Topbar";
import useCustomerStats from "../hooks/useCustomerStats";
import { customersFetchKeyState } from "../recoil/customers";
import { darkModeState } from "../recoil/settings";
import translate from "../utils/temp/translate";

const Customers = ({ userData }) => {
  const [search, setSearch] = useState("");
  const dark = useRecoilValue(darkModeState);
  const stats = useCustomerStats();
  const setFetchKey = useSetRecoilState(customersFetchKeyState);

  return (
    <div className="page">
      <Topbar
        dark={dark}
        pagename={translate("customers")}
        userData={userData}
      />
      <div>
        <div className="add-btns mb-1 px">
          <div className="mr-14">
            <SearchBtn value={search} updateValue={setSearch} dark={dark} />
          </div>
          <div className="mr-14">
            <Button
              text={translate("refresh")}
              imgSrc={
                <img
                  src={dark ? refreshIconDark : refreshIcon}
                  alt="refresh Icon"
                />
              }
              onClick={() => setFetchKey((prev) => prev + 1)}
            />
          </div>
        </div>
        <div className="px">
          <CustomerStats
            totalAmountSpent={stats.totalCustomerSpendings}
            newCustomerToday={stats.totalCustomerToday}
            totalCustomers={stats.totalCustomer}
          />
        </div>
        <div className="px">
          <CustomersSummary />
        </div>
      </div>
    </div>
  );
};

export default Customers;
