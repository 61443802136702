import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

export const EventTypeOptions = [
  {
    label: "All Sales",
    value: "All Sales",
  },
  {
    label: "Upcoming Sale",
    value: "Upcoming Sale",
  },
  {
    label: "Live Sale",
    value: "Live Sale",
  },
  // {
  //   label: "Live Sales",
  //   value: "Live Sales",
  // },
  {
    label: "Completed Sales",
    value: "Completed Sales",
  },
];

export const selectedEventIdState = atom({
  default: null,
  effects_UNSTABLE: [persistAtom],
  key: "selectedEventIdState",
});

export const eventType = atom({
  default: EventTypeOptions[0].value,
  effects_UNSTABLE: [persistAtom],
  key: "eventType",
});

export const selectedEventDataState = atom({
  default: null,
  key: "selectedEventDataState",
});

export const searchResultsState = atom({
  default: [],
  key: "searchResultsState",
});
