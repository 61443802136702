import React, { useState } from "react";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { styled } from "@mui/styles";
import { useSetRecoilState } from "recoil";

import DownIcon from "../../assets/down.svg";
import DownIconDark from "../../assets/downdark.svg";
import Show from "../../assets/show.svg";
import { previewModalState } from "../../recoil/modal";

import styles from "../../styles/general/Select.module.css";

export default function SelectLabels({
  name,
  disabled,
  value,
  uploadLabel = "",
  uploadedUrl = "",
  uploadedFileName = "",
  handleUpload = () => {},
  hideUpload = false,
  updateValue,
  options,
  render,
  sm,
  color,
  placeholderText,
  none,
  dark,
  icon = false,
}) {
  const [isOpen, updateIsOpen] = useState(false);
  const setPreviewState = useSetRecoilState(previewModalState);

  const NewPlaceholder = styled("div")({
    color: dark ? "#5A5D74" : "#abafd1",
    fontFamily: "Poppins",
    fontSize: "0.9375rem",
    fontWeight: 300,
    lineHeight: "1.375rem",
    maxWidth: "90%",
    overflow: "hidden",
    textOverflow: "ellipsis",
  });

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      {icon && value === uploadedUrl && !hideUpload && (
        <div
          href="/"
          target="_blank"
          onClickCapture={handleUpload}
          className={styles.uploadSeatMap}
        >
          {uploadedFileName || "Upload SeatMap"}
        </div>
      )}

      <FormControl
        sx={{ m: 1, minWidth: "100%", padding: 0 }}
        className={styles.formControl}
        id="simple-select-form"
      >
        <Select
          className={sm && styles.parentSelect}
          style={{
            color: color ? color : dark ? "#f6f7ff" : "#abafd1",
          }}
          disabled={disabled}
          id="simple-select"
          name={name}
          displayEmpty
          value={value}
          sx={{
            backgroundColor: dark ? "#3B435E" : "none",
            maxHeight: "200px !important",
          }}
          onChange={(e) => {
            updateValue(e.target.value);
          }}
          inputProps={{
            "aria-label": "Without label",
            className: sm ? styles.smSelect : styles.select,
          }}
          renderValue={
            value !== ""
              ? render
              : () => <NewPlaceholder>{placeholderText}</NewPlaceholder>
          }
          onOpen={() => updateIsOpen(true)}
          onClose={() => updateIsOpen(false)}
          IconComponent={() => (
            <img
              style={{
                pointerEvents: "none",
                position: "absolute",
                right: "0.9375rem",
                transform: `rotate(${isOpen ? 180 : 0}deg)`,
                transition: `all ease .2s`,
              }}
              alt="down"
              src={!dark ? DownIcon : DownIconDark}
            />
          )}
        >
          {none && !hideUpload && (
            <MenuItem value={uploadedUrl} className={styles.menuitem}>
              <span>{uploadLabel || "None"}</span>
            </MenuItem>
          )}
          {options.map((option, i) => (
            <MenuItem
              key={i}
              style={{
                color: option?.color
                  ? option?.color
                  : dark
                  ? "#f6f7ff"
                  : "#abafd1",
              }}
              value={option.value}
              className={`${styles.menuitem} ${
                icon ? styles.menuitemWithIcon : ""
              }`}
            >
              {!icon ? (
                option.label
              ) : (
                <div className={styles.labelWithIcon}>
                  {option.label}{" "}
                  <img
                    src={Show}
                    onClick={() => {
                      setPreviewState(true);
                    }}
                  />
                </div>
              )}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
