import React, { useState } from "react";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import { useRecoilState, useRecoilValue } from "recoil";
import { useFilePicker } from "use-file-picker";

import Close from "../../../assets/close.svg";
import CloseDark from "../../../assets/closedark.svg";
import { AddIcon } from "../../../constants/Svgs";
import uploadThemeAsset from "../../../functions/webpage/uploadThemeAsset";
import { darkModeState } from "../../../recoil/settings";
import { UserAccessTokenState } from "../../../recoil/user";
import { heroState } from "../../../recoil/webpage";
import axiosErrorHandler from "../../../utils/axiosErrorHandler";
import Button from "../../general/Button";
import CircularLoader from "../../general/CircularLoader";
import { ErrorToast } from "../../general/ToastNotification";
import EditThemeHOC from "../EditThemeHOC";

import styles from "../../../styles/modals/Modal.module.css";

export default function AddImage({ positionStyles }) {
  const accessToken = useRecoilValue(UserAccessTokenState);
  const [open, setOpen] = useState(false);
  const dark = useRecoilValue(darkModeState);
  const [heroData, setHeroData] = useRecoilState(heroState);
  const [uploading, setUploading] = useState(false);

  const imageUrl = heroData.heroImage;
  const setImageUrl = (url) =>
    setHeroData((prev) => ({ ...prev, heroImage: url }));

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpload = async (file) => {
    if (!uploading) {
      try {
        setUploading(true);
        const url = await uploadThemeAsset(accessToken, file, "heroImage");
        setImageUrl(`${url}?t=${Date.now()}`);
        handleClose();
      } catch (e) {
        const msg = axiosErrorHandler(e);
        ErrorToast("Upload Error", msg);
      } finally {
        setUploading(false);
      }
    }
  };

  const [openFileSelector, { filesContent }] = useFilePicker({
    accept: "image/*",
    limitFilesConfig: { max: 1 },
    maxFileSize: 50,
    multiple: false,
    onFilesSuccessfulySelected: async ({ plainFiles }) => {
      await handleUpload(plainFiles[0]);
    },
    readAs: "DataURL",
  });

  return (
    <EditThemeHOC>
      <div
        className={styles.buttonContainer}
        style={{
          ...positionStyles,
        }}
      >
        <Button onClick={() => setOpen(true)} text={"Change Image"} />
      </div>

      <Modal
        className={` ${dark ? "dark" : ""}`}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
      >
        <Fade in={open}>
          <div
            className={styles.webpageModal}
            style={{
              maxWidth: "21.875rem",
            }}
          >
            <div className={styles.previewHeader}>
              <div>Adding Image...</div>
              <img
                onClick={handleClose}
                src={dark ? CloseDark : Close}
                alt=""
              />
            </div>
            <div className={styles.content}>
              <div className={styles.label}>Add Image</div>
              <div
                className={styles.add}
                onClick={!uploading ? openFileSelector : null}
                style={{
                  background: `url(${imageUrl})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              >
                {uploading ? (
                  <CircularLoader loading={uploading} />
                ) : (
                  <AddIcon />
                )}
              </div>
              <div className="flex jc-fe">
                <Button text="Save" onClick={handleClose} />
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </EditThemeHOC>
  );
}
