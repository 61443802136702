import { useEffect } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import getEventsList from "../../../functions/getEventsList";
import {
  searchResultsState,
  selectedEventIdState,
} from "../../../recoil/events";
import { UserAccessTokenState } from "../../../recoil/user";

import styles from "../../../styles/modals/SearchModal.module.css";
export default function SearchResults({ search, dark, toggleState }) {
  // const { items: archive, refine: refineArchive } = useRefinementList({
  //   attribute: "archive",
  // });

  // useEffect(() => {
  //   // console.log(archive);
  //   refineArchive(false);
  // }, []);

  const [data, setData] = useRecoilState(searchResultsState);
  const accessToken = useRecoilValue(UserAccessTokenState);
  const setSelectedEventIdState = useSetRecoilState(selectedEventIdState);

  useEffect(() => {
    (async () => {
      try {
        const resp = await getEventsList(accessToken);
        setData(resp.events);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  const hits = [
    {
      dateStart: "2023-09-28T20:36:09.231Z",
      name: "Zouk CC presents Mambo Jambo with...",
      thumbImg:
        "https://i.ytimg.com/vi/KudedLV0tP0/hq720.jpg?sqp=-oaymwEhCK4FEIIDSFryq4qpAxMIARUAAAAAGAElAADIQj0AgKJD&rs=AOn4CLAcY80-tfHbki6RIYtHPVXRUfcOIg",
      venueLocation: "Singapore",
      venueName: "Singapore Indoor Stadium",
    },
    {
      dateStart: "2023-09-28T20:36:09.231Z",
      name: "Zouk CC presents Mambo Jambo with...",
      thumbImg:
        "https://i.ytimg.com/vi/KudedLV0tP0/hq720.jpg?sqp=-oaymwEhCK4FEIIDSFryq4qpAxMIARUAAAAAGAElAADIQj0AgKJD&rs=AOn4CLAcY80-tfHbki6RIYtHPVXRUfcOIg",
      venueLocation: "Singapore",
      venueName: "Singapore Indoor Stadium",
    },
    {
      dateStart: "2023-09-28T20:36:09.231Z",
      name: "Zouk CC presents Mambo Jambo with...",
      thumbImg:
        "https://i.ytimg.com/vi/KudedLV0tP0/hq720.jpg?sqp=-oaymwEhCK4FEIIDSFryq4qpAxMIARUAAAAAGAElAADIQj0AgKJD&rs=AOn4CLAcY80-tfHbki6RIYtHPVXRUfcOIg",
      venueLocation: "Singapore",
      venueName: "Singapore Indoor Stadium",
    },
  ];
  // const { hits, results, isLastPage, showMore } = useInfiniteHits();
  // console.log(hits);

  function handleSelect(id) {
    toggleState(false);
    setSelectedEventIdState(id);
  }

  const filteredData = data
    ? data.filter((item) => {
        return item.eventName.toLowerCase().includes(search.toLowerCase());
      })
    : [];

  return (
    <div className={styles.searchResults}>
      {filteredData?.map((item, index) => {
        return (
          <div
            key={index}
            className={styles.ticketContainer}
            onClick={() => handleSelect(item._id)}
          >
            <div className={`${styles.ticket} ${dark ? "dark" : ""}`}>
              <img
                className={styles.ticketImg}
                alt="ticket-img"
                src={item?.eventBanner}
              />

              <div className={`${styles.ticketInfo} ${dark ? "dark" : ""}`}>
                <div className={styles.ticketTitle}>{item.eventName}</div>
                <div className={styles.ticketDesc}>
                  {item.venueName}, {item.venueLocation}
                </div>
                <div className={styles.ticketDate}>
                  {new Date(item.eventStartDate).toLocaleString("en-US", {
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    month: "short",
                    weekday: "short",
                    year: "numeric",
                  })}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
