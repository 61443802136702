import React from "react";
import { useRecoilState } from "recoil";

import { currentThemeState } from "../../recoil/webpage";

import styles from "../../styles/webpage/Webpage.module.css";

export default function ThemeCardComponent({ theme, backgroundImg }) {
  const [currentTheme, setActiveTheme] = useRecoilState(currentThemeState);
  return (
    <div
      className={styles.box}
      onClick={() => {
        setActiveTheme(theme);
      }}
    >
      <div
        className={styles.themeComponent}
        style={{
          background: `url(${backgroundImg})`,
          backgroundSize: "cover",
        }}
      >
        <span>
          {theme === currentTheme
            ? "You are currently using this theme."
            : "Choose this theme."}
        </span>
      </div>
    </div>
  );
}
