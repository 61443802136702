import moment from "moment-timezone";

function getTimeAgo(timestamp, text = "") {
  const currentDate = new Date(); // Get the current date in UTC

  const date = new Date(moment.utc(timestamp).format("MMM D YYYY HH:mm"));

  // Calculate the difference in seconds
  const differenceInSeconds = (date.getTime() - currentDate.getTime()) / 1000;

  if (date < currentDate) {
    return text;
  }

  // If the difference is less than a minute, show "just now"
  if (differenceInSeconds < 60) {
    return "Just Now";
  }

  // Use moment to format the timestamp into a "time ago" format
  return moment(date.getTime()).from(moment(currentDate.getTime()));
}

export default getTimeAgo;
