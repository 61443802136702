import React from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import { CartIcon, IngeniumLogo } from "../../constants/Svgs";
import {
  footerLinksState,
  heroState,
  logoState,
  themeColorsState,
} from "../../recoil/webpage";

import AddImage from "./Modals/AddingImage";
import AddLogo from "./Modals/AddLogo";
import EditBackgroundColor from "./Modals/EditBackgroundColor";
import EditFooter from "./Modals/EditFooter";
import EditText1 from "./Modals/EditText1";
import EditText2 from "./Modals/EditText2";

import styles from "../../styles/webpage/Theme3.module.css";

const Banner = () => {
  const logoUrl = useRecoilValue(logoState);
  const [theme, setTheme] = useRecoilState(themeColorsState);

  return (
    <div className={styles.banner}>
      <EditBackgroundColor
        payload={{
          setValue: (value) => setTheme({ ...theme, backgroundColor: value }),
          value: theme.backgroundColor,
        }}
        positionStyles={{
          left: "4%",
          top: "45%",
        }}
      />
      <div />
      <div className={styles.logoContainer}>
        <AddLogo />
        <img className={styles.logo} src={logoUrl} alt="Banner Logo" />
      </div>
      <div className={styles.rightSection}>
        <div className={styles.textContainer}>
          <a href="#events" className={styles.description}>
            Events
          </a>
          <a href="#merchandise" className={styles.description}>
            Merchandises
          </a>
        </div>
        <div className={styles.cartContainer}>
          <CartIcon />
        </div>
      </div>
    </div>
  );
};

const Hero = () => {
  const { heroImage, primaryText, secondaryText } = useRecoilValue(heroState);

  return (
    <div
      className={styles.hero}
      style={{
        backgroundImage: `url(${heroImage})`,
      }}
    >
      <AddImage
        positionStyles={{
          right: "20%",
          top: "50%",
        }}
      />
      <div className={styles.heroTextContainer}>
        <div className={styles.heroText}>
          <div className={styles.primaryText}>
            <EditText1
              positionStyles={{
                left: "0",
                top: "-30px",
              }}
            />
            {primaryText}
          </div>
          <div className={styles.secondaryText}>
            <EditText2
              positionStyles={{
                bottom: "-40px",
                left: "0",
              }}
            />
            {secondaryText}
          </div>
        </div>
      </div>
    </div>
  );
};

const SectionDisplay = ({ text, id }) => {
  return (
    <div className={styles.section} id={id}>
      <div className={styles.sectionTitle}>{text}</div>
    </div>
  );
};

const Event = ({ name, date, time, location, price, img }) => {
  return (
    <article className={styles.event}>
      <img
        className={styles.eventImage}
        src="https://placeholder.com/300"
        alt={img}
      />
      <div className={styles.eventDescription}>
        <div className={styles.eventDetailsContainer}>
          <div>
            <div className={styles.eventName}>{name}</div>
            <div className={styles.eventDetails}>
              <p>
                {date}, {time}
              </p>
              <p>{location}</p>
              <p className={styles.eventPrice}>{price}</p>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
};

const Product = ({ image, name, price }) => {
  return (
    <div className={styles.product}>
      <img className={styles.productImage} src={image} alt={name} />
      <div className={styles.productDetails}>
        <div>
          <p className={styles.productName}>{name}</p>
          <p className={styles.productPrice}>${price}</p>
        </div>
      </div>
    </div>
  );
};

const Footer = () => {
  const logo = useRecoilValue(logoState);
  const socialLinks = useRecoilValue(footerLinksState);
  const [theme, setTheme] = useRecoilState(themeColorsState);

  return (
    <footer
      className={styles.footer}
      style={{
        backgroundColor: theme.footerBackground,
      }}
    >
      <EditFooter
        positionStyles={{
          left: "1%",
          top: "1rem%",
        }}
        payload={{
          setValue: (value) => setTheme({ ...theme, footerBackground: value }),
          value: theme.footerBackground,
        }}
      />
      <div className={styles.footerContainer}>
        <div className={styles.footerSection}>
          <h2 className={styles.footerHeading}>Main Menu</h2>
          <h2 className={styles.socialLink}>EVENTS</h2>
          <h2 className={styles.socialLink}>MERCHANDISE</h2>
        </div>

        <div className={styles.footerSection}>
          <h2 className={styles.footerHeading}>Main Menu</h2>
          <a href={socialLinks.facebook} className={styles.socialLink}>
            <span className={styles.socialIcon}>Facebook</span>
          </a>
          <a href={socialLinks.twitter} className={styles.socialLink}>
            <span className={styles.socialIcon}>Twitter X</span>
          </a>
          <a href={socialLinks.instagram} className={styles.socialLink}>
            <span className={styles.socialIcon}>Instagram</span>
          </a>
        </div>

        <div className={styles.footerLogoContainer}>
          <div className={styles.footerLogoWrapper}>
            <img className={styles.footerLogo} src={logo} alt="Footer Logo" />
          </div>
        </div>
      </div>
      <p className={styles.copyright}>
        <IngeniumLogo />© 2023 Ingenium. All rights reserved.
      </p>
    </footer>
  );
};

export default function Theme3() {
  const event = {
    date: "July 14, 2024",
    img: "Event Image",
    location: "Singapore Indoor Stadium",
    name: "Event Name",
    price: "$138 - $2388",
    time: "7:00 PM",
  };
  const { backgroundColor } = useRecoilValue(themeColorsState);

  return (
    <div
      className={styles.page}
      style={{
        backgroundColor,
      }}
    >
      <Banner />
      <Hero />
      <SectionDisplay text="Events" id="events" />
      <div className={styles.events}>
        <Event {...event} />
        <Event {...event} />
        <Event {...event} />
        <Event {...event} />
        <Event {...event} />
        <Event {...event} />
        <Event {...event} />
        <Event {...event} />
        <div className={styles.border} />
      </div>
      <SectionDisplay text="Merchandises" id="merchandise" />
      <div className={styles.products}>
        <Product
          image="https://placeholder.com/300"
          name="Product Name"
          price="35"
          type={"White"}
          onAddToCart={() => {}}
        />
        <Product
          image="https://placeholder.com/300"
          name="Product Name"
          price="35"
          type={"White"}
          onAddToCart={() => {}}
        />
        <Product
          image="https://placeholder.com/300"
          name="Product Name"
          price="35"
          type={"White"}
          onAddToCart={() => {}}
        />
        <Product
          image="https://placeholder.com/300"
          name="Product Name"
          price="35"
          type={"White"}
          onAddToCart={() => {}}
        />
        <Product
          image="https://placeholder.com/300"
          name="Product Name"
          price="35"
          type={"White"}
          onAddToCart={() => {}}
        />
        <Product
          image="https://placeholder.com/300"
          name="Product Name"
          price="35"
          type={"White"}
          onAddToCart={() => {}}
        />
        <Product
          image="https://placeholder.com/300"
          name="Product Name"
          price="35"
          type={"White"}
          onAddToCart={() => {}}
        />
        <Product
          image="https://placeholder.com/300"
          name="Product Name"
          price="35"
          type={"White"}
          onAddToCart={() => {}}
        />
      </div>
      <Footer />
    </div>
  );
}
