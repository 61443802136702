import { useEffect, useMemo, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import Banner from "../components/events/Banner";
import RecentActivity from "../components/events/RecentActivity";
import AccountCreationDate from "../components/fairness/AccountCreationDate";
import Captcha from "../components/fairness/Captcha";
import FairnessSystemSetting from "../components/fairness/FairnessSystemSetting";
import Instagram from "../components/fairness/Instagram";
import IPRestriction from "../components/fairness/IPRestriction";
import LineGraph from "../components/fairness/LineGraph";
import Spotify from "../components/fairness/Spotify";
import Vip from "../components/fairness/Vip";
import VirtualWatiningRoom from "../components/fairness/VirtualWaitingRoom";
import Button from "../components/general/Button";
import {
  fairnessLevels,
  fairnessLevelSettings,
} from "../components/general/Progress";
import SelectLabels from "../components/general/Select";
import SmallInput from "../components/general/SmallInput";
import {
  ErrorToast,
  SuccessToast,
} from "../components/general/ToastNotification";
import Topbar from "../components/general/Topbar";
import AcknowledgementModal from "../components/modals/addEvent/AcknowledgementModal";
import EventDetailsModal from "../components/modals/addEvent/EventDetailsModal";
import MoreDetailsModal from "../components/modals/addEvent/MoreDetailsModal";
import SearchModal from "../components/modals/search/SearchModal";
import createFairness from "../functions/fairness/createFairness";
import disableFairnessSystem from "../functions/fairness/disableFairnessSystem";
import editFairness from "../functions/fairness/editFairness";
import getFairnessData from "../functions/fairness/getFairnessData";
import getEvent from "../functions/getEvent";
import useSelectFirstEvent from "../hooks/useSelectFirstEvent";
import {
  eventType,
  EventTypeOptions,
  selectedEventDataState,
  selectedEventIdState,
} from "../recoil/events";
import {
  fairnessFetchKeyState,
  fairnessLevelState,
  saleIdState,
  selectedFairnessDataState,
} from "../recoil/fairness";
import { globalLoadingState } from "../recoil/general";
import { searchModalState } from "../recoil/modal";
import { darkModeState } from "../recoil/settings";
import { UserAccessTokenState } from "../recoil/user";
import axiosErrorHandler from "../utils/axiosErrorHandler";
import translate from "../utils/temp/translate";

import styles from "../styles/events/Events.module.css";

const Fairness = ({ userData }) => {
  const [search, setSearch] = useState("");
  const [globalLoading, setGlobalLoading] = useRecoilState(globalLoadingState);
  const [eventTypes, setEventTypes] = useRecoilState(eventType);
  const [selectedEventData, setSelectedEventData] = useRecoilState(
    selectedEventDataState
  );
  const [saleId, setSaleId] = useRecoilState(saleIdState);
  const accessToken = useRecoilValue(UserAccessTokenState);
  const toggleState = useSetRecoilState(searchModalState);
  const dark = useRecoilValue(darkModeState);
  const selectedEvent = useRecoilValue(selectedEventIdState);
  const [fairnessData, setFairnessData] = useRecoilState(
    selectedFairnessDataState
  );
  const [fetchKey, setFetchKey] = useRecoilState(fairnessFetchKeyState);
  const selectedFairnessIndex = useRecoilValue(fairnessLevelState);
  const fairnesslevel = fairnessLevels[selectedFairnessIndex].label;
  const currentSettings = fairnessLevelSettings[fairnesslevel];

  const sales = selectedEventData?.sales;
  const saleData = sales?.find((s) => s?._id === saleId) || {};
  const saleOptions = useMemo(() => {
    return (
      sales?.map((sale) => ({ label: sale?.saleName, value: sale?._id })) || []
    );
  }, [sales]);
  const queueId = saleData?.queueId;

  useSelectFirstEvent();

  // console.log({ queueId, saleData, selectedEventData });

  const handleSearchOpenOnInput = () => toggleState(true);
  const refresh = () => {
    setFetchKey((prev) => prev + 1);
  };

  useEffect(() => {
    (async () => {
      if (!selectedEvent) return;
      try {
        setGlobalLoading(true);
        const resp = await getEvent(accessToken, selectedEvent);
        setSelectedEventData(resp);
      } catch (err) {
        console.log(err);
      } finally {
        setGlobalLoading(false);
      }
    })();
  }, [selectedEvent, fetchKey]);

  useEffect(() => {
    (async () => {
      try {
        setGlobalLoading(true);
        const resp = await getFairnessData(accessToken, saleId, selectedEvent);
        setFairnessData(resp);
      } catch (err) {
        console.log(err);
      } finally {
        setGlobalLoading(false);
      }
    })();
  }, [saleId, selectedEvent]);

  // console.log({ fairnessData });

  const handleSaleSelect = (e) => {
    setSaleId(e);
  };

  const handleCreateFairness = async () => {
    if (!saleId || !selectedEvent || globalLoading) return;
    try {
      setGlobalLoading(true);
      const resp = await createFairness(
        accessToken,
        selectedEvent,
        saleId,
        fairnessData
      );
      SuccessToast("Success", "Fairness System Enabled");
      refresh();
    } catch (err) {
      const msg = axiosErrorHandler(err);
      ErrorToast(
        "Error",
        msg.includes("Error validating request body")
          ? "Some data are not provided"
          : msg
      );
    } finally {
      setGlobalLoading(false);
    }
  };

  const handleUpdateFairness = async () => {
    if (!saleId || !selectedEvent || globalLoading) return;
    try {
      setGlobalLoading(true);
      const resp = await editFairness(accessToken, queueId, fairnessData);
      SuccessToast("Success", "Fairness System Updated");
      refresh();
    } catch (err) {
      const msg = axiosErrorHandler(err);
      ErrorToast(
        "Error",
        msg.includes("Error validating request body")
          ? "Some data are not provided"
          : msg
      );
    } finally {
      setGlobalLoading(false);
    }
  };

  const handleDisableFairness = async () => {
    if (!queueId || globalLoading) return;
    try {
      setGlobalLoading(true);
      const resp = await disableFairnessSystem(accessToken, queueId);
      SuccessToast("Success", "Fairness System Disabled");
      refresh();
    } catch (err) {
      const msg = axiosErrorHandler(err);
      ErrorToast("Error", msg);
    } finally {
      setGlobalLoading(false);
    }
  };

  return (
    <div className="page">
      <EventDetailsModal />
      <MoreDetailsModal data={selectedEventData?.sales} />
      <AcknowledgementModal />
      <SearchModal />
      <Topbar
        dark={dark}
        pagename={translate("Fairness System")}
        userData={userData}
      />
      <div className={styles.btns}>
        <SmallInput
          disabled={true}
          onClick={handleSearchOpenOnInput}
          dark={dark}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder={translate("Search...")}
        />
        <SelectLabels
          placeholderText={"Events Type"}
          name={"eventTypes"}
          options={EventTypeOptions}
          dark={dark}
          updateValue={(e) => setEventTypes(e)}
          value={eventTypes}
          sm
        />
      </div>
      <div className={`${styles.container} px ${styles.fairness}`}>
        <div className={styles.left}>
          <Banner
            isFairnessPage
            handleSearchOpenOnInput={handleSearchOpenOnInput}
            data={selectedEventData}
          />
          <div className={styles.scroll}>
            <div className={styles.rowContainer}>
              {saleId && (
                <Button
                  onClick={
                    queueId ? handleDisableFairness : handleCreateFairness
                  }
                  disabled={globalLoading}
                  customClass={"enableFairness"}
                  imgSrc={<img src={require("../assets/power.svg").default} />}
                  text={`${queueId ? "Disable" : "Enable"} Fairness System`}
                />
              )}
              <div className={styles.selectSaleName}>
                <SelectLabels
                  name={"saleId"}
                  options={saleOptions}
                  sm
                  value={saleId}
                  updateValue={handleSaleSelect}
                  placeholderText={"Choose Sale Name"}
                  dark={dark}
                />
              </div>
              {queueId && (
                <Button
                  onClick={handleUpdateFairness}
                  disabled={globalLoading}
                  customClass={"updateFairness"}
                  imgSrc={
                    <img src={require("../assets/refresh.svg").default} />
                  }
                  text={`Update Settings`}
                />
              )}
            </div>
            <FairnessSystemSetting level={fairnessData?.level} />
            <div className={styles.setingsGrid}>
              <VirtualWatiningRoom
                data={fairnessData?.waitingRoomTheme}
                dark={dark}
              />

              {(currentSettings.spotify || currentSettings.accountCreation) && (
                <div>
                  {currentSettings.spotify && (
                    <Spotify artistId={fairnessData?.spotifyArtistId} />
                  )}
                  {currentSettings.accountCreation && (
                    <AccountCreationDate
                      timestamp={fairnessData?.accountCreationDate}
                    />
                  )}
                </div>
              )}
              {currentSettings.instagram && <Instagram />}

              {currentSettings.ipRestriction && (
                <IPRestriction
                  allowedCountries={fairnessData?.allowedCountries || []}
                />
              )}
              {currentSettings.captcha && (
                <Captcha
                  captchaQuestions={fairnessData?.captchaQuestions || []}
                />
              )}
              {currentSettings.whiteList && (
                <Vip whitelist={fairnessData?.vipWhitelist || []} />
              )}
            </div>
          </div>
        </div>
        <div className={`${styles.box} ${styles.right}`}>
          <RecentActivity />
          <LineGraph />
        </div>
      </div>
    </div>
  );
};

export default Fairness;
