import { useSetRecoilState } from "recoil";

import left from "../../assets/Left.svg";
import right from "../../assets/Right.svg";
import { searchModalState } from "../../recoil/modal";

import styles from "../../styles/eventScanner/EventDetails.module.css";

export default function EventDetails({
  dateStart,
  eventName,
  venue,
  thumbImage,
  handleSearchOpenOnInput,
}) {
  return (
    <div className={`${styles.container}`}>
      {eventName ? (
        <>
          <img
            onClick={handleSearchOpenOnInput}
            className={styles.arrowImage}
            src={left}
            alt="left"
          />
          <div className={styles.eventDate}>
            <div className={styles.month}>
              {new Date(dateStart)
                .toLocaleString("en-SG", { month: "long" })
                .toUpperCase()}
            </div>
            <div className={styles.eventName}>
              {new Date(dateStart).getDate()}
            </div>
          </div>
          <div className={styles.eventName}>{eventName}</div>
          <div className={`flex col al-st jc-se`}>
            <div className={styles.venueHeader}>Venue</div>
            <div className={styles.venueText}>{venue}</div>
          </div>
          <img
            className={styles.eventImage}
            height={96}
            width={144}
            src={thumbImage}
            alt="thumbnail"
          />
          <img
            onClick={handleSearchOpenOnInput}
            className={styles.arrowImage}
            src={right}
            alt="right"
          />
        </>
      ) : (
        <div className={styles.noEvents}>No Events Found</div>
      )}
    </div>
  );
}
