import React, { useEffect, useState } from "react";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import { useRecoilValue } from "recoil";

import Close from "../../../assets/close.svg";
import CloseDark from "../../../assets/closedark.svg";
import { Add } from "../../../constants/Svgs";
import { darkModeState } from "../../../recoil/settings";
import Button from "../../general/Button";
import ColorPickerWithPreview from "../../general/ColorPickerWithPreview";
import EditThemeHOC from "../EditThemeHOC";

import styles from "../../../styles/modals/Modal.module.css";

export default function EditTextColor2({ positionStyles, payload }) {
  const [open, setOpen] = useState(false);
  const dark = useRecoilValue(darkModeState);

  const [color, setColor] = useState(payload.value);

  useEffect(() => {
    setColor(payload.value);
  }, [payload.value, open]);

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <EditThemeHOC>
      <div
        className={styles.buttonContainer}
        style={{
          ...positionStyles,
        }}
      >
        <Button onClick={() => setOpen(true)} text={"Edit Text Colour 2"} />
      </div>

      <Modal
        className={` ${dark ? "dark" : ""}`}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
      >
        <Fade in={open}>
          <div className={styles.webpageModal}>
            <div className={styles.previewHeader}>
              <div>Editing Text Colour 2...</div>
              <img
                onClick={handleClose}
                src={dark ? CloseDark : Close}
                alt=""
              />
            </div>
            <div className={styles.content}>
              <div className={styles.pickerContainer}>
                <ColorPickerWithPreview
                  value={color}
                  onChange={(e) => setColor(e.target.value)}
                />
              </div>
              <div className="flex jc-fe">
                <Button
                  text="Save"
                  imgSrc={<Add />}
                  onClick={() => {
                    payload.setValue(color);
                    handleClose();
                  }}
                />
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </EditThemeHOC>
  );
}
