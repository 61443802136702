import React from "react";

import styles from "../../styles/staffPass/StaffPass.module.css";

export default function EmptyCardDesignPreview({ isNewPass }) {
  return (
    <div className={styles.emptyCardDesignPreview}>
      Choose {isNewPass ? "event" : "size"} to show preview
    </div>
  );
}
