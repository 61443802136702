import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";

import styles from "../../styles/general/Tooltip.module.css";

const Tooltip = ({ text }) => {
  const [show, setShow] = useState(false);
  return (
    <>
      <AnimatePresence>
        {show && (
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.2 }}
            className={styles.tooltipContent}
          >
            <div className={styles.tooltipText}>{text}</div>
          </motion.div>
        )}
      </AnimatePresence>
      <motion.svg
        onMouseEnter={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
        className={styles.tooltipIcon}
        width="13"
        height="13"
        viewBox="0 0 13 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="6.5"
          cy="6.5"
          r="6.125"
          stroke="#767BFA"
          strokeWidth="0.75"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.99992 9.20825C6.99992 9.48439 6.77606 9.70825 6.49992 9.70825C6.22378 9.70825 5.99992 9.48439 5.99992 9.20825V5.95825C5.99992 5.68211 6.22378 5.45825 6.49992 5.45825C6.77606 5.45825 6.99992 5.68211 6.99992 5.95825V9.20825ZM6.49992 4.87492C6.20076 4.87492 5.95825 4.63241 5.95825 4.33325C5.95825 4.0341 6.20076 3.79159 6.49992 3.79159C6.79907 3.79159 7.04159 4.0341 7.04159 4.33325C7.04159 4.63241 6.79907 4.87492 6.49992 4.87492Z"
          fill="#767BFA"
        />
      </motion.svg>
    </>
  );
};

export default Tooltip;
