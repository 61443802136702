import React from "react";

import styles from "../../styles/dashboard/Dashboard.module.css";

export default function Royalties() {
  return (
    <div className={`${styles.box} ${styles.royalties}`}>
      <div className={styles.comingSoon}>Coming Soon!</div>
    </div>
  );
}
