import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import deletePassDesign from "../../functions/staffPass/deletePassDesign";
import getPassDesigns from "../../functions/staffPass/getPassDesigns";
import { selectedEventIdState } from "../../recoil/events";
import { globalLoadingState } from "../../recoil/general";
import {
  cardDesignDataState,
  passDesignsRefetchKeyState,
} from "../../recoil/staff";
import { UserAccessTokenState } from "../../recoil/user";
import axiosErrorHandler from "../../utils/axiosErrorHandler";
import { ErrorToast } from "../general/ToastNotification";

import AddNewButton from "./AddNewButton";
import AddNewCardDesignModal, {
  STAFF_PASS_SIZES,
} from "./AddNewCardDesignModal";
import Card from "./Card";
import DeleteScanner from "./DeleteScanner";

import styles from "../../styles/staffPass/StaffPass.module.css";

export default function CardDesignSection() {
  const accessToken = useRecoilValue(UserAccessTokenState);
  const setGlobalLoading = useSetRecoilState(globalLoadingState);
  const selectedEventId = useRecoilValue(selectedEventIdState);
  const [fetchKey, setFetchKey] = useRecoilState(passDesignsRefetchKeyState);
  const [openDelete, setOpenDelete] = useState(false);
  const [cardsData, setCardsData] = useRecoilState(cardDesignDataState);
  const [selectedIndex, setSelectedIndex] = useState();
  const [addNewCardDesignModalOpen, setAddNewCardDesignModalOpen] =
    useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editPayload, setEditPayload] = useState({});

  const refetch = () => setFetchKey((prev) => prev + 1);

  const deleteCard = async (index) => {
    try {
      setGlobalLoading(true);
      const resp = await deletePassDesign(accessToken, cardsData?.[index]?._id);
      refetch();
    } catch (e) {
      const msg = axiosErrorHandler(e);
      ErrorToast("Error", msg);
    } finally {
      setGlobalLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      if (selectedEventId) {
        try {
          setGlobalLoading(true);
          const resp = await getPassDesigns(accessToken, selectedEventId);
          setCardsData(resp || []);
        } catch (e) {
          console.log(e);
          setCardsData([]);
        } finally {
          setGlobalLoading(false);
        }
      }
    })();
  }, [selectedEventId, fetchKey]);

  return (
    <div className={`px ${styles.configContainer}`}>
      <AddNewCardDesignModal
        open={addNewCardDesignModalOpen}
        onClose={() => {
          setAddNewCardDesignModalOpen(false);
          setIsEdit(false);
          setEditPayload({});
        }}
        refetch={refetch}
        isEdit={isEdit}
        editPayload={editPayload}
      />
      <DeleteScanner
        headerText={"Design"}
        open={openDelete}
        isDesign
        handleClose={() => {
          setOpenDelete(false);
          setSelectedIndex();
        }}
        onConfirm={() => {
          deleteCard(selectedIndex);
          setOpenDelete(false);
        }}
      />
      <AddNewButton onClick={() => setAddNewCardDesignModalOpen(true)} />
      {cardsData.map((design, i) => (
        <Card
          area={STAFF_PASS_SIZES?.[design?.dimension]?.label || ""}
          eventTitle={design?.eventName || ""}
          key={design?._id}
          isDesignCard
          onAction={() => {
            setIsEdit(true);
            setEditPayload(design);
            setAddNewCardDesignModalOpen(true);
          }}
          onDelete={() => {
            setSelectedIndex(i);
            setOpenDelete(true);
          }}
        />
      ))}
    </div>
  );
}
