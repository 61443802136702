import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";

import getEventsList from "../../functions/getEventsList";
import { UserAccessTokenState } from "../../recoil/user";

import HeaderGrid from "./HeaderGrid";
import Values from "./Values";

import styles from "../../styles/dashboard/Dashboard.module.css";

export default function AllEvents() {
  const [data, setData] = useState(null);
  const accessToken = useRecoilValue(UserAccessTokenState);

  useEffect(() => {
    (async () => {
      try {
        const resp = await getEventsList(accessToken);
        setData(resp.events);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  return (
    <div className={`px`}>
      <div className={styles.box}>
        <div className={styles.userDetailsItem}>All Events</div>
        <div className={styles.planDetailsSubtitle}>
          Total Events: {data?.length || 0}
        </div>
        <HeaderGrid />
        <Values data={data} />
      </div>
    </div>
  );
}
