import { useEffect, useState } from "react";
import _ from "lodash";
import { useRecoilValue } from "recoil";

import bookmark from "../../assets/images/bookmark.png";
import plus from "../../assets/images/plus.png";
import tick from "../../assets/images/tick.png";
import getRecentActivity from "../../functions/getRecentActivity";
import { selectedEventIdState } from "../../recoil/events";
import { totalScanDataState } from "../../recoil/scanner";
import { UserAccessTokenState } from "../../recoil/user";
import translate from "../../utils/temp/translate";
import timeAgo from "../../utils/timeAgo";
import { StatDetails } from "../customers/StatDetails";
import RecentActivityItem from "../events/RecentActivityItem";

import styles from "../../styles/eventScanner/RecentActivity.module.css";
import gridStyles from "../../styles/general/Grid.module.css";

export default function RecentActivity() {
  const totalScanData = useRecoilValue(totalScanDataState);
  const accessToken = useRecoilValue(UserAccessTokenState);
  const [activityData, setActivityData] = useState([]);
  const selectedEvent = useRecoilValue(selectedEventIdState);

  useEffect(() => {
    (async () => {
      try {
        const resp = await getRecentActivity(
          accessToken,
          selectedEvent,
          10,
          "ticketScannedSuccessfully"
        );
        setActivityData(resp);
      } catch (err) {
        console.log(err);
      }
    })();
  }, [selectedEvent]);

  const totalSold = _.sumBy(totalScanData, "totalCount");
  const totalScanned = _.sumBy(totalScanData, "usedTicketCount");
  const totalNotScanned = totalSold - totalScanned;

  return (
    <>
      <div className={`${gridStyles.headersection} flex al-ce jc-sp`}>
        <div>RECENT ACTIVITY</div>
      </div>
      <div className={styles.container}>
        <div className={styles.listContainer}>
          {activityData &&
            activityData?.map((activity, index) => {
              const { timeAgoString } = timeAgo(activity?.payload?.date);
              return (
                <RecentActivityItem
                  key={index}
                  timeAgo={timeAgoString}
                  name={activity?.payload?.buyerName}
                  activity={`${activity?.payload?.quantity}  ${activity?.payload?.ticketType}`}
                  type={"scan"}
                />
              );
            })}
        </div>
        <div className={styles.totalsContainer}>
          <div className={styles.statsWrapper}>
            <div className={styles.statContainer}>
              <StatDetails
                left
                iconSrc={bookmark}
                header={totalSold || 0}
                subtext={"TOTAL TICKETS SOLD"}
              />
            </div>
            <div className={styles.hr} />
            <div className={styles.statContainer}>
              <StatDetails
                left
                iconSrc={tick}
                header={totalScanned || 0}
                subtext={"TOTAL TICKET SCANNED"}
              />
            </div>
            <div className={styles.hr} />
            <div className={styles.statContainer}>
              <StatDetails
                left
                iconSrc={plus}
                header={totalNotScanned || 0}
                subtext={"TOTAL TICKET NOT SCANNED"}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
