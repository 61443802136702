import { useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import add from "../assets/add.svg";
import addDark from "../assets/addark.svg";
import Button from "../components/general/Button";
import CircularLoader from "../components/general/CircularLoader";
import SelectLabels from "../components/general/Select";
import {
  ErrorToast,
  SuccessToast,
} from "../components/general/ToastNotification";
import Topbar from "../components/general/Topbar";
import UploadProfilePicture from "../components/settings/UploadProfilePicture";
import uploadProfilePicture from "../functions/settings/uploadProfilePicture";
import useUserDataFetch from "../hooks/useUserDataFetch";
import { darkModeState } from "../recoil/settings";
import {
  FetchKeyState,
  UserAccessTokenState,
  UserDataState,
} from "../recoil/user";
import axiosErrorHandler from "../utils/axiosErrorHandler";
import translate from "../utils/temp/translate";

import styles from "../styles/settings/Settings.module.css";

const initialValues = {
  country: "",
  eventBanner: "",
  eventEndDate: null,
  eventName: "",
  eventStartDate: null,
  seatMap: "",
  venueLocation: "",
  venueName: "",
};

export default function Settings({ currency, setCurrency, user }) {
  const [dark, setDark] = useRecoilState(darkModeState);
  const [language, setLanguage] = useState("english");
  const [uploading, setUploading] = useState(false);
  const [fields, setFields] = useState(initialValues);
  const accessToken = useRecoilValue(UserAccessTokenState);
  const [bannerData, setBannerData] = useState(initialValues);
  const [userData, setUserData] = useRecoilState(UserDataState);
  const setFetchKey = useSetRecoilState(FetchKeyState);
  const [imgBinary, setImgBinary] = useState();
  useUserDataFetch();

  const handleModeChange = (e) => {
    setDark(e.target.value);
  };

  const handleProfilePictureUpload = async (file) => {
    if (!uploading) {
      try {
        setUploading(true);
        await uploadProfilePicture(accessToken, file);
        setFetchKey(Date.now());
      } catch (e) {
        const msg = axiosErrorHandler(e);
        ErrorToast("Upload Error", msg);
      } finally {
        setUploading(false);
      }
    }
  };

  return (
    <div>
      <div className="page">
        <Topbar dark={dark} pagename={"Settings"} userData={userData} />
        <div className="content px">
          <div className={styles.px15}>
            <div className={styles.settingsContainer}>
              <div className={`flex al-ce jc-sp ${styles.headersection}`}>
                <div className={styles.smtext}>
                  {translate("settingOptions")}
                </div>
              </div>
              <div className={styles.twogrid}>
                <div className={styles.px25}>
                  <div className={styles.wrap}>
                    <div className={styles.selectContainer}>
                      <div className={styles.label}>
                        {translate("currency")}
                      </div>
                      <SelectLabels
                        disabled={true}
                        dark={dark}
                        name={"currency"}
                        value={currency}
                        updateValue={(e) => {
                          setCurrency(e.target.value);
                          localStorage.setItem("currency", e.target.value);
                        }}
                        placeholderText={"Choose Currency"}
                        options={[
                          {
                            label: "CNY",
                            value: "cny",
                          },
                          {
                            label: "EUR",
                            value: "eur",
                          },
                          {
                            label: "SGD",
                            value: "sgd",
                          },
                          {
                            label: "USD",
                            value: "usd",
                          },
                        ]}
                      />
                    </div>
                    <div className={styles.selectContainer}>
                      <div className={styles.label}>
                        {translate("language")}
                      </div>
                      <SelectLabels
                        dark={dark}
                        name={"language"}
                        value={language}
                        updateValue={setLanguage}
                        placeholderText={"Choose Language"}
                        options={[
                          {
                            label: translate("english"),
                            value: "english",
                          },
                        ]}
                      />
                    </div>
                    <div className={styles.selectContainer}>
                      <div className={styles.label}>{translate("mode")}</div>
                      <SelectLabels
                        dark={dark}
                        name={"mode"}
                        value={dark}
                        updateValue={setDark}
                        placeholderText={"Choose Mode"}
                        options={[
                          {
                            label: translate("light"),
                            value: false,
                          },
                        ]}
                      />
                    </div>
                  </div>

                  <div className={styles.wrap}>
                    <div className={`flex end ${styles.btncontainer}`}>
                      <Button
                        text={translate("save")}
                        imgSrc={
                          <img src={dark ? addDark : add} alt="add icon" />
                        }
                        onClick={() =>
                          SuccessToast(
                            translate("success"),
                            translate("settingsSaved")
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
                <div />
              </div>
              <div className={`flex al-ce jc-sp ${styles.headersection}`}>
                <div className={styles.smtext}>
                  {translate("accountInformation")}
                </div>
              </div>
              <div className={styles.px25}>
                <UploadProfilePicture
                  currentImgUrl={userData?.profileImageUrl}
                  setImgBinary={setImgBinary}
                />

                <div className={styles.wrap}>
                  <div className={`flex end ${styles.btncontainer}`}>
                    {uploading ? <CircularLoader loading={uploading} /> : null}
                    <Button
                      style={{ marginLeft: "10px" }}
                      text={translate("save")}
                      imgSrc={<img src={dark ? addDark : add} alt="add icon" />}
                      onClick={() => {
                        if (imgBinary) {
                          handleProfilePictureUpload(imgBinary);
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
              <div />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
