import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import { useRecoilValue, useSetRecoilState } from "recoil";

import addIcon from "../../../assets/add.svg";
import Close from "../../../assets/close.svg";
import CloseDark from "../../../assets/closedark.svg";
import RedClose from "../../../assets/red-close.svg";
import RedCloseDark from "../../../assets/red-closedark.svg";
import { selectedFairnessDataState } from "../../../recoil/fairness";
import { darkModeState } from "../../../recoil/settings";
import translate from "../../../utils/temp/translate";
import Button from "../../general/Button";
import Input from "../../general/Input";
import SelectLabels from "../../general/Select";

import styles from "../../../styles/modals/Modal.module.css";

export default function CreateCaptchaModal({
  isEditMode,
  open,
  handleClose,
  payload,
}) {
  const { answers, data, setAnswers, editData } = payload;
  const dark = useRecoilValue(darkModeState);
  // const [captchaType, setCaptchaType] = useState("");
  const [captchaQuestion, setCaptchaQuestion] = useState("");
  const [captchaAnswer, setCaptchaAnswer] = useState("");
  const setFairnessData = useSetRecoilState(selectedFairnessDataState);

  const handleAddAnswer = () => {
    if (captchaAnswer.length > 0) {
      if (!answers.includes(captchaAnswer)) {
        setAnswers([...answers, captchaAnswer]);
        setCaptchaAnswer("");
      } else {
        toast.error("Answer already exists");
      }
    } else {
      toast.error("Please enter answer");
    }
  };

  useEffect(() => {
    if (isEditMode) {
      // setCaptchaType(editData.captchaType);
      setCaptchaQuestion(editData.question);
      setAnswers(editData.answers);
    } else {
      // setCaptchaType("custom");
      setCaptchaQuestion("");
      setAnswers([]);
    }
  }, [isEditMode, editData]);

  const handleRemoveAnswer = (answer) => {
    const newAnswers = answers.filter((a) => a !== answer);
    setAnswers(newAnswers);
  };

  return (
    <Modal className={` ${dark ? "dark" : ""}`} open={open}>
      <Fade in={open}>
        <div className={`${styles.previewBox} ${styles.captcha}`}>
          <div className={styles.previewHeader}>
            <div>
              {translate(isEditMode ? `Edit Captcha` : `Create New Captcha`)}
            </div>
            <img onClick={handleClose} src={dark ? CloseDark : Close} alt="" />
          </div>

          <div>
            <div className={styles.captchaRow}>
              {/* <div>
                <div className={styles.label}>Type</div>
                <SelectLabels
                  options={[{ label: "Custom", value: "custom" }]}
                  name={"captchaType"}
                  value={captchaType}
                  placeholderText={"Choose"}
                  updateValue={(e) => setCaptchaType(e)}
                />
              </div> */}
              <div className={styles.span2}>
                <div className={styles.label}>Question</div>
                <Input
                  name="captchaQuestion"
                  value={captchaQuestion}
                  updateValue={(e) => setCaptchaQuestion(e.target.value)}
                  placeholder={"Enter question ie. What is today’s date?"}
                />
              </div>
            </div>

            <div className={styles.captchaRow}>
              <div>
                <div className={styles.label}>Answer</div>
                <Input
                  name="captchaAnswer"
                  value={captchaAnswer}
                  updateValue={(e) => setCaptchaAnswer(e.target.value)}
                  placeholder={"Enter new answer"}
                />
              </div>
              <div className={styles.addBtnContainer}>
                <Button
                  text={"Add"}
                  onClick={handleAddAnswer}
                  imgSrc={<img src={addIcon} alt="add" />}
                />
              </div>
            </div>

            <div className={styles.pad}>
              <div className={styles.label}>Accepted Answer</div>

              <div className={styles.answersContainer}>
                <div className={styles.twoGrid}>
                  {answers.map((answer, index) => (
                    <div key={index} className={styles.captchaAnswer}>
                      {answer}
                      <img
                        onClick={() => handleRemoveAnswer(answer)}
                        src={dark ? RedCloseDark : RedClose}
                        alt=""
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className={styles.btnContainer}>
              <Button
                text={"Save"}
                onClick={() => {
                  if (!captchaQuestion || !answers.length) return;
                  const id = Date.now().toString();
                  let newData = isEditMode
                    ? {
                        ...data,
                        [editData._id]: {
                          _id: editData._id,
                          answers,
                          question: captchaQuestion,
                        },
                      }
                    : {
                        ...data,
                        [id]: {
                          _id: id,
                          answers,
                          question: captchaQuestion,
                        },
                      };
                  payload.setData(newData);
                  setFairnessData((prev) => ({
                    ...prev,
                    captchaQuestions: Object.values(newData),
                  }));
                  handleClose();
                }}
              />
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}
