import React from "react";

import Button from "../general/Button";

import styles from "../../styles/staffPass/StaffPass.module.css";

export default function AddNewButton({ onClick, isEdit = false }) {
  return (
    <div className={styles.addNewContainer}>
      <Button
        imgSrc={
          <img src={require("../../assets/add.svg").default} alt="plus" />
        }
        text={isEdit ? "Edit" : "Add New"}
        onClick={onClick}
      />
    </div>
  );
}
