import React from "react";

import { UserIcon } from "../../constants/Svgs";

import styles from "../../styles/events/RecentActivity.module.css";

export default function RecentActivityItem({
  name,
  activity,
  timeAgo,
  type,
  counter,
}) {
  return (
    <div className={styles.recentActivityItem}>
      <div className={styles.userIcon}>
        <UserIcon />
      </div>
      <div>
        <div className={styles.text}>
          <span className={styles.name}>{name}</span>{" "}
          {type === "scan" ? "just scanned" : "just has bought"}
          <span className={`${styles.activity} ${styles[type]}`}>
            {" "}
            {activity}{" "}
          </span>
          ticket(s) on{" "}
          {type === "pos" ? (
            <span style={{ color: "#767BFA" }}>
              {String(counter).toLowerCase().indexOf("counter") !== -1
                ? counter
                : `counter-${counter}`}
            </span>
          ) : (
            "your event"
          )}
          .
        </div>
        <div className={styles.timeAgo}>{timeAgo}</div>
      </div>
    </div>
  );
}
