import React, { useEffect, useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";

import {
  fairnessLevelState,
  selectedFairnessDataState,
} from "../../recoil/fairness";

import styles from "../../styles/general/ProgressBar.module.css";

export const fairnessLevels = [
  { color: "#767BFA", label: "ZERO", percentage: 0 },
  { color: "#9EE193", label: "LOW", percentage: 25 },
  { color: "#FAAD76", label: "MEDIUM", percentage: 50 },
  { color: "#EA6768", label: "HIGH", percentage: 90 },
];
export const fairnessLevelSettings = {
  HIGH: {
    accountCreation: true,
    analyseAddress: true,
    analyseEmail: true,
    analysePhone: true,
    bgAppScan: true,
    captcha: true,
    instagram: true,
    ipRestriction: true,
    purchaseBehaviour: true,
    queue: true,
    spotify: true,
    whiteList: true,
  },
  LOW: {
    accountCreation: false,
    analyseAddress: false,
    analyseEmail: false,
    analysePhone: false,
    bgAppScan: false,
    captcha: true,
    instagram: false,
    ipRestriction: false,
    purchaseBehaviour: false,
    queue: false,
    spotify: false,
    whiteList: true,
  },
  MEDIUM: {
    accountCreation: true,
    analyseAddress: true,
    analyseEmail: false,
    analysePhone: true,
    bgAppScan: false,
    captcha: true,
    instagram: false,
    ipRestriction: true,
    purchaseBehaviour: true,
    queue: false,
    spotify: false,
    whiteList: true,
  },
  ZERO: {
    accountCreation: false,
    analyseAddress: false,
    analyseEmail: false,
    analysePhone: false,
    bgAppScan: false,
    captcha: false,
    instagram: false,
    ipRestriction: false,
    purchaseBehaviour: false,
    queue: false,
    spotify: false,
    whiteList: true,
  },
};

const ProgressBar = ({ level }) => {
  const [fairnessIndex, setFairnessIndex] = useRecoilState(fairnessLevelState);
  const setFairnessData = useSetRecoilState(selectedFairnessDataState);
  const currentPercentage = fairnessLevels[fairnessIndex].percentage;

  useEffect(() => {
    let index = fairnessLevels.findIndex((l) => l.label === level);

    setFairnessIndex(index === -1 ? 0 : index);
  }, [level]);

  const handleLevelChange = (index) => {
    setFairnessIndex(index);
    setFairnessData((prev) => ({
      ...prev,
      level: fairnessLevels[index].label,
    }));
  };

  return (
    <div className={styles.progressBar}>
      <div className={styles.progressBarText}>
        You are now currently running{" "}
        <span
          style={{
            color: fairnessLevels[fairnessIndex].color,
          }}
        >
          {currentPercentage}%
        </span>
      </div>
      <div className={styles.backgroundBar}>
        {fairnessLevels.map((level, index) => {
          return (
            <>
              {index > 0 && (
                <Step
                  color={
                    currentPercentage >= level.percentage
                      ? level.color
                      : "#abafd1"
                  }
                />
              )}
              <Circle
                color={
                  currentPercentage >= level.percentage
                    ? level.color
                    : "#abafd1"
                }
                num={level.percentage}
                onClick={() => handleLevelChange(index)}
              />
            </>
          );
        })}
      </div>
    </div>
  );
};

const Circle = ({ num, onClick, color }) => {
  return (
    <div
      style={{
        backgroundColor: color,
      }}
      className={styles.circle}
      onClick={onClick}
    >
      <span className={styles.circleText}>{num}%</span>
    </div>
  );
};

const Step = ({ color }) => {
  return (
    <div
      style={{
        backgroundColor: color,
      }}
      className={styles.step}
    ></div>
  );
};

export default ProgressBar;
