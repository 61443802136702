import React from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";

import deleteStaff from "../../functions/pos/deleteStaff";
import { staffRefetchKeyState } from "../../recoil/pos";
import { UserAccessTokenState } from "../../recoil/user";
import axiosErrorHandler from "../../utils/axiosErrorHandler";
import Button from "../general/Button";
import { ErrorToast, SuccessToast } from "../general/ToastNotification";

import styles from "../../styles/pos/Pos.module.css";

const StaffItem = ({ email, id }) => {
  const accessToken = useRecoilValue(UserAccessTokenState);
  const setStaffRefetchKey = useSetRecoilState(staffRefetchKeyState);

  const handleStaffDelete = async () => {
    if (id) {
      try {
        const resp = await deleteStaff(accessToken, id);
        if (!resp.success) {
          return ErrorToast(
            "Error",
            "Some error occurred while deleting the staff"
          );
        }
        setStaffRefetchKey((prev) => prev + 1);
        SuccessToast("Success", "Staff deleted successfully");
      } catch (e) {
        const msg = axiosErrorHandler(e);
        return ErrorToast("Error", msg);
      }
    }
  };

  return (
    <div className={styles.staffItem}>
      <span>Email: {email}</span>
      <span>Staff ID: {id}</span>
      <Button
        text="Delete Staff"
        customClass={"deleteStaffBtn"}
        onClick={handleStaffDelete}
      />
    </div>
  );
};

export default StaffItem;
