import React from "react";
import { toast } from "react-toastify";
import { useRecoilValue, useSetRecoilState } from "recoil";

import viewStaffIcon from "../../assets/boy-dynamic-color.webp";
import addCounterIcon from "../../assets/computer-dynamic-color.webp";
import createCounter from "../../functions/pos/createCounter";
import { globalLoadingState } from "../../recoil/general";
import {
  counterRefetchKeyState,
  staffListModalOpenState,
} from "../../recoil/pos";
import { UserAccessTokenState, UserDataState } from "../../recoil/user";
import axiosErrorHandler from "../../utils/axiosErrorHandler";
import Button from "../general/Button";
import { ErrorToast, SuccessToast } from "../general/ToastNotification";

import DataGrid from "./DataGrid";
import IconButton from "./IconButton";

import styles from "../../styles/pos/Pos.module.css";

export default function SystemManagement({ counterList = [] }) {
  const accessToken = useRecoilValue(UserAccessTokenState);
  const userData = useRecoilValue(UserDataState);
  const setStaffListModalOpen = useSetRecoilState(staffListModalOpenState);
  const setCounterRefetchKey = useSetRecoilState(counterRefetchKeyState);
  const setGlobalLoading = useSetRecoilState(globalLoadingState);

  const handleCopyPOSLink = () => {
    navigator.clipboard.writeText(
      `${process.env.REACT_APP_POS_LINK}/login?organiserId=${userData._id}`
    );
    toast.success("POS Link copied to clipboard");
  };

  const handleCounterCreate = async () => {
    try {
      setGlobalLoading(true);
      const resp = await createCounter(accessToken);
      if (!resp.success) {
        return ErrorToast(
          "Error",
          "Some error occurred while creating new counter"
        );
      }
      setCounterRefetchKey((prev) => prev + 1);
      SuccessToast("Success", "New counter created");
    } catch (e) {
      const msg = axiosErrorHandler(e);
      return ErrorToast("Error", msg);
    } finally {
      setGlobalLoading(false);
    }
  };

  return (
    <div className={`box ${styles.systemManagement}`}>
      <div className={styles.header}>
        <div className={styles.headerText}>POS SYSTEM MANAGEMENT</div>
        <Button
          onClick={handleCopyPOSLink}
          text={"Copy POS Link"}
          customClass={"copyPosLink"}
        />
      </div>
      <div className={styles.subHeader}>
        Kindly generate an unique passcode for your POS system.
      </div>
      <div className={styles.passcode}>
        <IconButton
          icon={viewStaffIcon}
          label="VIEW STAFF LISTS"
          onClick={() => setStaffListModalOpen(true)}
        />
        <div className={styles.verticalDivider} />
        <IconButton
          icon={addCounterIcon}
          label="ADD NEW COUNTER"
          onClick={handleCounterCreate}
        />
      </div>
      <DataGrid data={counterList} />
    </div>
  );
}
