import React, { useEffect } from "react";

import getDayOfMonth from "../../utils/getDayOfMonth";
import getMonthAsString from "../../utils/getMonthString";
import getTimein12HourFormat from "../../utils/getTimeIn12HourFormat";
import ConditionalDisplay from "../general/ConditionalDisplay";

import styles from "../../styles/events/Banner.module.css";

export default function EventDetails({ data }) {
  return (
    <div className={styles.eventDetails}>
      {data ? (
        <>
          <ConditionalDisplay condition={data.eventStartDate}>
            <div className={styles.eventDate}>
              <div className={styles.month}>
                {getMonthAsString(data.eventStartDate)}
              </div>
              <div className={styles.date}>
                {getDayOfMonth(data.eventStartDate)}
              </div>
            </div>
          </ConditionalDisplay>
          <div className={styles.eventName}>
            {data.eventName}
            <ConditionalDisplay condition={data.eventStartDate}>
              <span>, {getTimein12HourFormat(data.eventStartDate)}</span>
            </ConditionalDisplay>
          </div>
          <ConditionalDisplay condition={data.venueName}>
            <div className={styles.eventLocation}>
              <span>Venue</span>
              <div className={styles.venueName}>
                <span>{data.venueName}</span>
                <ConditionalDisplay condition={data.country}>
                  <span>,&nbsp;{data.country}</span>
                </ConditionalDisplay>
              </div>
            </div>
          </ConditionalDisplay>
          <ConditionalDisplay condition={data.eventBanner}>
            <div className={styles.eventImage}>
              <img src={data.eventBanner} alt="Event" />
            </div>
          </ConditionalDisplay>
        </>
      ) : null}
    </div>
  );
}
