import React, { useEffect, useMemo, useState } from "react";
import { Fade, Modal } from "@mui/material";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import createStaffPass from "../../functions/staffPass/createStaffPass";
import getPassDesigns from "../../functions/staffPass/getPassDesigns";
import listAllEventAreas from "../../functions/staffPass/listAllEventAreas";
import { globalLoadingState } from "../../recoil/general";
import {
  activeViewState,
  addCardInputFieldsState,
  addPassInputFieldsState,
  cardDesignDataState,
  eventAreaDataState,
  pdfGenerationTriggerState,
  toggleViewModeState,
  viewPassModalDataState,
} from "../../recoil/staff";
import { UserAccessTokenState } from "../../recoil/user";
import axiosErrorHandler from "../../utils/axiosErrorHandler";
import Button from "../general/Button";
import CircularLoader from "../general/CircularLoader";
import Input from "../general/Input";
import SelectLabels from "../general/Select";
import { ErrorToast, SuccessToast } from "../general/ToastNotification";

import AddCardDesignPreviewSection from "./AddCardDesignPreviewSection";
import { MODAL_STYLE } from "./DeleteScanner";

import styles from "../../styles/modals/Modal.module.css";

export default function AddNewPassModal({ open, onClose, refetch }) {
  const accessToken = useRecoilValue(UserAccessTokenState);
  const [fields, setFields] = useRecoilState(addPassInputFieldsState);
  const [eventAreaData, setEventAreaData] = useRecoilState(eventAreaDataState);
  const [designData, setDesignData] = useRecoilState(cardDesignDataState);
  const [viewPassModalData, setViewPassModalData] = useRecoilState(
    viewPassModalDataState
  );
  const setCardDesignData = useSetRecoilState(addCardInputFieldsState);
  const isViewMode = useRecoilValue(toggleViewModeState);
  const setGlobalLoading = useSetRecoilState(globalLoadingState);
  const selectedEventId = fields.event;
  const selectedDesignId = fields.cardDesign;
  const [loading, setLoading] = useState(false);
  const handleChanges = (e) => {
    setFields({ ...fields, [e.target.name]: e.target.value });
  };
  const activeView = useRecoilValue(activeViewState);

  const eventAreaOptions = useMemo(() => {
    return (
      eventAreaData?.map((data) => ({
        label: data.eventName,
        value: data.eventId,
      })) || []
    );
  }, [open, eventAreaData]);

  const areaOptions = useMemo(() => {
    return (
      eventAreaData
        ?.find((event) => event.eventId === selectedEventId)
        ?.areaNames?.map((area) => ({ label: area, value: area })) || []
    );
  }, [open, selectedEventId, eventAreaData]);

  const designOptions = useMemo(() => {
    return (
      designData?.map((design) => ({
        label: design.name,
        value: design._id,
      })) || []
    );
  }, [open, selectedEventId, designData]);

  useEffect(() => {
    if (isViewMode) {
      setFields({
        ...fields,
        areaAccess: viewPassModalData.areaName,
        cardDesign: viewPassModalData.designId,
        email: viewPassModalData.staffEmail,
        event: viewPassModalData.eventId,
        role: viewPassModalData.role,
      });
    }
  }, [isViewMode]);

  useEffect(() => {
    if (selectedDesignId && designData) {
      const selectedDesignData = designData.find(
        (design) => design._id === selectedDesignId
      );
      if (selectedDesignData) {
        setCardDesignData({
          accent1: selectedDesignData.accent1,
          accent2: selectedDesignData.accent2,
          bgColor: selectedDesignData.bgColor,
          dimension: selectedDesignData.dimension,
          name: selectedDesignData.name,
          nameColor: selectedDesignData.nameColor,
          roleColor: selectedDesignData.roleColor,
        });
      }
    }
  }, [open, selectedDesignId]);

  useEffect(() => {
    (async () => {
      try {
        // setGlobalLoading(true);
        const resp = await listAllEventAreas(accessToken);
        setEventAreaData(resp || []);
      } catch (e) {
        console.log(e);
        setEventAreaData([]);
      } finally {
        // setGlobalLoading(false);
      }
    })();
  }, [open]);

  useEffect(() => {
    (async () => {
      if (selectedEventId) {
        try {
          setGlobalLoading(true);
          const resp = await getPassDesigns(accessToken, selectedEventId);
          setDesignData(resp || []);
        } catch (e) {
          console.log(e);
          setDesignData([]);
        } finally {
          setGlobalLoading(false);
        }
      }
    })();
  }, [open, selectedEventId]);

  useEffect(() => {
    if (selectedDesignId) {
      const selectedDesign = designData?.find(
        (design) => design._id === selectedDesignId
      );
      console.log(selectedDesign);
      setViewPassModalData((prev) => ({
        ...prev,
        backImageUrl: selectedDesign?.backImageUrl,
        logoUrl: selectedDesign?.logoUrl,
      }));
    }
  }, [
    selectedDesignId,
    designData,
    setViewPassModalData,
    setCardDesignData,
    activeView,
  ]);

  const handleCreateStaffPass = async () => {
    if (!loading) {
      try {
        setLoading(true);
        const resp = await createStaffPass(accessToken, {
          areaName: fields.areaAccess,
          designId: fields.cardDesign,
          email: fields.email,
          eventId: fields.event,
          role: fields.role,
        });
        refetch();
        onClose();
        SuccessToast("Success", "Staff pass created");
      } catch (e) {
        const msg = axiosErrorHandler(e);
        ErrorToast("Error", msg);
      } finally {
        setLoading(false);
      }
    }
  };

  const setPdfGenerationTrigger = useSetRecoilState(pdfGenerationTriggerState);

  const downloadPdf = () => {
    setPdfGenerationTrigger(true);
  };

  return (
    <Modal
      className={styles.modalContainer}
      open={open}
      onClose={onClose}
      disableEnforceFocus
      style={MODAL_STYLE}
    >
      <Fade
        in={open}
        style={{
          border: "none",
          outline: "none",
        }}
      >
        <div
          className={`${styles.modal} ${styles.addNewCardDesignModal}`}
          style={{
            minWidth: "56.875rem",
          }}
        >
          <div className={styles.modalHeader}>
            <div>{isViewMode ? "Viewing Pass" : "Adding New Pass..."}</div>
            <img
              className={styles.cursor}
              onClick={onClose}
              src={require("../../assets/close.svg").default}
            />
          </div>
          <div className={styles.cardDesignGrid}>
            <div className={styles.leftSection}>
              <div className={styles.fullWidth}>
                <label className={styles.label} htmlFor="email">
                  Email
                </label>
                <Input
                  readOnly={isViewMode}
                  placeholder={"Email"}
                  type={"email"}
                  name={"email"}
                  value={fields.email}
                  updateValue={handleChanges}
                />
              </div>
              <div className={styles.fullWidth}>
                <div className={styles.fullNameContainer}>
                  <div className={styles.shortName}>
                    {isViewMode
                      ? viewPassModalData?.staffName
                          ?.split(" ")
                          .map((n) => n[0])
                          .join("")
                      : ""}
                  </div>
                  <div className={styles.fullName}>
                    Full Name: {isViewMode ? viewPassModalData?.staffName : ""}
                  </div>
                </div>
              </div>
              <div className={styles.fullWidth}>
                <label className={styles.label} htmlFor="event">
                  Event
                </label>
                <SelectLabels
                  disabled={isViewMode}
                  options={eventAreaOptions}
                  placeholderText={"Select Event"}
                  name={"event"}
                  value={fields.event}
                  updateValue={(e) =>
                    handleChanges({
                      target: { name: "event", value: e },
                    })
                  }
                />
              </div>
              <div className={styles.fullWidth}>
                <label className={styles.label} htmlFor="areaAccess">
                  Area to access
                </label>
                <SelectLabels
                  disabled={isViewMode}
                  options={areaOptions}
                  placeholderText={"Select Area to access"}
                  name={"areaAccess"}
                  value={fields.areaAccess}
                  updateValue={(e) =>
                    handleChanges({
                      target: { name: "areaAccess", value: e },
                    })
                  }
                />
              </div>
              <div className={styles.twoGrid}>
                <div>
                  <label className={styles.label} htmlFor="role">
                    Role
                  </label>
                  <Input
                    readOnly={isViewMode}
                    type={"text"}
                    name={"role"}
                    placeholder={"Role"}
                    value={fields.role}
                    updateValue={handleChanges}
                  />
                </div>
                <div>
                  <label className={styles.label}>Card Design</label>
                  <SelectLabels
                    disabled={isViewMode}
                    options={designOptions}
                    placeholderText={"Select Card Design"}
                    name={"cardDesign"}
                    value={fields.cardDesign}
                    updateValue={(e) =>
                      handleChanges({
                        target: { name: "cardDesign", value: e },
                      })
                    }
                  />
                </div>
              </div>
              <div className={styles.addButtonContainer}>
                {loading ? (
                  <div className={styles.loaderContainer}>
                    <div className={styles.loader}>
                      <CircularLoader loading={true} />
                    </div>
                  </div>
                ) : (
                  <Button
                    onClick={isViewMode ? downloadPdf : handleCreateStaffPass}
                    text={isViewMode ? "Download PDF" : "Generate Pass"}
                  />
                )}
              </div>
            </div>
            <div className={styles.previewContainer}>
              <label className={styles.label}>Preview</label>
              <div className={styles.preview}>
                <AddCardDesignPreviewSection isNewPass />
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}
