import axios from "axios";

export default async function deletePassDesign(access_token, designId) {
  if (!designId) throw new Error("Provide the Design ID to delete");

  const resp = await axios.delete(
    `${process.env.REACT_APP_ORGANISER_API}/staff-pass/design/delete/${designId}`,
    {
      headers: {
        token: access_token,
      },
    }
  );

  if (!resp.data) {
    throw new Error("Some error occured");
  }
  if (resp.data.error) {
    throw new Error(resp.data.error);
  }

  return resp.data;
}
