import axios from "axios";

export default async function uploadThemeImage(token, file, content, saleId) {
  if (file.size > 4 * 1024 * 1024) {
    throw new Error("Size of the pdf should be under 4MB");
  }

  if (!saleId) throw new Error("Please select a sale first");

  let formData = new FormData();
  formData.append("file", file);
  const resp = await axios.post(
    `${process.env.REACT_APP_ORGANISER_API}/event/theme/upload/${content}/${saleId}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        token,
      },
    }
  );

  // console.log(resp);
  if (!resp.data) {
    throw new Error(resp.error.message);
  }
  if (resp.error) {
    throw new Error(resp.error);
  }
  return resp.data.url;
}
