import React from "react";

import styles from "../../styles/general/Button.module.css";

export default function DeleteButton({ text = "Delete", onClick }) {
  return (
    <div onClick={onClick} className={styles.delete}>
      {text}
    </div>
  );
}
