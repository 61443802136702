import React, { useState } from "react";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import { useRecoilValue, useSetRecoilState } from "recoil";

import Close from "../../../assets/close.svg";
import CloseDark from "../../../assets/closedark.svg";
import { selectedFairnessDataState } from "../../../recoil/fairness";
import { darkModeState } from "../../../recoil/settings";
import translate from "../../../utils/temp/translate";
import Button from "../../general/Button";
import Input from "../../general/Input";
import SelectLabels from "../../general/Select";

import styles from "../../../styles/modals/Modal.module.css";

const maxPurchaseAmountOptions = () => {
  let arr = [];
  for (let index = 1; index < 10; index++) {
    const element = {
      label: index,
      value: index,
    };
    arr.push(element);
  }
  return [
    {
      label: "Unlimited",
      value: 0,
    },
    ...arr,
  ];
};

export default function AddVipModal({ open = true, handleClose, setData }) {
  const dark = useRecoilValue(darkModeState);
  const [email, setEmail] = useState("");
  const [maxTickets, setMaxPurchaseAmount] = useState("");
  const setFairnessData = useSetRecoilState(selectedFairnessDataState);

  return (
    <Modal className={` ${dark ? "dark" : ""}`} open={open}>
      <Fade
        style={{
          maxWidth: "39.8125rem",
        }}
        in={open}
      >
        <div className={`${styles.previewBox} ${styles.captcha}`}>
          <div className={styles.previewHeader}>
            <div>Adding new VIP</div>
            <img onClick={handleClose} src={dark ? CloseDark : Close} alt="" />
          </div>

          <div>
            <div className={`${styles.captchaRow} ${styles.vipRow}`}>
              <div>
                <div className={styles.label}>Email</div>
                <Input
                  name="email"
                  value={email}
                  updateValue={(e) => setEmail(e.target.value)}
                  placeholder={"Enter Email"}
                />
              </div>
              <div>
                <div className={styles.label}>Max. Ticket to Purchase</div>
                <SelectLabels
                  options={maxPurchaseAmountOptions()}
                  name={"maxTickets"}
                  value={maxTickets}
                  placeholderText={"Enter amount"}
                  updateValue={(e) => setMaxPurchaseAmount(e)}
                />
              </div>
            </div>

            <div className={styles.btnContainer}>
              <Button
                text={"Add New"}
                style={{
                  marginTop: "1.5rem",
                  minWidth: "11rem",
                }}
                onClick={() => {
                  setData((prev) => [...prev, { email, maxTickets }]);
                  setFairnessData((prev) => ({
                    ...prev,
                    vipWhitelist: [
                      ...(prev.vipWhitelist || []),
                      { email, maxTickets },
                    ],
                  }));
                  handleClose();
                }}
              />
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}
