import { useState } from "react";
import { useRecoilValue } from "recoil";
import { useFilePicker } from "use-file-picker";

import uploadThemeImage from "../../functions/fairness/uploadThemeImage";
import { UserAccessTokenState } from "../../recoil/user";
import axiosErrorHandler from "../../utils/axiosErrorHandler";

import { ErrorToast } from "./ToastNotification";

import styles from "../../styles/settings/UploadProfilePicture.module.css";

export default function UploadButton({
  btnName,
  saleId,
  content,
  updateThemeData,
}) {
  const [uploading, setUploading] = useState(false);
  const accessToken = useRecoilValue(UserAccessTokenState);

  const handleUpload = async (file) => {
    if (!uploading) {
      try {
        setUploading(true);
        const url = await uploadThemeImage(accessToken, file, content, saleId);
        updateThemeData(url);
      } catch (e) {
        const msg = axiosErrorHandler(e);
        ErrorToast("Upload Error", msg);
      } finally {
        setUploading(false);
      }
    }
  };

  const [openFileSelectorForProfilePicture, { filesContent }] = useFilePicker({
    accept: "image/*",
    limitFilesConfig: { max: 1 },
    maxFileSize: 50,
    multiple: false,
    onFilesSuccessfulySelected: async ({ plainFiles }) => {
      await handleUpload(plainFiles[0]);
    },
    readAs: "DataURL",
  });

  return (
    <div className="flex al-ce">
      <div className={styles.uploadWrapper}>
        <div
          className={styles.uploadText}
          onClick={!uploading ? openFileSelectorForProfilePicture : null}
        >
          {uploading ? "Uploading..." : filesContent?.[0]?.name || btnName}
        </div>
      </div>
    </div>
  );
}
