import React from "react";

import styles from "../../styles/pos/Pos.module.css";

function formatDate(dateString) {
  const date = new Date(dateString);
  return new Intl.DateTimeFormat("en-US", {
    day: "2-digit",
    hour: "numeric",
    hour12: true,
    minute: "2-digit",
    month: "long",
    year: "numeric",
  }).format(date);
}

const TxSummaryGrid = ({ transactions }) => {
  return (
    <div className={styles.table}>
      <div className={`${styles.tableRow} ${styles.headerRow}`}>
        <div className={styles.cell}>Transaction ID</div>
        <div className={styles.cell}>Category</div>
        <div className={styles.cell}>Buyer Email</div>
        <div className={styles.cell}>Purchased Time</div>
        <div className={styles.cell}>Amount</div>
        <div className={styles.cell}>Payment Type</div>
      </div>
      <div className={styles.tableContent}>
        {[...transactions].map((transaction, index) => (
          <div key={index} className={`${styles.tableRow} ${styles.valuesRow}`}>
            <div className={styles.cell}>{transaction?.txId || "-"}</div>
            <div className={styles.cell}>{transaction?.category || "-"}</div>
            <div className={styles.cell}>{transaction?.buyerEmail || "-"}</div>
            <div className={styles.cell}>
              {formatDate(transaction.soldDate)}
            </div>
            <div className={styles.cell}>
              {transaction?.amount
                ? `S$${transaction?.amount?.toFixed(2)}`
                : "-"}
            </div>
            <div className={styles.cell}>{transaction?.paymentType || "-"}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TxSummaryGrid;
