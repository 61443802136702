import React from "react";

import ProgressBar from "../general/Progress";

import styles from "../../styles/events/TicketSold.module.css";

export default function FairnessSystemSetting({ level }) {
  return (
    <div className={`${styles.ticketSold} ${styles.fairnessSystemSetting}`}>
      <div className={styles.header}>Fairness System Settings</div>

      <div className={`${styles.box} ${styles.content}`}>
        <ProgressBar level={level} />
      </div>
    </div>
  );
}
