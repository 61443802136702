import { atom } from "recoil";

export const PREVIEW_CARD_DESIGN = {
  EMPTY: "EMPTY",
  READY: "READY",
};

export const ACTIVE_CARD_FACE_VIEW = {
  BACK: "Back",
  FRONT: "Front",
};

export const previewCardDesignState = atom({
  default: PREVIEW_CARD_DESIGN.EMPTY,
  key: "previewCardDesignState",
});

export const addCardInputFieldsState = atom({
  default: {
    accent1: "#872AB2",
    accent2: "#872AB2",
    backImageUrl: "",
    bgColor: "#fff",
    dimension: "135x90",
    logoUrl: "",
    name: "",
    nameColor: "#872AB2",
    roleColor: "#872AB2",
  },
  key: "addCardInputFieldsState",
});

export const addPassInputFieldsState = atom({
  default: {
    areaAccess: "",
    cardDesign: "",
    email: "",
    event: "",
    role: "",
  },
  key: "addPassInputFieldsState",
});

export const activeViewState = atom({
  default: ACTIVE_CARD_FACE_VIEW.FRONT,
  key: "activeViewState",
});

export const cardDesignDataState = atom({
  default: [],
  key: "cardDesignDataState",
});

export const configurationDataState = atom({
  default: undefined,
  key: "configurationDataState",
});

export const addNewPassModalState = atom({
  default: false,
  key: "addNewPassModalState",
});

export const toggleViewModeState = atom({
  default: false,
  key: "toggleViewModeState",
});

export const viewPassModalDataState = atom({
  default: null,
  key: "viewPassModalDataState",
});

export const eventAreaRefetchKeyState = atom({
  default: 0,
  key: "eventAreaRefetchKeyState",
});

export const passDesignsRefetchKeyState = atom({
  default: 0,
  key: "passDesignsRefetchKeyState",
});

export const staffPassRefetchKeyState = atom({
  default: 0,
  key: "staffPassRefetchKeyState",
});

export const eventAreaDataState = atom({
  default: [],
  key: "eventAreaDataState",
});

export const pdfGenerationTriggerState = atom({
  default: false,
  key: "pdfGenerationTriggerState",
});
