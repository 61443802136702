import React from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

import {
  CheckboxCheckedIcon,
  CheckboxUnCheckedIcon,
} from "../../constants/Svgs";

const CustomCheckbox = ({ label, checked, handleChange }) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          checkedIcon={<CheckboxCheckedIcon />}
          icon={<CheckboxUnCheckedIcon />}
          color="primary"
        />
      }
      onChange={handleChange}
      label={label}
    />
  );
};

export default CustomCheckbox;
