import React from "react";

import styles from "../../styles/staffPass/StaffPass.module.css";

export function HorizontalDimension({ dimension, isSqaure }) {
  return (
    <div
      style={{
        left: "0px",
      }}
      className={styles.horizontalDimension}
    >
      {dimension}mm
    </div>
  );
}

export function VerticalDimension({ dimension }) {
  return <div className={styles.verticalDimension}>{dimension}mm</div>;
}
