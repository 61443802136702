import { useState } from "react";
import { useRecoilValue } from "recoil";

import AllEvents from "../components/dashboard/AllEvents";
import Customers from "../components/dashboard/Customers";
import PlanDetails from "../components/dashboard/PlanDetails";
import ProfitLossGraph from "../components/dashboard/ProfitLossGraph";
import Royalties from "../components/dashboard/Royalties";
import UserDetails from "../components/dashboard/UserDetails";
import { ErrorToast } from "../components/general/ToastNotification";
import Topbar from "../components/general/Topbar";
import changePlan from "../functions/changePlan";
import { darkModeState } from "../recoil/settings";
import { UserAccessTokenState, UserDataState } from "../recoil/user";
import axiosErrorHandler from "../utils/axiosErrorHandler";
import translate from "../utils/temp/translate";

import styles from "../styles/dashboard/Dashboard.module.css";

const Dashboard = () => {
  const dark = useRecoilValue(darkModeState);
  const userData = useRecoilValue(UserDataState);
  const accessToken = useRecoilValue(UserAccessTokenState);
  const [loading, setLoading] = useState(false);

  async function handleChangePlan() {
    setLoading(true);
    try {
      const url = await changePlan(accessToken);

      if (url) {
        window.location.href = url;
      } else {
        return ErrorToast("Portal URL not found");
      }
    } catch (error) {
      const msg = axiosErrorHandler(error);
      ErrorToast("Error", msg);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="page">
      <Topbar
        dark={dark}
        pagename={translate("dashboard")}
        userData={userData}
      />
      <div className={`${styles.container} px`}>
        <div
          style={{
            display: "grid",
            gridGap: "1rem",
          }}
        >
          <UserDetails userData={userData} />
          <PlanDetails onClick={handleChangePlan} loading={loading} />
        </div>
        <ProfitLossGraph />
        <div className={styles.twoColumn}>
          <Customers />
          <Royalties />
        </div>
      </div>
      <AllEvents />
    </div>
  );
};

export default Dashboard;
