import React from "react";

import PreviewHeader from "./PreviewHeader";
import WebsitePreview from "./WebsitePreview";

import styles from "../../styles/webpage/Webpage.module.css";

export default function PreviewSection({ handleLinkSave }) {
  return (
    <div className={styles.previewSection}>
      <PreviewHeader handleLinkSave={handleLinkSave} />
      <WebsitePreview />
    </div>
  );
}
