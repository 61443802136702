import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import redClose from "../../assets/red-close.svg";
import deleteBankAccount from "../../functions/wallet/deleteBankAccount";
import getBankAccounts from "../../functions/wallet/getBankAccounts";
import { globalLoadingState } from "../../recoil/general";
import { bankRefetchKeyState } from "../../recoil/modal";
import { UserAccessTokenState } from "../../recoil/user";
import axiosErrorHandler from "../../utils/axiosErrorHandler";
import { ErrorToast } from "../general/ToastNotification";

import gridStyles from "../../styles/general/Grid.module.css";
import styles from "../../styles/wallet/PayoutMethods.module.css";

export default function PayoutMethods() {
  const [bankAccounts, setBankAccounts] = useState([]);
  const accessToken = useRecoilValue(UserAccessTokenState);
  const setLoading = useSetRecoilState(globalLoadingState);
  const [fetchKey, setFetchKey] = useRecoilState(bankRefetchKeyState);

  useEffect(() => {
    (async () => {
      try {
        const resp = await getBankAccounts(accessToken);
        setBankAccounts(resp);
      } catch (err) {
        const msg = axiosErrorHandler(err);
        ErrorToast("Error", msg);
      }
    })();
  }, [accessToken, fetchKey]);

  const handleDelete = async (accountNumber) => {
    try {
      setLoading(true);
      await deleteBankAccount(accessToken, accountNumber);
      setFetchKey((prev) => prev + 1);
    } catch (e) {
      const msg = axiosErrorHandler(e);
      ErrorToast("Error", msg);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={gridStyles.gridContainer}>
      <div>
        <div className={`${gridStyles.gridHeaders} ${styles.headerColumns}`}>
          <div>Account Name</div>
          <div>Bank Name</div>
          <div>Account</div>
        </div>
        <div className={styles.itemRows}>
          {bankAccounts.map((method) => (
            <div
              className={`${gridStyles.gridItems} ${styles.itemColumns}`}
              key={method.id}
            >
              <div>{method.accountHolderName}</div>
              <div>{method.bankName}</div>
              <div>{method.accountNumber}</div>
              <div className={`flex al-ce jc-fe ${styles.activitiesImg}`}>
                <img
                  onClick={() => handleDelete(method.accountNumber)}
                  src={redClose}
                  className={styles.delIcon}
                  alt="red-close"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
