import React from "react";

import styles from "../../styles/pos/Pos.module.css";

const SummaryCard = ({ icon, value, label }) => {
  return (
    <div className={styles.card}>
      <img src={icon} className={styles.icon} alt="icon" />
      <div className={styles.content}>
        <div className={styles.value}>{value}</div>
        <div className={styles.label}>{label}</div>
      </div>
    </div>
  );
};

export default SummaryCard;
