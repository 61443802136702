import React, { useState } from "react";
import { Box, Fade, Modal } from "@mui/material";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import createStaff from "../../functions/pos/createStaff";
import {
  staffListModalOpenState,
  staffRefetchKeyState,
} from "../../recoil/pos";
import { UserAccessTokenState } from "../../recoil/user";
import axiosErrorHandler from "../../utils/axiosErrorHandler";
import { isValidEmail } from "../../utils/validators";
import Input from "../general/Input";
import { ErrorToast, SuccessToast } from "../general/ToastNotification";

import StaffItem from "./StaffItem";

import styles from "../../styles/pos/Pos.module.css";

const style = {
  backgroundColor: "#F6F7FF",
  border: "none",
  borderRadius: "0.9375rem",
  boxShadow: "0rem 0rem 0.625rem rgba(0, 0, 0, 0.15)",
  left: "50%",
  maxWidth: "624px",
  outline: "none",
  padding: "20px",
  position: "absolute",
  top: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
};

const StaffList = ({ staffMembers, dark = false }) => {
  const accessToken = useRecoilValue(UserAccessTokenState);
  const [show, setShow] = useRecoilState(staffListModalOpenState);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const setStaffRefetchKey = useSetRecoilState(staffRefetchKeyState);

  const handleStaffCreate = async () => {
    if (email && isValidEmail(email) && name) {
      try {
        const resp = await createStaff(accessToken, email, name);
        if (!resp.password) {
          return ErrorToast(
            "Error",
            "Some error occurred while creating new staff"
          );
        }
        setStaffRefetchKey((prev) => prev + 1);
        navigator.clipboard.writeText(resp.password);
        SuccessToast("Staff Created!", "Staff password copied to clipboard");
      } catch (e) {
        const msg = axiosErrorHandler(e);
        return ErrorToast("Error", msg);
      }
    }
  };

  return (
    <Modal
      className={`${styles.modal} ${dark ? "dark" : ""}`}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={show}
      onClose={() => setShow(false)}
      closeAfterTransition
    >
      <Fade in={show}>
        <Box sx={style}>
          <div className={styles.staffList}>
            <div className={styles.header}>Staff Lists</div>
            <div className={styles.row}>
              <Input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                placeholder="remuser@gmail.com"
                style={{ marginRight: "1rem" }}
              />
              <Input
                value={name}
                onChange={(e) => setName(e.target.value)}
                type="text"
                placeholder="John Doe"
              />
              <button onClick={handleStaffCreate} disabled={!email && !name}>
                Add New Staff
              </button>
            </div>
            <div className={styles.divider} />
            {staffMembers.map((staff) => (
              <StaffItem key={staff._id} email={staff.email} id={staff._id} />
            ))}
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};

export default StaffList;
