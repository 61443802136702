import React from "react";
import { format } from "date-fns";

import { findLatestNextSaleStartDate } from "../events/BannerBottom";

import styles from "../../styles/dashboard/Dashboard.module.css";

function formatTimestampToCustomFormat(timestamp) {
  const date = new Date(timestamp);
  return format(date, "dd MMMM yyyy, hh:mma");
}

export default function Values({ data }) {
  if (!data) return null;
  return (
    <div className={styles.scrollContainer}>
      {data?.map((item) => {
        const latest = findLatestNextSaleStartDate(item?.sales);
        function getEventStatus(event) {
          const today = new Date();
          const saleStartDate = new Date(event.saleStartDate);
          const saleEndDate = new Date(event.saleEndDate);

          if (saleStartDate > today) {
            return "upcoming";
          } else if (saleEndDate < today) {
            return "completed";
          } else {
            return "active";
          }
        }

        return (
          <div
            key={item._id}
            className={`
            ${styles.values} 
            ${styles.gridContainer}`}
          >
            <div className={styles.eventImage}>
              <img src={item?.eventBanner} alt="event" />
            </div>
            <div className={styles.header}>
              <div>{item?.eventName}</div>
            </div>
            <div className={styles.header}>
              {item?.venueName} {item?.venueLocation}, {item?.country}
            </div>
            <div className={styles.content}>
              {formatTimestampToCustomFormat(item?.eventStartDate)}
            </div>
            <div className={styles.content}>
              {formatTimestampToCustomFormat(item?.eventEndDate)}
            </div>
            <div className={styles.content}>
              {formatTimestampToCustomFormat(latest?.saleStartDate)}
            </div>
            <div className={styles.content}>
              <div>
                <span>Total: </span>
                <span className={styles.normal}>{item?.totalTickets || 0}</span>
              </div>
              <div>
                <span>Sold: </span>
                <span className={styles.normal}>{item?.soldTickets || 0}</span>
              </div>
            </div>
            {getEventStatus(latest) === "completed" && (
              <div
                style={{
                  color: "#767BFA",
                }}
              >
                <span
                  style={{
                    backgroundColor: "#767BFA",
                  }}
                  className={styles.status}
                />
                Completed
              </div>
            )}
            {getEventStatus(latest) === "active" && (
              <div
                style={{
                  color: "#12c519",
                }}
              >
                <span
                  style={{
                    backgroundColor: "#12c519",
                  }}
                  className={styles.status}
                />
                Active
              </div>
            )}
            {getEventStatus(latest) === "upcoming" && (
              <div
                style={{
                  color: "#12c519",
                }}
              >
                <span
                  style={{
                    backgroundColor: "#12c519",
                  }}
                  className={styles.status}
                />
                Upcoming
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}
