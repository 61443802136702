import axios from "axios";

export default async function uploadDesignAsset(token, file) {
  if (file.size > 4 * 1024 * 1024) {
    throw new Error("Size of the picture should be under 4MB");
  }

  let formData = new FormData();
  formData.append("file", file);

  const resp = await axios.post(
    `${process.env.REACT_APP_ORGANISER_API}/staff-pass/design/upload`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        token,
      },
    }
  );

  if (!resp.data) {
    throw new Error(resp.error.message);
  }

  if (resp.error) {
    throw new Error(resp.error);
  }

  return resp.data.url;
}
