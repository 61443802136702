import { useRef, useState } from "react";

import translate from "../../../utils/temp/translate";
import Input from "../../general/Input";

import modalStyles from "../../../styles/modals/Modal.module.css";
import styles from "../../../styles/modals/SearchModal.module.css";

export function SearchInput({ search, setSearch }) {
  // const { query, refine, isSearchStalled } = useSearchBox();
  const inputRef = useRef(null);

  function setQuery(newQuery) {
    setSearch(newQuery);
    // refine(newQuery);
  }

  // if (query !== search && !inputRef.current?.isFocused()) {
  //   setSearch(query);
  // }

  return (
    <div className={styles.inputWrapper}>
      <div>
        <div className={modalStyles.label}>{translate("Event Name")}</div>
        <Input
          ref={inputRef}
          name="search"
          type={"text"}
          value={search}
          updateValue={(e) => {
            setQuery(e.target.value);
          }}
          placeholder={translate("search")}
        />
      </div>
    </div>
  );
}
