import { useEffect, useState } from "react";
import _ from "lodash";
import { useRecoilValue } from "recoil";

import bookmark from "../../assets/images/bookmark.png";
import locker from "../../assets/images/locker.png";
import money from "../../assets/images/money.png";
import getRecentActivity from "../../functions/getRecentActivity";
import { selectedEventIdState } from "../../recoil/events";
import { counterListState } from "../../recoil/pos";
import { totalScanDataState } from "../../recoil/scanner";
import { UserAccessTokenState } from "../../recoil/user";
import translate from "../../utils/temp/translate";
import timeAgo from "../../utils/timeAgo";
import { StatDetails } from "../customers/StatDetails";
import RecentActivityItem from "../events/RecentActivityItem";

import styles from "../../styles/eventScanner/RecentActivity.module.css";
import gridStyles from "../../styles/general/Grid.module.css";

const MOCK_ACTIVITY_DATA = [
  {
    __v: 0,
    _id: "6568c192f0cbb4bf73f8639d",
    eventId: "6568c143b822534f4518654f",
    eventType: "ticketPurchased",
    organiserId: "654623ef606be93d9b044932",
    payload: {
      buyerId: "6rbhNNLnEkQiDFhUZebLngZQjGw1",
      buyerName: "Remus",
      counter: 1,
      date: "2023-11-30T17:08:34.121Z",
      quantity: 11,
      ticketType: "Standing",
      venueName: "Capitol Theatre",
    },
  },
  {
    __v: 0,
    _id: "6568c192f0cbb4bf73f8639d",
    eventId: "6568c143b822534f4518654f",
    eventType: "ticketPurchased",
    organiserId: "654623ef606be93d9b044932",
    payload: {
      buyerId: "6rbhNNLnEkQiDFhUZebLngZQjGw1",
      buyerName: "Remus",
      counter: 1,
      date: "2023-11-30T17:08:34.121Z",
      quantity: 11,
      ticketType: "Standing",
      venueName: "Capitol Theatre",
    },
  },
];

export default function RecentActivity({
  totalSale,
  totalTicketSold,
  totalOpeningBalance,
}) {
  const accessToken = useRecoilValue(UserAccessTokenState);
  const [activityData, setActivityData] = useState([]);
  const selectedEvent = useRecoilValue(selectedEventIdState);
  const counterList = useRecoilValue(counterListState);

  const getCounterNumber = (counterId) => {
    const index = counterList.findIndex((counter) => counter._id === counterId);
    return `Counter-${String(index + 1).padStart(3, "0")}`;
  };

  useEffect(() => {
    (async () => {
      try {
        const resp = await getRecentActivity(
          accessToken,
          selectedEvent,
          10,
          "ticketPurchasedFromPOS"
        );
        setActivityData(resp);
      } catch (err) {
        console.log(err);
      }
    })();
  }, [selectedEvent]);

  return (
    <>
      <div className={`${gridStyles.headersection} flex al-ce jc-sp`}>
        <div>RECENT ACTIVITY</div>
      </div>
      <div className={styles.container}>
        <div className={styles.listContainer}>
          {activityData &&
            activityData?.map((activity, index) => {
              const { timeAgoString } = timeAgo(activity?.payload?.date);
              return (
                <RecentActivityItem
                  key={index}
                  timeAgo={timeAgoString}
                  name={activity?.payload?.buyerName}
                  activity={`${activity?.payload?.quantity}  ${activity?.payload?.ticketType}`}
                  type={"pos"}
                  counter={getCounterNumber(activity?.payload?.counter)}
                />
              );
            })}
        </div>
        <div className={styles.totalsContainer}>
          <div className={styles.statsWrapper}>
            <div className={styles.statContainer}>
              <StatDetails
                left
                iconSrc={bookmark}
                header={totalTicketSold || 0}
                subtext={"TOTAL TICKETS SOLD ON POS"}
              />
            </div>
            <div className={styles.hr} />
            <div className={styles.statContainer}>
              <StatDetails
                left
                iconSrc={money}
                header={`$${totalSale || 0}`}
                subtext={"TOTAL REVENUE ON POS"}
              />
            </div>
            <div className={styles.hr} />
            <div className={styles.statContainer}>
              <StatDetails
                left
                iconSrc={locker}
                header={`$${totalOpeningBalance || 0}`}
                subtext={"TOTAL OPENING BALANCE ON POS"}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
