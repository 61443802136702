import React from "react";
import { useRecoilValue } from "recoil";

import { ACTIVE_CARD_FACE_VIEW, activeViewState } from "../../recoil/staff";

import CardBackView from "./CardBackView";
import CardFrontView from "./CardFrontView";
import SwitchButton from "./SwitchButton";

import styles from "../../styles/staffPass/StaffPass.module.css";

export default function ReadyCardDesignPreview() {
  const activeView = useRecoilValue(activeViewState);
  return (
    <div className={styles.cardDesignPreview}>
      {activeView === ACTIVE_CARD_FACE_VIEW.FRONT ? (
        <CardFrontView />
      ) : (
        <CardBackView />
      )}
      <SwitchButton />
    </div>
  );
}
