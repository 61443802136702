import { useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import { ErrorToast } from "../components/general/ToastNotification";
import getCustomersSummary from "../functions/customers/getCustomersSummary";
import {
  customersFetchKeyState,
  customersStatState,
} from "../recoil/customers";
import { UserAccessTokenState } from "../recoil/user";
import axiosErrorHandler from "../utils/axiosErrorHandler";

export default function useCustomerStats() {
  const [stats, setStats] = useRecoilState(customersStatState);
  const accessToken = useRecoilValue(UserAccessTokenState);
  const fetchKey = useRecoilValue(customersFetchKeyState);

  useEffect(() => {
    (async () => {
      try {
        const resp = await getCustomersSummary(accessToken);
        setStats(resp);
      } catch (e) {
        const msg = axiosErrorHandler(e);
        ErrorToast("Error", msg);
      }
    })();
  }, [fetchKey]);

  return stats;
}
