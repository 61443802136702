import React, { useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { useRecoilState, useRecoilValue } from "recoil";

import {
  ACTIVE_CARD_FACE_VIEW,
  activeViewState,
  addCardInputFieldsState,
  pdfGenerationTriggerState,
  viewPassModalDataState,
} from "../../recoil/staff";

import { HorizontalDimension, VerticalDimension } from "./Dimension";

import styles from "../../styles/staffPass/StaffPass.module.css";

const pageStyle = `@page {
  size: 90mm 135mm;
}
`;

export default function CardBackView() {
  const cardDesignInputs = useRecoilValue(addCardInputFieldsState);
  const isSquare = cardDesignInputs.dimension === "135x135";

  const viewPassModalData = useRecoilValue(viewPassModalDataState);
  const [trigger, setTrigger] = useRecoilState(pdfGenerationTriggerState);
  const activeView = useRecoilValue(activeViewState);
  const ref = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => ref.current,
    onAfterPrint: () => setTrigger(false), // Reset trigger after printing
    pageStyle: pageStyle,
  });

  const designBg =
    cardDesignInputs.backImageUrl || viewPassModalData?.backImageUrl;

  // Effect to listen for the trigger and print
  useEffect(() => {
    if (trigger && handlePrint && activeView === ACTIVE_CARD_FACE_VIEW.BACK) {
      handlePrint();
    }
  }, [trigger, handlePrint]);

  return (
    <div
      className={
        styles[
          isSquare
            ? "squareCardDesignPreviewContainer"
            : "cardDesignPreviewContainer"
        ]
      }
    >
      <HorizontalDimension
        isSquare={isSquare}
        dimension={cardDesignInputs.dimension.split("x")[1]}
      />
      {!isSquare && (
        <VerticalDimension
          dimension={cardDesignInputs.dimension.split("x")[0]}
        />
      )}
      <div
        ref={ref}
        style={{ margin: "auto", position: "relative", width: "max-content" }}
      >
        {!isSquare ? (
          <>
            <div className={styles.cardBackDesignPreview}>
              <img src={designBg} />
            </div>
          </>
        ) : (
          <div className={styles.squadeCardBackDesignPreview}>
            <div
              className={styles.bg}
              style={{
                backgroundImage: `url("${designBg}`,
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}
