import axios from "axios";

export default async function deleteBankAccount(access_token, accountNumber) {
  const response = await axios.delete(
    `${process.env.REACT_APP_ORGANISER_API}/bank/delete`,
    {
      data: { accountNumber },
      headers: {
        token: access_token,
      },
    }
  );

  if (!response.data) {
    throw new Error("Some error occurred while fetching.");
  }
  if (response.data.error) {
    throw new Error(response.data.error);
  }

  return response.data;
}
