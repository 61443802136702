import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { useRecoilValue } from "recoil";

import { dateFormat } from "../../constants/dateFormat";
import getFeesSummary from "../../functions/wallet/getFeesSummary";
import { UserAccessTokenState } from "../../recoil/user";
import axiosErrorHandler from "../../utils/axiosErrorHandler";
import { ErrorToast } from "../general/ToastNotification";

import gridStyles from "../../styles/general/Grid.module.css";
import styles from "../../styles/wallet/FeesSummary.module.css";
const STUB_FEES_SUMMARY = [
  {
    eventDate: "2023-11-11",
    eventName: "Very Famous Event",
    feesPerEvent: 10,
    feesPerTicket: 10,
    id: 1,
    profit: 800,
    ticketSold: 1000,
    totalAmountSold: 10878,
    totalFees: 100,
  },
  {
    eventDate: "2023-11-11",
    eventName: "Very Famous Event",
    feesPerEvent: 10,
    feesPerTicket: 10,
    id: 2,
    profit: 800,
    ticketSold: 1000,
    totalAmountSold: 10878,
    totalFees: 100,
  },
];

export default function FeesSummary() {
  const accessToken = useRecoilValue(UserAccessTokenState);
  const [feesSummary, setFeesSummary] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const resp = await getFeesSummary(accessToken);
        setFeesSummary(resp);
      } catch (e) {
        const msg = axiosErrorHandler(e);
        ErrorToast("Error", msg);
      }
    })();
  }, []);

  return (
    <div className={gridStyles.gridContainer}>
      <div className={`${gridStyles.gridHeaders} ${styles.headerColumns}`}>
        <div>Event Name</div>
        <div>Event Date</div>
        <div>Total Amount Sold</div>
        <div>Ticket Sold</div>
        <div>Fees (per event)</div>
        <div>Fees (per ticket)</div>
        <div>Total Fees</div>
        <div>Profit After Fees</div>
      </div>
      <div className={styles.itemRows}>
        {feesSummary &&
          feesSummary?.map((feeItem) => (
            <div
              className={`${gridStyles.gridItems} ${styles.itemColumns}`}
              key={feeItem.id}
            >
              <div>{feeItem.eventName}</div>
              <div>{format(new Date(feeItem.dateStart), dateFormat)}</div>
              <div>$ {feeItem.totalAmount} SGD</div>
              <div>{feeItem.totalQuantity}</div>
              <div>$0 SGD</div>
              <div>
                $
                {(feeItem.totalOrganiserFees / feeItem.totalQuantity).toFixed(
                  2
                )}{" "}
                SGD
              </div>
              <div className="red">$ {feeItem.totalOrganiserFees} SGD</div>
              <div className={`green`}>$ {feeItem.profitAfterFees} SGD</div>
            </div>
          ))}
      </div>
    </div>
  );
}
