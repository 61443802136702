import React, { Fragment, useRef } from "react";
import Slider from "react-slick";

import translate from "../../../utils/temp/translate";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "../../../styles/modals/Modal.module.css";

const CustomPrevArrow = (props) => {
  const { onClick } = props;
  return (
    <button
      className={`${styles["custom-arrow"]} ${styles["prev-arrow"]}`}
      onClick={onClick}
      disabled={props.currentSlide === 0}
    >
      <img
        src={require("../../../assets/arrow.svg").default}
        alt="arrow-left"
        className={styles.arrow}
      />
    </button>
  );
};

const CustomNextArrow = (props) => {
  const { onClick } = props;
  return (
    <button
      className={`${styles["custom-arrow"]} ${styles["next-arrow"]}`}
      onClick={onClick}
      disabled={props.currentSlide + props.slidesToScroll >= props.slideCount}
    >
      <img
        src={require("../../../assets/arrow.svg").default}
        alt="arrow-left"
        className={styles.arrow}
      />
    </button>
  );
};

const CustomSlider = ({ packagesData, handleRemovePackage }) => {
  const sliderRef = useRef(null);

  const settings = {
    arrows: false,
    centerMode: false,
    centerPadding: "10px",
    infinite: false,
    slidesToScroll: 2,
    slidesToShow: 2,
  };

  if (packagesData.length < 1) return null;

  return (
    <div className={styles["slider-container"]}>
      <CustomPrevArrow onClick={() => sliderRef?.current.slickPrev()} />
      <CustomNextArrow onClick={() => sliderRef?.current.slickNext()} />
      <Slider ref={sliderRef} className={styles["custom-slider"]} {...settings}>
        {packagesData.map((packageData, i) => (
          <>
            <DisplayItem
              key={i}
              index={i}
              packageData={packageData}
              handleRemovePackage={() =>
                handleRemovePackage(
                  packageData.categoryIndex,
                  packageData.typeIndex
                )
              }
            />
          </>
        ))}
        {packagesData.length % 2 !== 0 && <div />}
      </Slider>
    </div>
  );
};

const DisplayItem = ({ index, packageData, handleRemovePackage }) => {
  const isEven = (index + 1) % 2 === 0;
  return (
    <div className={styles.slider}>
      <div
        className={styles.selectedPackage}
        style={{
          margin: "10px",
          marginLeft: isEven ? "10px" : "auto",
          marginRight: isEven ? "auto" : "10px",
        }}
      >
        <div className={styles.label}>
          {packageData?.ticketTypeName}

          <span>
            <img
              onClick={() => handleRemovePackage(index)}
              src={require("../../../assets/red-close.svg").default}
              alt="close"
              className={styles.arrow}
            />
          </span>
        </div>

        <div className={styles.valueBox}>
          <div className={styles.packageLabel}>Section:</div>
          <div className={styles.packageValue}>
            {packageData?.ticketSection}
          </div>
        </div>
        <div className={styles.valueBox}>
          <div className={styles.packageLabel}>Price:</div>
          <div className={styles.packageValue}>${packageData?.price} SGD</div>
        </div>
        <div className={styles.valueBox}>
          <div className={styles.packageLabel}>Items:</div>
          <div className={styles.packageValue}>
            {packageData?.itemsIncluded ? (
              <>
                {packageData.itemsIncluded.split("\n").map((line, i) => (
                  <Fragment key={i}>
                    {line}
                    <br />
                  </Fragment>
                ))}
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomSlider;
