import React, { useState } from "react";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import { useRecoilState, useRecoilValue } from "recoil";

import addSvg from "../../../assets/add.svg";
import Close from "../../../assets/close.svg";
import CloseDark from "../../../assets/closedark.svg";
import redClose from "../../../assets/red-close.svg";
import { BannerEventData } from "../../../constants/EventDemoData";
import {
  acknowledgementModalState,
  addEventModalState,
} from "../../../recoil/modal";
import { darkModeState } from "../../../recoil/settings";
import translate from "../../../utils/temp/translate";
import EventDetails from "../../events/EventDetails";
import SeatDetails from "../../events/SeatDetails";
import Button from "../../general/Button";
import Input from "../../general/Input";
import Select from "../../general/Select";

import CustomSlider from "./Slider";

import styles from "../../../styles/modals/Modal.module.css";

export const style = (dark) => {
  return {
    bgcolor: dark ? "#323A52" : "#F6F7FF",
    border: "none",
    borderRadius: "0.9375rem",
    boxShadow: "0rem 0rem 0.625rem rgba(0, 0, 0, 0.15)",
    color: !dark ? "#323A52" : "#F6F7FF",
    left: "50%",
    maxWidth: "72.0625rem",
    outline: "none",
    padding: "1.25rem 0",
    position: "absolute",
    top: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
  };
};

export default function AcknowledgementModal() {
  const dark = useRecoilValue(darkModeState);
  const [open, setOpen] = useRecoilState(acknowledgementModalState);

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <Modal
        className={` ${dark ? "dark" : ""}`}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
      >
        <Fade in={open}>
          <div className={styles.acknowledgementBox}>
            <div className={styles.secondaryHeader}>
              <img
                onClick={handleClose}
                src={dark ? CloseDark : Close}
                alt=""
              />
            </div>
            <div className={styles.acknowledgement}>
              Your request to edit this event has been sent to Ingenium+. Please
              wait for approval.
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
