import { atom } from "recoil";

export const saleIdState = atom({
  default: "",
  key: "saleIdState",
});

export const fairnessLevelState = atom({
  default: 0,
  key: "fairnessLevelState",
});

export const selectedFairnessDataState = atom({
  default: undefined,
  key: "selectedFairnessDataState",
});

export const fairnessFetchKeyState = atom({
  default: 0,
  key: "fairnessFetchKeyState",
});
