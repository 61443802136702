import { useRecoilState, useSetRecoilState } from "recoil";

import {
  currentThemeState,
  defaultThemeColors,
  footerLinksState,
  heroState,
  logoState,
  themeColorsState,
  websiteLinkState,
} from "../recoil/webpage";

export default function useThemeData() {
  const [logo, setLogo] = useRecoilState(logoState);
  const [heroData, setHeroData] = useRecoilState(heroState);
  const [themeColors, setThemeColors] = useRecoilState(themeColorsState);
  const [footerLinks, setFooterLinks] = useRecoilState(footerLinksState);
  const [pathname, setPathname] = useRecoilState(websiteLinkState);
  const setActiveTheme = useSetRecoilState(currentThemeState);

  const setThemeData = (data) => {
    if (data) {
      setActiveTheme(data.theme);
      setPathname(data.pathname);
      setLogo(data.logoUrl);
      setHeroData({
        heroImage: data.heroImageUrl,
        primaryText: data.primaryText,
        secondaryText: data.secondaryText,
      });
      setThemeColors({
        accentColor1: data.accentColor1 || defaultThemeColors.accentColor1,
        accentColor2: data.accentColor2 || defaultThemeColors.accentColor2,
        accentColor3: data.accentColor3 || defaultThemeColors.accentColor3,
        backgroundColor:
          data.backgroundColor || defaultThemeColors.backgroundColor,
        footerBackground:
          data.footerBackground || defaultThemeColors.footerBackground,
      });
      setFooterLinks({
        facebook: data.facebook,
        instagram: data.instagram,
        twitter: data.twitter,
      });
    }
  };

  const getThemePayload = (activeTheme) => {
    return {
      heroImageUrl: heroData.heroImage,
      logoUrl: logo,
      pathname,
      primaryText: heroData.primaryText,
      secondaryText: heroData.secondaryText,
      theme: activeTheme,
      ...themeColors,
      ...footerLinks,
    };
  };

  return { getThemePayload, setThemeData };
}
