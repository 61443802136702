import React, { useState } from "react";

import SubscribePlan from "../components/dashboard/SubscribePlan";
import createSubscription from "../functions/createSubscription";

import styles from "../styles/subscribe/index.module.css";

export default function Subscribe() {
  const [loading, setLoading] = useState(false);
  async function handleCreateSubscription() {
    setLoading(true);
    try {
      const response = await createSubscription();
    } catch (error) {
      console.error("There was an error!", error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className={styles.subscribe}>
      <div className={styles.subscribePlan}>
        <SubscribePlan
          loading={loading}
          text={"Subscribe"}
          onClick={handleCreateSubscription}
        />
      </div>
      <img
        className={styles.blurImg}
        src={require("../assets/images/bg.webp")}
        alt="subscribe"
      />
    </div>
  );
}
