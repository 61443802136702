import axios from "axios";

export default async function createStaffPass(access_token, data) {
  if (!data.eventId) throw new Error("Event Id not provided");
  if (!data.email) throw new Error("Staff Email Name not provided");
  if (!data.designId) throw new Error("Pass Design ID not provided");
  if (!data.areaName) throw new Error("Area Name not provided");
  if (!data.role) throw new Error("Role Image not provided");

  const resp = await axios.post(
    `${process.env.REACT_APP_ORGANISER_API}/staff-pass/create`,
    {
      ...data,
    },
    {
      headers: {
        token: access_token,
      },
    }
  );

  if (!resp.data) {
    throw new Error("Some error occured");
  }
  if (resp.data.error) {
    throw new Error(resp.data.error);
  }

  return resp.data;
}
