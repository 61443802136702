import React, { useEffect, useState } from "react";
import { Fade, Modal } from "@mui/material";
import { format } from "date-fns";
import { useRecoilValue } from "recoil";

import createEventAreas from "../../functions/staffPass/createEventAreas";
import editEventAreas from "../../functions/staffPass/editEventAreas";
import {
  selectedEventDataState,
  selectedEventIdState,
} from "../../recoil/events";
import { UserAccessTokenState } from "../../recoil/user";
import axiosErrorHandler from "../../utils/axiosErrorHandler";
import Button from "../general/Button";
import CircularLoader from "../general/CircularLoader";
import Input from "../general/Input";
import { ErrorToast, SuccessToast } from "../general/ToastNotification";

import { MODAL_STYLE } from "./DeleteScanner";

import styles from "../../styles/modals/Modal.module.css";

const NewConfigurationModal = ({
  onClose,
  open,
  refetch,
  isEditMode,
  editData,
}) => {
  const [areas, setAreas] = useState([]);
  const accessToken = useRecoilValue(UserAccessTokenState);
  const [area, setArea] = useState("");
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [loading, setLoading] = useState(false);
  const eventId = useRecoilValue(selectedEventIdState);
  const selectedEventData = useRecoilValue(selectedEventDataState);
  const eventName = selectedEventData?.eventName;

  // Format the date to the datetime-local format (YYYY-MM-DDTHH:mm)

  useEffect(() => {
    if (isEditMode && editData) {
      const startDate = new Date(editData.startTime);
      const startDateTimeLocalString = format(startDate, "yyyy-MM-dd'T'HH:mm");

      const endDate = new Date(editData.endTime);
      const endDateTimeLocalString = format(endDate, "yyyy-MM-dd'T'HH:mm");

      setAreas(editData.areaNames);
      setStartTime(startDateTimeLocalString);
      setEndTime(endDateTimeLocalString);
    } else {
      setAreas([]);
      setStartTime();
      setEndTime();
    }
  }, [editData, isEditMode]);

  const addArea = (area) => {
    if (area) {
      setAreas([...areas, area]);
    }
  };

  const removeArea = (index) => {
    setAreas(areas.filter((_, i) => i !== index));
  };

  const handleCreateEventAreas = async () => {
    if (!loading) {
      try {
        setLoading(true);
        const resp = await createEventAreas(accessToken, {
          areaNames: areas,
          endTime,
          eventId,
          startTime,
        });

        refetch();
        onClose();
        SuccessToast("Success", "Event Area Configuration Saved");
      } catch (e) {
        const msg = axiosErrorHandler(e);
        ErrorToast("Error", msg);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleEditEventAreas = async () => {
    if (!loading) {
      try {
        setLoading(true);
        const resp = await editEventAreas(accessToken, eventId, {
          areaNames: areas,
          endTime,
          eventId,
          startTime,
        });

        refetch();
        onClose();
        SuccessToast("Success", "Event Area Configuration Saved");
      } catch (e) {
        const msg = axiosErrorHandler(e);
        ErrorToast("Error", msg);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Modal
      className={styles.modalContainer}
      open={open}
      onClose={onClose}
      disableEnforceFocus
      style={MODAL_STYLE}
    >
      <Fade
        in={open}
        style={{
          border: "none",
          outline: "none",
        }}
      >
        <div
          className={`${styles.modal} ${styles.addNewCardDesignModal}`}
          style={{
            maxWidth: "580px",
            width: "100%",
          }}
        >
          <div className={styles.modalHeader}>
            <div>{!isEditMode ? "Adding New" : "Editing"} Configuration...</div>
            <img
              className={styles.cursor}
              onClick={onClose}
              src={require("../../assets/close.svg").default}
            />
          </div>
          <form className={styles.modalForm}>
            <label htmlFor="eventName">Event Name</label>
            <Input
              type={"text"}
              name={"eventName"}
              value={eventName}
              onChange={(e) => {}}
              placeholder={"Event Name"}
            />

            <label>Area</label>
            <Input
              suffix={
                <img
                  src={require("../../assets/add.svg").default}
                  className={styles.cursor}
                  onClick={() => {
                    addArea(area);
                    setArea("");
                  }}
                />
              }
              type={"text"}
              value={area}
              onChange={(e) => setArea(e.target.value)}
              placeholder={"Area"}
            />

            <div className={styles.areaContainer}>
              {areas.map((area, index) => (
                <div
                  key={index}
                  className={styles.areaEntry}
                  style={
                    index === 0
                      ? {
                          borderTop: "1px solid #abafd1",
                          marginTop: "20px",
                        }
                      : null
                  }
                >
                  <div>{area}</div>
                  <img
                    onClick={() => removeArea(index)}
                    className={styles.cursor}
                    src={require("../../assets/red-close.svg").default}
                  />
                </div>
              ))}
            </div>

            <div className={styles.timeInputs}>
              <div className={styles.timeInput}>
                <label>Start Time</label>
                <Input
                  value={startTime}
                  onChange={(e) => setStartTime(e.target.value)}
                  placeholder={"13/3/2024 8:00 AM"}
                  type={"datetime-local"}
                />
              </div>
              <div className={styles.timeInput}>
                <label>End Time</label>
                <Input
                  value={endTime}
                  onChange={(e) => setEndTime(e.target.value)}
                  placeholder={"13/3/2024 8:00 PM"}
                  type={"datetime-local"}
                />
              </div>
            </div>

            <div className={styles.addConfiguration}>
              {loading ? (
                <div className={styles.loaderContainer}>
                  <div className={styles.loader}>
                    <CircularLoader loading={true} />
                  </div>
                </div>
              ) : (
                <Button
                  onClick={
                    isEditMode ? handleEditEventAreas : handleCreateEventAreas
                  }
                  text={`${!isEditMode ? "Add" : "Edit"} Area`}
                  imgSrc={
                    <img
                      src={require("../../assets/add.svg").default}
                      alt="plus"
                    />
                  }
                />
              )}
            </div>
          </form>
        </div>
      </Fade>
    </Modal>
  );
};

export default NewConfigurationModal;
