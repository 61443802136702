import React from "react";
import { Navigate, Route } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { UserDataState } from "../../recoil/user";

const ProtectedRoute = ({ element }) => {
  const userData = useRecoilValue(UserDataState);

  if (!userData) {
    return <Navigate to="/login" replace />;
  }

  return element;
};

export default ProtectedRoute;
