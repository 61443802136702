import axios from "axios";

export default async function editPassDesign(access_token, data, designId) {
  console.log("Edit data", { data });
  if (!designId) throw new Error("Design ID must be provided");
  if (!data.eventId) throw new Error("Event Id not provided");
  if (!data.name) throw new Error("Design Name not provided");
  if (!data.logoUrl) throw new Error("Logo not provided");
  if (!data.backImageUrl) throw new Error("Back Image not provided");

  const resp = await axios.post(
    `${process.env.REACT_APP_ORGANISER_API}/staff-pass/design/edit/${designId}`,
    {
      accent1: data.accent1,
      accent2: data.accent2,
      backImageUrl: data.backImageUrl,
      bgColor: data.bgColor,
      dimension: data.dimension,
      eventId: data.eventId,
      logoUrl: data.logoUrl,
      name: data.name,
      nameColor: data.nameColor,
      roleColor: data.roleColor,
    },
    {
      headers: {
        token: access_token,
      },
    }
  );

  if (!resp.data) {
    throw new Error("Some error occured");
  }
  if (resp.data.error) {
    throw new Error(resp.data.error);
  }

  return resp.data;
}
