import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useRecoilValue } from "recoil";

import { selectedEventIdState } from "../../recoil/events";
import { UserAccessTokenState } from "../../recoil/user";

import styles from "../../styles/events/Events.module.css";

const generateXAxisLabels = (hours) => {
  const labels = [];
  let currentHour = new Date().getHours();
  for (let i = 0; i < hours; i++) {
    const formattedHour = currentHour % 12 || 12;
    const ampm = currentHour >= 12 ? " PM" : " AM";
    labels.push(`${formattedHour}${ampm}`);
    currentHour--;
  }
  return labels.reverse();
};

const LineGraph = () => {
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const selectedEvent = useRecoilValue(selectedEventIdState);
  const accessToken = useRecoilValue(UserAccessTokenState);

  const midNight = new Date(new Date().toDateString()).toISOString();
  const hours = 9;

  useEffect(() => {}, [selectedEvent]);

  return (
    <div className={styles.graph}>
      <div className={styles.header}>Queue Passed</div>
      <LineChart data={data} hours={hours} />
      <div className={styles.total}>
        {count > 1 ? `${count} User` : `${count} Users`} <span>passed...</span>
      </div>
    </div>
  );
};

const LineChart = ({ data = [], hours }) => {
  const chartData = {
    options: {
      chart: {
        toolbar: {
          show: false,
        },
        type: "line",
      },
      colors: ["#12C519"],
      dataLabels: {
        enabled: false,
      },
      fill: {
        gradient: {
          colorStops: [
            {
              color: "#f6f7ff",
              offset: 0,
              opacity: 1,
            },
            {
              color: "#12C519",
              offset: 100,
              opacity: 1,
            },
          ],
          opacityFrom: 0.7,
          opacityTo: 0.9,
          shadeIntensity: 1,
        },
        type: "gradient",
      },
      grid: {
        borderColor: "#E4E5ED",
        position: "back",

        show: true,
        strokeDashArray: 0, // Place y-axis grid behind the chart

        xaxis: {
          lines: {
            show: true, // Hide x-axis grid lines
          },
        },
        yaxis: {
          lines: {
            show: false, // Show y-axis grid lines
          },
        },
      },
      legend: {
        show: false,
      },
      markers: {
        size: 0,
      },
      stroke: {
        curve: "smooth", // Use a smooth curve
        width: 2,
      },
      xaxis: {
        categories: generateXAxisLabels(hours),
        labels: {
          style: {
            colors: "#A9AAB1",
            fontFamily: "Poppins",
            fontSize: "15px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "normal",
            textAlign: "center",
          },
        },
        type: "string",
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return value.toFixed(0); // Customize y-axis labels if needed
          },
          show: true,
          style: {
            colors: "#A9AAB1",
            fontFamily: "Poppins",
            fontSize: "15px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "normal",
            textAlign: "center",
          },
        },
        show: true,
      },
    },

    series: [
      {
        data: data,
        name: "Value",
      },
    ],
  };

  return (
    <ReactApexChart
      options={chartData.options}
      series={chartData.series}
      type="line"
      height={300}
    />
  );
};

export default LineGraph;
