import React, { useEffect, useState } from "react";
import _ from "lodash";

import Button from "../general/Button";
import CreateCaptchaModal from "../modals/createCaptcha/CreateCaptcha";

import styles from "../../styles/events/TicketSold.module.css";

export default function Captcha({ captchaQuestions }) {
  const [open, setOpen] = useState(false);
  const [isEditMode, toggleEditMode] = useState(false);
  const [answers, setAnswers] = useState([]);
  const [data, setData] = useState({});
  const [editData, setEditData] = useState({});

  useEffect(() => {
    setData(_.keyBy(captchaQuestions, "_id"));
  }, [captchaQuestions]);

  // useEffect(() => {
  //   setFairnessData((prev) => ({
  //     ...prev,
  //     captchaQuestions: Object.values(data),
  //   }));
  // }, [data]);

  const handleClose = () => {
    setEditData({});
    toggleEditMode(false);
    setOpen(false);
  };

  return (
    <div className={styles.bannerSection}>
      <div className={styles.header}>Captcha</div>
      <CreateCaptchaModal
        isEditMode={isEditMode}
        payload={{ answers, data, editData: editData, setAnswers, setData }}
        open={open}
        handleClose={handleClose}
      />
      <div className={styles.box}>
        <Button
          text={"Create New"}
          onClick={() => {
            setOpen(true);
          }}
          customClass={"createCaptcha"}
        />

        <div>
          <div className={`${styles.captchaGrid} ${styles.headers}`}>
            <div>Question</div>
            <div>Answer</div>
            <div>Action</div>
          </div>

          {Object.entries(data).map(([key, item]) => (
            <div key={item.id} className={styles.captchaGrid}>
              <div>{item.question}</div>
              <div>{item.answers.join(", ")}</div>
              <div className={styles.action}>
                <img
                  src={require("../../assets/edit.webp")}
                  onClick={() => {
                    toggleEditMode(true);
                    setEditData(item);
                    setOpen(true);
                  }}
                />
                <img
                  src={require("../../assets/trash.svg").default}
                  onClick={() => {
                    const newData = { ...data };
                    delete newData[key];
                    setData(newData);
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
