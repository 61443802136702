import React from "react";

import styles from "../../styles/general/ColorPickerWithPreview.module.css";

const ColorPickerWithPreview = ({ label, value, onChange }) => {
  return (
    <div className={styles.colorPickerContainer}>
      <label className={styles.colorLabel}>{label}</label>
      <div className={styles.colorPicker}>
        <div
          className={styles.colorPreview}
          style={{ backgroundColor: value }}
        />
        <input
          type="color"
          value={value}
          onChange={onChange}
          className={styles.colorInput}
        />
        <div className={styles.text}>Pick a color</div>
      </div>
    </div>
  );
};

export default ColorPickerWithPreview;
