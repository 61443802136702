import React from "react";

import styles from "../../styles/dashboard/Dashboard.module.css";

export default function UserDetails({ userData }) {
  console.log(userData);
  function formatDate(inputDate) {
    const date = new Date(inputDate);
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    const formattedDate = `${day} ${month} ${year}`;

    return formattedDate;
  }

  const inputDate = "2023-01-15T08:00:00.000Z";
  const formattedDate = formatDate(inputDate);
  console.log(formattedDate);

  return (
    <div className={styles.userDetailsContainer}>
      <div className={styles.userDetails}>
        <span className={styles.userDetailsItem}>Organiser Name:</span>
        <span className={styles.userDetailsValue}>{userData.fullName}</span>
      </div>
      <div className={styles.userDetails}>
        <span className={styles.userDetailsItem}>Organiser ID:</span>
        <span className={styles.userDetailsValue}>{userData._id}</span>
      </div>
      <div className={styles.userDetails}>
        <span className={styles.userDetailsItem}>Country:</span>
        <span className={styles.userDetailsValue}>{userData.country}</span>
      </div>
      <div className={styles.userDetails}>
        <span className={styles.userDetailsItem}>Email:</span>
        <span className={styles.userDetailsValue}>{userData.email}</span>
      </div>
      <div
        className={styles.userDetails}
        style={{
          flexBasis: "100%",
        }}
      >
        <span className={styles.userDetailsItem}>Date Joined:</span>
        <span className={styles.userDetailsValue}>
          {formatDate(userData.dateJoined)}
        </span>
      </div>
    </div>
  );
}
