import {
  differenceInMilliseconds,
  formatDistanceToNow,
  parseISO,
} from "date-fns";

function timeAgo(timestamp) {
  const now = new Date();
  timestamp = parseISO(timestamp);
  const timeDifference = differenceInMilliseconds(now, timestamp);
  const timeAgoString = formatDistanceToNow(timestamp, { addSuffix: true });

  return { timeAgoString, timeDifference };
}

export default timeAgo;
