import { useEffect } from "react";
import axios from "axios";
import { useRecoilState, useRecoilValue } from "recoil";

import {
  FetchKeyState,
  UserAccessTokenState,
  UserDataState,
} from "../recoil/user";

const useUserDataFetch = () => {
  const [userData, setUserData] = useRecoilState(UserDataState);
  const fetchKey = useRecoilValue(FetchKeyState);
  const accessToken = useRecoilValue(UserAccessTokenState);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_ORGANISER_API}/organiser/get`,
          {
            headers: {
              token: accessToken,
            },
          }
        );
        setUserData(response.data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchData();
  }, [fetchKey]);

  return userData;
};

export default useUserDataFetch;
