import React from "react";

import styles from "../../styles/general/SmallInput.module.css";

export default function SmallInput({ onClick, containerStyles, ...rest }) {
  const { disabled } = rest;
  return (
    <div
      style={{ ...containerStyles }}
      onClick={onClick}
      className={styles.container}
    >
      <input
        style={{
          cursor: "text",
          pointerEvents: disabled ? "none" : "auto",
          ...rest.inputStyles,
        }}
        {...rest}
      />
      {!rest.hideIcon && (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.1277 12.1714L12.1714 13.1277L14.5656 15.5219C14.8297 15.7859 15.2578 15.7859 15.5219 15.5219C15.786 15.2578 15.786 14.8297 15.5219 14.5656L13.1277 12.1714Z"
            fill="#767BFA"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 7.33334C0 13 2 14.6667 7.33334 14.6667C12.6667 14.6667 14.6667 13 14.6667 7.33334C14.6667 1.66667 12.6667 0 7.33334 0C2 0 0 1.66667 0 7.33334ZM1.33334 7.33334C1.33334 10.0808 1.83388 11.434 2.59218 12.1652C3.36196 12.9075 4.72782 13.3334 7.33334 13.3334C9.93887 13.3334 11.3047 12.9075 12.0745 12.1652C12.8328 11.434 13.3334 10.0808 13.3334 7.33334C13.3334 4.58591 12.8328 3.23269 12.0745 2.50146C11.3047 1.75917 9.93887 1.33334 7.33334 1.33334C4.72782 1.33334 3.36196 1.75917 2.59218 2.50146C1.83388 3.23269 1.33334 4.58591 1.33334 7.33334Z"
            fill="#ABAFD1"
          />
        </svg>
      )}
    </div>
  );
}
