import React from "react";

import LoaderSvg from "../../assets/loader.svg";
import LoaderWhiteSvg from "../../assets/loaderWhite.svg";

import styles from "../../styles/general/Loader.module.css";

export default function CircularLoader({ loading, white = false }) {
  return (
    loading && (
      <div className={`${styles.loader} ${loading ? styles.loading : ""}`}>
        <img
          className={styles.loaderSvg}
          src={white ? LoaderWhiteSvg : LoaderSvg}
          alt=""
        />
      </div>
    )
  );
}
