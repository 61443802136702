import styles from "../../styles/general/Button.module.css";

const Button = ({ disabled, onClick, imgSrc, text, customClass, ...props }) => {
  return (
    <button
      {...props}
      className={`${styles.btn} ${styles[customClass] || ""} ${
        disabled ? styles.disabled : ""
      }`}
      onClick={() => onClick?.()}
    >
      {imgSrc || null}
      <span className={styles.text}>{text}</span>
    </button>
  );
};

export default Button;
