import React from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";

import closeCounter from "../../functions/pos/closeCounter";
import { globalLoadingState } from "../../recoil/general";
import { counterRefetchKeyState, CounterStatus } from "../../recoil/pos";
import { UserAccessTokenState } from "../../recoil/user";
import axiosErrorHandler from "../../utils/axiosErrorHandler";
import Button from "../general/Button";
import { ErrorToast, SuccessToast } from "../general/ToastNotification";

import styles from "../../styles/pos/Pos.module.css";

const DataGrid = ({ data }) => {
  const accessToken = useRecoilValue(UserAccessTokenState);
  const setCounterRefetchKey = useSetRecoilState(counterRefetchKeyState);
  const setGlobalLoading = useSetRecoilState(globalLoadingState);

  const handleCounterClose = async (id) => {
    if (!id) return ErrorToast("Error", "Counter ID not provided");
    try {
      setGlobalLoading(true);
      const resp = await closeCounter(accessToken, id);
      if (!resp.success) {
        return ErrorToast(
          "Error",
          "Some error occurred while closing the counter"
        );
      }
      setCounterRefetchKey((prev) => prev + 1);
      SuccessToast("Counter Closed!");
    } catch (e) {
      const msg = axiosErrorHandler(e);
      return ErrorToast("Error", msg);
    } finally {
      setGlobalLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.gridHeader}>
        <div>Counter ID</div>
        <div>Total Transactions</div>
        <div>Staff In-charge</div>
        <div>Action</div>
      </div>
      {data && data.length > 0 ? (
        <div className={styles.gridContent}>
          {data.map((item, index) => (
            <div key={item?._id} className={styles.gridRow}>
              <div>Counter-{String(index + 1).padStart(3, "0")}</div>
              <div>
                {item.totalTransactionAmount
                  ? `S$${item.totalTransactionAmount}`
                  : "-"}
              </div>
              <div>{item.staffInChargeName ? item.staffInChargeName : "-"}</div>
              <div>
                <Button
                  onClick={() => {
                    if (item.status === CounterStatus.OPEN)
                      handleCounterClose(item._id);
                  }}
                  style={
                    item.status === CounterStatus.CLOSED
                      ? { background: "#646464", fontSize: "13px" }
                      : { fontSize: "13px" }
                  }
                  customClass={"deleteStaffBtn"}
                  text={
                    item.status === CounterStatus.CLOSED
                      ? "Counter Closed"
                      : "Close Counter"
                  }
                />
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className={styles.emptyData}>ADD STAFF TO CONTINUE</div>
      )}
    </div>
  );
};

export default DataGrid;
