import { AxiosError } from "axios";

export default function axiosErrorHandler(error) {
  console.log(error);
  if (error instanceof Error) {
    if (error instanceof AxiosError) {
      console.log(error?.response?.data.error || error?.response?.data);
      return error?.response?.data.error || error?.response?.data;
    } else {
      console.log(error.message);
      return error.message;
    }
  }
}
