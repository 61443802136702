import React from "react";
import { Fade, Modal } from "@mui/material";
import { useRecoilValue } from "recoil";

import { darkModeState } from "../../recoil/settings";

import styles from "../../styles/modals/Modal.module.css";

export const MODAL_STYLE = {
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
};

export default function DeleteScanner({
  open,
  handleClose,
  onConfirm,
  onCancel,
  headerText,
}) {
  const dark = useRecoilValue(darkModeState);
  return (
    <Modal
      onClose={handleClose}
      className={`${dark ? "dark" : ""}`}
      open={open}
      style={MODAL_STYLE}
    >
      <Fade in={open}>
        <div className={styles.deleteModalContainer}>
          <div className={styles.modal}>
            <h2 className={styles.modalTitle}>Deleting {headerText}...</h2>
            <p className={styles.modalContent}>
              Confirm to delete this <span>{headerText}</span>?
            </p>
            <div className={styles.modalActions}>
              <button
                onClick={() => {
                  onConfirm?.();
                  handleClose();
                }}
                className={`${styles.button} ${styles.confirm}`}
              >
                YES, Confirm.
              </button>
              <button
                onClick={() => {
                  onCancel?.();
                  handleClose();
                }}
                className={`${styles.button} ${styles.cancel}`}
              >
                No, Think Again.
              </button>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}
