import { useState } from "react";
import { toast } from "react-toastify";
import { useRecoilValue } from "recoil";

import chain from "../../assets/images/chain.png";
import keyImg from "../../assets/images/key.png";
import getPassword from "../../functions/eventScanner/getPassword";
import { UserAccessTokenState, UserDataState } from "../../recoil/user";
import axiosErrorHandler from "../../utils/axiosErrorHandler";
import translate from "../../utils/temp/translate";
import Button from "../general/Button";
import CircularLoader from "../general/CircularLoader";
import { ErrorToast } from "../general/ToastNotification";

import styles from "../../styles/eventScanner/ScannerLink.module.css";

export default function ScannerLink({
  selectedEvent,
  eventName,
  dateStart,
  thumbImage,
}) {
  const [key, setKey] = useState();
  const [loading, setLoading] = useState(false);
  const accessToken = useRecoilValue(UserAccessTokenState);
  const userData = useRecoilValue(UserDataState);

  const handleGeneratePassword = async () => {
    try {
      if (!loading && selectedEvent) {
        setLoading(true);
        const pass = await getPassword(accessToken, selectedEvent);
        setKey(pass);
      }
    } catch (e) {
      const msg = axiosErrorHandler(e);
      ErrorToast("Error", msg);
    } finally {
      setLoading(false);
    }
  };

  const handleScannerLinkCopy = () => {
    if (!selectedEvent) return ErrorToast("Error", "No selected event");
    if (!userData._id) return ErrorToast("Error", "User not logged in");
    if (!eventName) return ErrorToast("Error", "Event data not available");

    const data = {
      eventData: {
        dateStart,
        eventName,
        thumbImage,
      },
      eventId: selectedEvent,
      organiserId: userData._id,
    };

    const jsonString = JSON.stringify(data);
    const base64Encoded = btoa(jsonString);

    const url = `${process.env.REACT_APP_SCANNER_URL}?state=${base64Encoded}`;
    navigator.clipboard.writeText(url);
    toast.success("Copied to clipboard!");
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.header}>
        <div>{translate("Scanner Link")}</div>
      </div>

      <div className={styles.box}>
        <div className={styles.container}>
          <div className={styles.reqText}>
            Kindly generate an unique password for your event.
          </div>
          <img src={keyImg} alt="key" />
          {key || loading ? (
            <div className={styles.key}>
              {loading ? <CircularLoader loading={true} /> : key}
            </div>
          ) : (
            <Button
              onClick={handleGeneratePassword}
              customClass={"bigPurpleBtn"}
              text={"CLICK TO GENERATE"}
              disabled={loading}
            />
          )}
          <img src={chain} alt="chain" />
          <Button
            onClick={handleScannerLinkCopy}
            customClass={"bigPurpleBtn"}
            text={"COPY SCANNER LINK"}
          />
        </div>
      </div>
    </div>
  );
}
