import React from "react";

import Progressbar from "./Progressbar";

import "react-circular-progressbar/dist/styles.css";
import styles from "../../styles/events/TicketSold.module.css";

export default function TicketSold({ data }) {
  return (
    <div className={styles.ticketSold}>
      <div className={styles.header}>
        TICKETS SOLD
        <span>by section</span>
      </div>

      <div className={styles.box}>
        <div className={styles.progressbarGrid}>
          {data &&
            data.map((item, index) => (
              <Progressbar
                index={index}
                dataArray={item?.ticketTypes}
                key={index}
                value={item?.originalStock - item?.quantity}
                maxValue={item?.originalStock}
                type={item.sectionName}
              />
            ))}
        </div>
      </div>
    </div>
  );
}
