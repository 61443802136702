import React from "react";
import { useRecoilValue } from "recoil";

import {
  addPassInputFieldsState,
  PREVIEW_CARD_DESIGN,
  previewCardDesignState,
} from "../../recoil/staff";

import EmptyCardDesignPreview from "./EmptyCardDesignPreview";
import ReadyCardDesignPreview from "./ReadyCardDesignPreview";

const getCardDesignPreview = (cardDesignState, isNewPass) => {
  const addNewPassModalData = useRecoilValue(addPassInputFieldsState);

  if (
    (isNewPass && !addNewPassModalData.cardDesign) ||
    (!isNewPass && PREVIEW_CARD_DESIGN.EMPTY === cardDesignState)
  ) {
    return <EmptyCardDesignPreview isNewPass={isNewPass} />;
  } else {
    return <ReadyCardDesignPreview />;
  }
};

export default function AddCardDesignPreviewSection({
  isNewPass,
  passId = "",
  area = "",
}) {
  const cardDesignState = useRecoilValue(previewCardDesignState);
  return <>{getCardDesignPreview(cardDesignState, isNewPass, passId, area)}</>;
}
