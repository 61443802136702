import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { Button } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useRecoilValue, useSetRecoilState } from "recoil";

import down from "../../assets/down.svg";
import DownIconDark from "../../assets/downdark.svg";
import { UserDataState } from "../../recoil/user";
import translate from "../../utils/temp/translate";

import Buttons from "./Button";
import UserInfo from "./UserInfo";

import styles from "../../styles/general/Topbar.module.css";

const Topbar = ({
  pagename,
  links,
  userData,
  onClick,
  active,
  dark,
  children,
}) => {
  const setUserData = useSetRecoilState(UserDataState);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const isOwner = localStorage.getItem("owner");

  let history = useNavigate();

  const handleLogout = () => {
    setUserData(undefined);

    history("/login");
  };

  return (
    <div className={`${styles.topbar} px`}>
      <div className={styles.pagename}>{pagename}</div>
      {links?.length > 0 ? (
        <div className={styles.smLink}>
          {links.map((e, i) => (
            <div
              className={`${styles.smLink} ${
                active === i ? styles.activeLink : ""
              }`}
              key={i}
              onClick={() => onClick(i)}
            >
              {e.name}
            </div>
          ))}
        </div>
      ) : null}
      {children}
      <div className={styles.user}>
        {isOwner ? (
          userData?.userImg ? (
            <img
              src={userData?._doc.profileImageUrl}
              className={styles.userimg}
              alt="userimg"
            />
          ) : (
            <span className={styles.circle} />
          )
        ) : null}
        {isOwner && (
          <div className={styles.username}>
            Welcome back, {userData?.fullName}
          </div>
        )}
        {isOwner ? (
          <>
            <Button
              variant="raised"
              style={{
                backgroundColor: "transparent",
                marginLeft: "1rem",
                minWidth: "auto",
                padding: "0",
              }}
              id="basic-button"
              aria-controls="basic-menu"
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              <img src={!dark ? down : DownIconDark} alt="down" />
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              sx={{
                "& .MuiMenu-paper": {
                  backgroundColor: dark ? "#353C53" : "#fff",
                  color: !dark ? "#353C53" : "#fff",
                },
              }}
            >
              {localStorage.getItem("otherStores") ? (
                localStorage
                  .getItem("otherStores")
                  .split(",")
                  .map((k, i) => (
                    <MenuItem
                      key={i}
                      className={styles.logoutbtn}
                      onClick={handleLogout}
                    >
                      {localStorage.getItem("store") == k ? (
                        <RadioButtonCheckedIcon
                          sx={{ fontSize: 15, marginRight: "10px" }}
                        />
                      ) : (
                        <RadioButtonUncheckedIcon
                          sx={{ fontSize: 15, marginRight: "10px" }}
                        />
                      )}
                      {k}
                    </MenuItem>
                  ))
              ) : (
                <></>
              )}

              <hr style={{ marginTop: "10px" }} />

              <MenuItem
                className={styles.logoutbtn}
                onClick={() => {
                  handleClose();
                  history("/logout");
                }}
              >
                <LogoutIcon sx={{ fontSize: 15, marginRight: "10px" }} />
                {translate("logOut")}
              </MenuItem>
            </Menu>
          </>
        ) : (
          <UserInfo userData={userData} />
        )}
      </div>
    </div>
  );
};

export default React.memo(Topbar);
