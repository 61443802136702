import axios from "axios";

export default async function editFairness(access_token, queueId, payload) {
  if (!queueId) throw new Error("Fairness ID not provided");
  const response = await axios.post(
    `${process.env.REACT_APP_ORGANISER_API}/fairness/update/${queueId}`,
    {
      accountCreationDate: payload.accountCreationDate,
      allowedCountries: payload.allowedCountries,
      captchaQuestions: payload.captchaQuestions?.map((q) => ({
        answers: q.answers,
        question: q.question,
      })),
      level: payload.level,
      spotifyArtistId: payload.spotifyArtistId,
      vipWhitelist: payload.vipWhitelist || [],
      waitingRoomTheme: payload.waitingRoomTheme,
    },
    {
      headers: {
        token: access_token,
      },
    }
  );

  if (!response.data) {
    throw new Error("Some error occurred while fetching.");
  }
  if (response.data.error) {
    throw new Error(response.data.error);
  }

  return response.data;
}
