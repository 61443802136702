import React, { useEffect, useState } from "react";
import { Fade, Modal } from "@mui/material";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import createPassDesign from "../../functions/staffPass/createPassDesign";
import editPassDesign from "../../functions/staffPass/editPassDesign";
import { selectedEventIdState } from "../../recoil/events";
import {
  addCardInputFieldsState,
  PREVIEW_CARD_DESIGN,
  previewCardDesignState,
} from "../../recoil/staff";
import { UserAccessTokenState } from "../../recoil/user";
import axiosErrorHandler from "../../utils/axiosErrorHandler";
import Button from "../general/Button";
import CircularLoader from "../general/CircularLoader";
import ColorPickerWithPreview from "../general/ColorPickerWithPreview";
import Input from "../general/Input";
import SelectLabels from "../general/Select";
import { ErrorToast, SuccessToast } from "../general/ToastNotification";

import AddCardDesignPreviewSection from "./AddCardDesignPreviewSection";
import { MODAL_STYLE } from "./DeleteScanner";
import UploadButton from "./UploadButton";

import styles from "../../styles/modals/Modal.module.css";

export const STAFF_PASS_SIZES = {
  "105x70": { label: "105mm x 70mm", value: "105x70" },
  "135x135": { label: "135mm x 135mm", value: "135x135" },
  "135x90": { label: "135mm x 90mm", value: "135x90" },
};

const SIZE_OPTIONS = Object.values(STAFF_PASS_SIZES);

const getLabelFromValue = (value) => {
  const found = STAFF_PASS_SIZES[value];
  return found ? found.label : "";
};

export default function AddNewCardDesignModal({
  open,
  onClose,
  isEdit,
  editPayload,
  refetch,
}) {
  const accessToken = useRecoilValue(UserAccessTokenState);
  const [fields, setFields] = useRecoilState(addCardInputFieldsState);
  const [backImage, setBackImage] = useState();
  const [logo, setLogo] = useState();
  const [loading, setLoading] = useState(false);
  const eventId = useRecoilValue(selectedEventIdState);
  const setPreviewCardDesignState = useSetRecoilState(previewCardDesignState);

  useEffect(() => {
    if (fields.dimension) {
      setPreviewCardDesignState(PREVIEW_CARD_DESIGN.READY);
    } else {
      setPreviewCardDesignState(PREVIEW_CARD_DESIGN.EMPTY);
    }
  }, [fields.dimension]);

  const handleChanges = (e) => {
    setFields({ ...fields, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (isEdit) {
      setFields({
        ...fields,
        ...editPayload,
      });
    }
  }, [isEdit]);

  const handleCreateDesign = async () => {
    if (!loading) {
      try {
        setLoading(true);
        const resp = await createPassDesign(accessToken, {
          ...fields,
          eventId,
        });
        refetch();
        onClose();
        SuccessToast("Success", "Staff pass design created");
      } catch (e) {
        const msg = axiosErrorHandler(e);
        ErrorToast("Error", msg);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleEditDesign = async () => {
    if (!loading) {
      try {
        setLoading(true);
        const resp = await editPassDesign(
          accessToken,
          {
            ...fields,
            eventId,
          },
          editPayload._id
        );
        refetch();
        onClose();
        SuccessToast("Success", "Staff pass design edited");
      } catch (e) {
        const msg = axiosErrorHandler(e);
        ErrorToast("Error", msg);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Modal
      className={styles.modalContainer}
      open={open}
      onClose={onClose}
      disableEnforceFocus
      style={MODAL_STYLE}
    >
      <Fade
        in={open}
        style={{
          border: "none",
          outline: "none",
        }}
      >
        <div
          className={`${styles.modal} ${styles.addNewCardDesignModal}`}
          style={{
            minWidth: "56.875rem",
          }}
        >
          <div className={styles.modalHeader}>
            <div>
              {isEdit ? "Edit " : "Adding New "}
              Pass Design...
            </div>
            <img
              className={styles.cursor}
              onClick={onClose}
              src={require("../../assets/close.svg").default}
            />
          </div>
          <div className={styles.cardDesignGrid}>
            <div className={styles.leftSection}>
              <div className={styles.twoGrid}>
                <div>
                  <label className={styles.label} htmlFor="name">
                    Design Name
                  </label>
                  <Input
                    type={"text"}
                    name={"name"}
                    placeholder={"Design Name"}
                    value={fields.name}
                    updateValue={(e) => handleChanges(e)}
                  />
                </div>
                <div>
                  <label className={styles.label} htmlFor="dimension">
                    Choose Size
                  </label>
                  <SelectLabels
                    placeholderText={"Choose Size"}
                    name={"dimension"}
                    options={SIZE_OPTIONS}
                    value={fields.dimension}
                    updateValue={(e) =>
                      handleChanges({
                        target: { name: "dimension", value: e },
                      })
                    }
                  />
                </div>
              </div>
              <div className={styles.twoGrid}>
                <ColorPickerWithPreview
                  label={"Background Colour"}
                  value={fields.bgColor}
                  onChange={(e) =>
                    handleChanges({
                      target: { name: "bgColor", value: e.target.value },
                    })
                  }
                />
                <ColorPickerWithPreview
                  label={"Accent 1"}
                  value={fields.accent1}
                  onChange={(e) =>
                    handleChanges({
                      target: { name: "accent1", value: e.target.value },
                    })
                  }
                />
              </div>
              <div className={styles.twoGrid}>
                <ColorPickerWithPreview
                  label={"Accent 2"}
                  value={fields.accent2}
                  onChange={(e) =>
                    handleChanges({
                      target: { name: "accent2", value: e.target.value },
                    })
                  }
                />
                <ColorPickerWithPreview
                  label={"Name Colour"}
                  value={fields.nameColor}
                  onChange={(e) =>
                    handleChanges({
                      target: { name: "nameColor", value: e.target.value },
                    })
                  }
                />
              </div>
              <div className={styles.twoGrid}>
                <ColorPickerWithPreview
                  label={"Role Colour"}
                  value={fields.roleColor}
                  onChange={(e) =>
                    handleChanges({
                      target: { name: "roleColor", value: e.target.value },
                    })
                  }
                />
                <div>
                  <label className={styles.label}>Logo</label>
                  <UploadButton
                    onSuccessUpload={(url) => {
                      setLogo(url);
                      setFields({ ...fields, logoUrl: url });
                    }}
                    btnName={"Upload an image"}
                  />
                </div>
              </div>
              <div className={styles.fullWidth}>
                <label className={styles.label}>Back View Image</label>
                <UploadButton
                  onSuccessUpload={(url) => {
                    setBackImage(url);
                    setFields({ ...fields, backImageUrl: url });
                  }}
                  btnName={`Upload an image: ${getLabelFromValue(
                    fields.dimension
                  )}`}
                />
              </div>

              <div className={styles.addButtonContainer}>
                {loading ? (
                  <div className={styles.loaderContainer}>
                    <div className={styles.loader}>
                      <CircularLoader loading={true} />
                    </div>
                  </div>
                ) : (
                  <Button
                    text={isEdit ? "Save" : "Add new"}
                    onClick={isEdit ? handleEditDesign : handleCreateDesign}
                  />
                )}
              </div>
            </div>
            <div className={styles.previewContainer}>
              <label className={styles.label}>Preview</label>
              <div className={styles.preview}>
                <AddCardDesignPreviewSection />
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}
