import React from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import { ArrowRight, CartIcon, IngeniumLogo } from "../../constants/Svgs";
import {
  footerLinksState,
  heroState,
  logoState,
  themeColorsState,
} from "../../recoil/webpage";

import AddImage from "./Modals/AddingImage";
import AddLogo from "./Modals/AddLogo";
import EditAccentColor1 from "./Modals/EditAccentColor1";
import EditAccentColor2 from "./Modals/EditAccentColor2";
import EditAccentColor3 from "./Modals/EditAccentColor3";
import EditFooter from "./Modals/EditFooter";
import EditText1 from "./Modals/EditText1";
import EditText2 from "./Modals/EditText2";

import styles from "../../styles/webpage/Theme1.module.css";

const Banner = () => {
  const logoUrl = useRecoilValue(logoState);

  return (
    <div className={styles.banner}>
      <div className={styles.logoContainer}>
        <AddLogo />
        <img className={styles.logo} src={logoUrl} alt="Banner Logo" />
      </div>
      <div className={styles.textContainer}>
        <a href="#events" className={styles.description}>
          Events
        </a>
        <a href="#merchandise" className={styles.description}>
          Merchandises
        </a>
      </div>
      <div className={styles.cartContainer}>
        <CartIcon />
      </div>
    </div>
  );
};

const Hero = () => {
  const { heroImage, primaryText, secondaryText } = useRecoilValue(heroState);

  return (
    <div className={styles.hero}>
      <div className={styles.heroText}>
        <div className={styles.primaryText}>
          {primaryText}
          <EditText1
            positionStyles={{
              left: "0",
              top: "-24px",
            }}
          />
        </div>
        <div className={styles.secondaryText}>
          {secondaryText}
          <EditText2
            positionStyles={{
              bottom: "-40px",
              left: "0",
            }}
          />
        </div>
      </div>
      <div className={styles.heroImageContainer}>
        <AddImage
          positionStyles={{
            left: "40%",
            top: "50%",
          }}
        />
        <img className={styles.heroImage} src={heroImage} alt="Card Image" />
      </div>
    </div>
  );
};

const SectionDisplay = ({ text, id, black }) => {
  const { accentColor1 } = useRecoilValue(themeColorsState);

  return (
    <div
      className={styles.section}
      id={id}
      style={{
        borderColor: accentColor1,
      }}
    >
      <div
        style={{
          color: black ? "#000" : "#fff",
        }}
        className={styles.sectionTitle}
      >
        {text}
      </div>
    </div>
  );
};

const Event = ({
  name,
  date,
  time,
  location,
  price,
  viewDetailsLink,
  img,
  index,
}) => {
  const { accentColor2, accentColor3 } = useRecoilValue(themeColorsState);

  const isEven = index % 2 === 0;

  const accentColor = !isEven ? accentColor3 : accentColor2;

  return (
    <article className={styles.event}>
      <img
        style={{
          borderColor: accentColor,
        }}
        className={styles.eventImage}
        src="https://placeholder.com/300"
        alt={img}
      />
      <div
        className={styles.eventDescription}
        style={{
          color: accentColor,
        }}
      >
        <div>
          <div className={styles.eventName}>{name}</div>
          <div className={styles.eventDetails}>
            <p>
              {date}, {time}
            </p>
            <p>{location}</p>
            <p className={styles.eventPrice}>{price}</p>
          </div>
        </div>
        <div
          onClick={viewDetailsLink}
          role="button"
          className={styles.viewDetailsBtn}
          style={{
            borderColor: accentColor,
            color: accentColor,
          }}
        >
          View Details
          <ArrowRight fill={accentColor} />
        </div>
      </div>
    </article>
  );
};

const Product = ({ image, name, price, onAddToCart, type }) => {
  return (
    <div className={styles.product}>
      <img className={styles.productImage} src={image} alt={name} />
      <div className={styles.productDetails}>
        <div>
          <p className={styles.productName}>{name}</p>
          <p className={styles.productDescription}>{type}</p>
          <p className={styles.productPrice}>${price}</p>
        </div>
        <a
          onClick={onAddToCart}
          role="button"
          className={styles.viewDetailsBtn}
        >
          Add to Cart
          <ArrowRight />
        </a>
      </div>
    </div>
  );
};

const Footer = () => {
  const logo = useRecoilValue(logoState);
  const socialLinks = useRecoilValue(footerLinksState);
  const [theme, setTheme] = useRecoilState(themeColorsState);

  return (
    <footer
      className={styles.footer}
      style={{
        backgroundColor: theme.footerBackground,
      }}
    >
      <EditFooter
        payload={{
          setValue: (value) =>
            setTheme((prev) => ({
              ...prev,
              footerBackground: value,
            })),
          value: theme.footerBackground,
        }}
        positionStyles={{
          left: "16px",
          top: "16px",
        }}
      />
      <div className={styles.footerContainer}>
        <div className={styles.footerLogoContainer}>
          <div className={styles.footerLogoWrapper}>
            <img className={styles.footerLogo} src={logo} alt="Footer Logo" />
          </div>
        </div>
        <div className={styles.footerSection}>
          <h2 className={styles.footerHeading}>EVENTS</h2>
          <ul className={styles.footerList}>
            <li>Jacky Cheung</li>
            <li>Jacky Chesing</li>
          </ul>
        </div>
        <div className={styles.footerSection}>
          <h2 className={styles.footerHeading}>MERCHANDISE</h2>
          <ul className={styles.footerList}>
            <li>Jacky Cheung T-Shirt</li>
          </ul>
        </div>
        <div className={styles.footerSection}>
          <h2 className={styles.footerHeading}>SOCIAL</h2>
          <a href={socialLinks.facebook} className={styles.socialLink}>
            <span className={styles.socialIcon}>FACEBOOK</span>
          </a>
          <a href={socialLinks.twitter} className={styles.socialLink}>
            <span className={styles.socialIcon}>TWITTER</span>
          </a>
          <a href={socialLinks.instagram} className={styles.socialLink}>
            <span className={styles.socialIcon}>INSTAGRAM</span>
          </a>
        </div>
      </div>
      <p className={styles.copyright}>
        <IngeniumLogo />© 2023 Ingenium. All rights reserved.
      </p>
    </footer>
  );
};

export default function Theme1() {
  const event = {
    date: "July 14, 2024",
    img: "Event Image",
    location: "Singapore Indoor Stadium",
    name: "Event Name",
    price: "Event Price",
    time: "7:00 PM",
    viewDetailsLink: "#",
  };
  const [theme, setTheme] = useRecoilState(themeColorsState);

  return (
    <div className={styles.page}>
      <Banner />
      <Hero />
      <div
        style={{
          position: "relative",
        }}
      >
        <EditAccentColor1
          payload={{
            setValue: (value) =>
              setTheme((prev) => ({
                ...prev,
                accentColor1: value,
              })),
            value: theme.accentColor1,
          }}
          positionStyles={{
            left: "120px",
            top: "12px",
          }}
        />
        <SectionDisplay text="Events" id="events" />
      </div>
      <div className={styles.events}>
        <EditAccentColor2
          payload={{
            setValue: (value) =>
              setTheme((prev) => ({
                ...prev,
                accentColor2: value,
              })),
            value: theme.accentColor2,
          }}
          positionStyles={{
            right: "100px",
            top: "220px",
          }}
        />
        <EditAccentColor3
          payload={{
            setValue: (value) =>
              setTheme((prev) => ({
                ...prev,
                accentColor3: value,
              })),
            value: theme.accentColor3,
          }}
          positionStyles={{
            right: "100px",
            top: "720px",
          }}
        />
        {[...Array(7)].map((_, i) => (
          <Event key={i} {...event} index={i} />
        ))}
      </div>
      <SectionDisplay text="Merchandise" id="merchandise" black />
      <div className={styles.products}>
        <Product
          image="https://placeholder.com/300"
          name="Product Name"
          price="35"
          type={"White"}
          onAddToCart={() => {}}
        />
        <Product
          image="https://placeholder.com/300"
          name="Product Name"
          price="35"
          type={"White"}
          onAddToCart={() => {}}
        />
        <Product
          image="https://placeholder.com/300"
          name="Product Name"
          price="35"
          type={"White"}
          onAddToCart={() => {}}
        />
        <Product
          image="https://placeholder.com/300"
          name="Product Name"
          price="35"
          type={"White"}
          onAddToCart={() => {}}
        />
      </div>
      <Footer />
    </div>
  );
}
