import { useEffect, useState } from "react";
import { format } from "date-fns";
import { useRecoilState, useRecoilValue } from "recoil";

import listCustomers from "../../functions/customers/listCustomers";
import {
  customersDataState,
  customersFetchKeyState,
} from "../../recoil/customers";
import { UserAccessTokenState } from "../../recoil/user";
import axiosErrorHandler from "../../utils/axiosErrorHandler";
import translate from "../../utils/temp/translate";
import { ErrorToast } from "../general/ToastNotification";

import styles from "../../styles/customers/CustomersSummary.module.css";
import gridStyles from "../../styles/general/Grid.module.css";

export default function CustomersSummary() {
  const [totalAmountSort, setTotalAmountSort] = useState(1);
  const [data, setData] = useRecoilState(customersDataState);
  const fetchKey = useRecoilValue(customersFetchKeyState);
  const accessToken = useRecoilValue(UserAccessTokenState);

  const toggleTotalAmountSort = () => setTotalAmountSort((prev) => -prev);

  useEffect(() => {
    (async () => {
      try {
        const resp = await listCustomers(accessToken, totalAmountSort);
        setData(resp);
      } catch (e) {
        const msg = axiosErrorHandler(e);
        ErrorToast("Error", msg);
      }
    })();
  }, [fetchKey]);

  return (
    <div className={`box ${styles.container}`}>
      <div className={gridStyles.headersection}>{translate("summary")}</div>
      <div className={gridStyles.gridContainer}>
        <div className={`${gridStyles.gridHeaders} ${styles.headerColumns}`}>
          <div>Customer Name</div>
          <div>Email</div>
          <div>
            Total Amount Spent{" "}
            <span
              className={`${styles.sortIcon}`}
              onClick={toggleTotalAmountSort}
            >
              <svg
                width="22"
                height="18"
                viewBox="0 0 22 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={`${
                  totalAmountSort === -1 ? styles.reverseSortIcon : ""
                }`}
              >
                <path
                  d="M1.70711 11.2929C1.31658 10.9024 0.683417 10.9024 0.292893 11.2929C-0.0976311 11.6834 -0.0976311 12.3166 0.292893 12.7071L5.29289 17.7071C5.68342 18.0976 6.31658 18.0976 6.70711 17.7071L11.7071 12.7071C12.0976 12.3166 12.0976 11.6834 11.7071 11.2929C11.3166 10.9024 10.6834 10.9024 10.2929 11.2929L7 14.5858L7 1C7 0.447716 6.55228 0 6 0C5.44771 0 5 0.447716 5 1L5 14.5858L1.70711 11.2929Z"
                  fill="#ABAFD1"
                />
                <path
                  d="M11.7071 6.70711C11.3166 7.09763 10.6834 7.09763 10.2929 6.70711C9.90237 6.31658 9.90237 5.68342 10.2929 5.29289L15.2929 0.292893C15.6834 -0.0976311 16.3166 -0.0976311 16.7071 0.292893L21.7071 5.29289C22.0976 5.68342 22.0976 6.31658 21.7071 6.70711C21.3166 7.09763 20.6834 7.09763 20.2929 6.70711L17 3.41421V17C17 17.5523 16.5523 18 16 18C15.4477 18 15 17.5523 15 17V3.41421L11.7071 6.70711Z"
                  fill="#767BFA"
                />
              </svg>
            </span>{" "}
          </div>
          <div>Last Purchased Event</div>
          <div>Last Amount Spent</div>
        </div>
        <div className={styles.itemRows}>
          {data?.map((customer, index) => (
            <div
              key={index}
              className={`${gridStyles.gridItems} ${styles.itemColumns}`}
            >
              <div>{customer?.fullName || ""}</div>
              <div>{customer?.email || ""}</div>
              <div>${customer?.totalAmountSpent || ""} SGD</div>
              <div>
                {customer?.lastPurchasedEvent || ""},{" "}
                {format(new Date(customer?.lastPurchaseDate), "do MMMM, yyyy")}
              </div>
              <div>${(customer?.lastAmountSpent || 0).toFixed(2)} SGD</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
