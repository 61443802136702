import React, { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";

import { selectedFairnessDataState } from "../../recoil/fairness";
import Button from "../general/Button";
import DeleteButton from "../general/DeleteButton";
import AddVipModal from "../modals/addVip/AddVipModal";

import styles from "../../styles/events/TicketSold.module.css";

export default function Vip({ whitelist }) {
  const [data, setData] = useState(whitelist);
  const [open, setOpen] = useState(false);
  const setFairnessData = useSetRecoilState(selectedFairnessDataState);

  useEffect(() => {
    setData(whitelist);
  }, [whitelist]);

  const handleRemove = (id) => {
    const newList = data.filter((_, index) => index !== id);
    setData(newList);
    setFairnessData((prev) => ({
      ...prev,
      vipWhitelist: newList,
    }));
  };

  return (
    <div className={styles.bannerSection}>
      <AddVipModal
        handleClose={() => setOpen(false)}
        open={open}
        setData={setData}
      />
      <div className={styles.header}>VIP Whitelist</div>
      <div className={styles.box}>
        <div className={"flex"}>
          <Button
            text={"Add New"}
            onClick={() => {
              setOpen(true);
            }}
            customClass={"createCaptcha"}
            style={{
              marginRight: "10px",
            }}
          />
          <div className={styles.info}>
            VIP lists allow to purchase 1 hour before the sale, with or without
            max. amount of tickets to purchase.
          </div>
        </div>

        <div>
          <div className={`${styles.vipGrid} ${styles.headers}`}>
            <div>Account</div>
            <div>Max. amount to purchase</div>
            <div>Action</div>
          </div>

          {data?.map((item, id) => (
            <div key={id} className={styles.vipGrid}>
              <div className={styles.vipItem}>
                <img
                  src={require("../../assets/userImg.svg").default}
                  alt="country"
                />
                <div className={styles.userDetails}>
                  {/* <div title={item.userName} className={styles.userName}>
                    {item.userName}
                  </div> */}
                  <div title={item.email} className={styles.userEmail}>
                    {item.email}
                  </div>
                </div>
              </div>
              <div className={styles.maxPurchaseAmount}>
                {item.maxTickets === 0 ? "Unlimited" : item.maxTickets}
              </div>
              <div>
                <DeleteButton text="Remove" onClick={() => handleRemove(id)} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
