import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";

import getRevenueData from "../../functions/events/getRevenueData";
import { selectedEventIdState } from "../../recoil/events";
import { UserAccessTokenState } from "../../recoil/user";
import CircularLoader from "../general/CircularLoader";

import styles from "../../styles/events/TotalPayments.module.css";

export default function TotalPayments() {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const selectedEvent = useRecoilValue(selectedEventIdState);
  const accessToken = useRecoilValue(UserAccessTokenState);

  useEffect(() => {
    (async () => {
      if (!selectedEvent) return;
      if (!loading) {
        try {
          setLoading(true);
          const resp = await getRevenueData(accessToken, selectedEvent);
          setData(resp);
        } catch (err) {
          console.log(err);
        } finally {
          setLoading(false);
        }
      }
    })();
  }, [selectedEvent]);
  return (
    <div className={styles.container}>
      <div className={styles.header}>Total Revenue from primary market</div>
      <div className={styles.box}>
        {loading ? (
          <div className={styles.loaderContainer}>
            <CircularLoader loading={loading} />
          </div>
        ) : (
          <>
            <div className={styles.grid}>
              <div className={styles.gridItem}>
                <img src={require("../../assets/dollar.png")} alt="dollar" />
                <div>
                  <div className={styles.bold}>
                    ${(data?.totalTicketRevenue || 0).toFixed(2)}
                  </div>
                  <div className={styles.subText}>Total Ticket Revenue</div>
                </div>
              </div>
              <div className={styles.gridItem}>
                <img src={require("../../assets/money.png")} alt="money" />
                <div>
                  <div className={styles.bold}>
                    ${(data?.totalPackageRevenue || 0).toFixed(2)}
                  </div>
                  <div className={styles.subText}>Total Package Revenue</div>
                </div>
              </div>
              <div className={styles.gridItem}>
                <img
                  src={require("../../assets/money-bag.png")}
                  alt="money-bag"
                />
                <div>
                  <div className={styles.bold}>
                    ${(data?.totalRevenue || 0).toFixed(2)}
                  </div>
                  <div className={styles.subText}>Total Revenue</div>
                </div>
              </div>
            </div>
            <GridData data={data?.sectionData || []} />
          </>
        )}
      </div>
    </div>
  );
}

const GridData = ({ data = [] }) => {
  return (
    <div className={styles.gridData}>
      <div className={styles.headers}>
        <div>Categories</div>
        <div>Total Tickets For Sale</div>
        <div>Ticket Sold</div>
        <div>Ticket Revenue</div>
        <div>Package Revenue</div>
        <div>Total Revenue</div>
      </div>
      {data?.map((section, index) => (
        <div className={styles.values} key={section.category}>
          <div>{section?.category}</div>
          <div>{section?.totalTickets}</div>
          <div>{section?.totalSold}</div>
          <div>${(section?.ticketRevenue || 0).toFixed(2)}</div>
          <div>${(section?.packageRevenue || 0).toFixed(2)}</div>
          <div>${(section?.totalRevenue || 0).toFixed(2)}</div>
        </div>
      ))}
    </div>
  );
};
