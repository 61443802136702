import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import Button from "../components/general/Button";
import SmallInput from "../components/general/SmallInput";
import Topbar from "../components/general/Topbar";
import SearchModal from "../components/modals/search/SearchModal";
import AddNewPassModal from "../components/staffPass/AddNewPassModal";
import CardDesignSection from "../components/staffPass/CardDesignSection";
import ConfigureSection from "../components/staffPass/ConfigureSection";
import OverviewSection from "../components/staffPass/OverviewSection";
import getEvent from "../functions/getEvent";
import { selectedEventDataState, selectedEventIdState } from "../recoil/events";
import { globalLoadingState } from "../recoil/general";
import { searchModalState } from "../recoil/modal";
import { darkModeState } from "../recoil/settings";
import {
  ACTIVE_CARD_FACE_VIEW,
  activeViewState,
  addNewPassModalState,
  staffPassRefetchKeyState,
  toggleViewModeState,
  viewPassModalDataState,
} from "../recoil/staff";
import { UserAccessTokenState } from "../recoil/user";
import translate from "../utils/temp/translate";

import styles from "../styles/staffPass/StaffPass.module.css";

const TABS = ["Configuration", "Card Design", "Overview"];

const getCurrentView = (activeTab) => {
  switch (activeTab) {
    case 0:
      return <ConfigureSection />;
    case 1:
      return <CardDesignSection />;
    case 2:
      return <OverviewSection />;
    default:
      return <ConfigureSection />;
  }
};

export default function StaffPass({ userData }) {
  const dark = useRecoilValue(darkModeState);
  const setGlobalLoading = useSetRecoilState(globalLoadingState);
  const accessToken = useRecoilValue(UserAccessTokenState);
  const [activeTab, setActiveTab] = useState(0);
  const [search, setSearch] = useState("");
  const [addNewPassModal, setAddNewPassModal] =
    useRecoilState(addNewPassModalState);
  const setSelectedEventData = useSetRecoilState(selectedEventDataState);
  const selectedEventId = useRecoilValue(selectedEventIdState);
  const setViewPassModalDataState = useSetRecoilState(viewPassModalDataState);
  const toggleViewMode = useSetRecoilState(toggleViewModeState);
  const toggleSearchState = useSetRecoilState(searchModalState);
  const setStaffPassesFetchKey = useSetRecoilState(staffPassRefetchKeyState);
  const handleSearchOpenOnInput = () => toggleSearchState(true);
  const setActiveView = useSetRecoilState(activeViewState);

  useEffect(() => {
    (async () => {
      if (!selectedEventId) return;
      try {
        setGlobalLoading(true);
        const resp = await getEvent(accessToken, selectedEventId);
        setSelectedEventData(resp);
      } catch (err) {
        console.log(err);
      } finally {
        setGlobalLoading(false);
      }
    })();
  }, [selectedEventId]);

  return (
    <div className="page">
      <AddNewPassModal
        onClose={() => {
          setAddNewPassModal(false);
          setViewPassModalDataState();
          toggleViewMode(false);
          setActiveView(ACTIVE_CARD_FACE_VIEW.FRONT);
        }}
        refetch={() => setStaffPassesFetchKey((prev) => prev + 1)}
        open={addNewPassModal}
      />
      <SearchModal />
      <Topbar
        dark={dark}
        pagename={translate("Staff Pass System")}
        userData={userData}
      >
        <div className={styles.tabs}>
          {TABS.map((e, i) => (
            <div
              className={`${styles.tab} ${
                i === activeTab ? styles.active : ""
              }`}
              key={i}
              onClick={() => setActiveTab(i)}
            >
              {e}
            </div>
          ))}
        </div>
      </Topbar>
      <div className={`add-btns mb-1 px ${styles.addBtns}`}>
        <div className="mr-14">
          <SmallInput
            disabled={true}
            onClick={handleSearchOpenOnInput}
            dark={dark}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder={translate("Search...")}
          />
        </div>
        <div className="mr-14">
          <Button
            text={"Refresh"}
            imgSrc={
              <img src={require("../assets/refresh.svg").default} alt="plus" />
            }
            dark={dark}
            onClick={() => {
              toast.success("Refreshed successfully!");
            }}
          />
        </div>
        {activeTab === 2 && (
          <div className={styles.overviewButtons}>
            <Button
              text={"Add New Pass"}
              imgSrc={
                <img src={require("../assets/add.svg").default} alt="plus" />
              }
              dark={dark}
              onClick={() => {
                setAddNewPassModal(true);
              }}
            />

            {/* <Button customClass={"downloadAll"} text={"Download all PDF"} /> */}
          </div>
        )}
      </div>
      {getCurrentView(activeTab)}
    </div>
  );
}
