import axios from "axios";

export default async function getRecentActivity(
  access_token,
  eventId,
  count,
  type
) {
  if (!eventId) return;

  const response = await axios.get(
    `
    ${process.env.REACT_APP_ORGANISER_API}/organiser/activity/${eventId}/${count}?type=${type}`,
    {
      headers: {
        token: access_token,
      },
    }
  );

  if (!response.data) {
    throw new Error("Some error occurred while fetching.");
  }
  if (response.data.error) {
    throw new Error(response.data.error);
  }

  return response.data;
}
