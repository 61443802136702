import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import addSvg from "../assets/add.svg";
import Banner from "../components/events/Banner";
import LineGraph from "../components/events/LineGraph";
import RecentActivity from "../components/events/RecentActivity";
import SeatStats from "../components/events/SeatStats";
import TicketSold from "../components/events/TicketSold";
import TotalPayments from "../components/events/TotalPayments";
import Button from "../components/general/Button";
import SelectLabels from "../components/general/Select";
import SmallInput from "../components/general/SmallInput";
import Topbar from "../components/general/Topbar";
import AcknowledgementModal from "../components/modals/addEvent/AcknowledgementModal";
import EventDetailsModal from "../components/modals/addEvent/EventDetailsModal";
import MoreDetailsModal from "../components/modals/addEvent/MoreDetailsModal";
import SearchModal from "../components/modals/search/SearchModal";
import getEvent from "../functions/getEvent";
import useSelectFirstEvent from "../hooks/useSelectFirstEvent";
import {
  eventType,
  EventTypeOptions,
  selectedEventDataState,
  selectedEventIdState,
} from "../recoil/events";
import { globalLoadingState } from "../recoil/general";
import {
  acknowledgementModalState,
  addEventModalState,
  previewModalState,
  searchModalState,
} from "../recoil/modal";
import { darkModeState } from "../recoil/settings";
import { UserAccessTokenState } from "../recoil/user";
import translate from "../utils/temp/translate";

import styles from "../styles/events/Events.module.css";

const Events = ({ userData }) => {
  const [search, setSearch] = useState("");
  const setGlobalLoading = useSetRecoilState(globalLoadingState);
  const [eventTypes, setEventTypes] = useRecoilState(eventType);
  const [selectedSearchData, setSelectedSearchData] = useRecoilState(
    selectedEventDataState
  );
  const accessToken = useRecoilValue(UserAccessTokenState);
  const toggleState = useSetRecoilState(searchModalState);
  const setShowModal = useSetRecoilState(addEventModalState);
  const setShowAcknowledgement = useSetRecoilState(acknowledgementModalState);
  const setPreviewState = useSetRecoilState(previewModalState);
  const dark = useRecoilValue(darkModeState);
  const selectedEvent = useRecoilValue(selectedEventIdState);

  useSelectFirstEvent();

  const handleSearchOpenOnInput = () => toggleState(true);

  useEffect(() => {
    (async () => {
      if (!selectedEvent) return;
      try {
        setGlobalLoading(true);
        const resp = await getEvent(accessToken, selectedEvent);
        setSelectedSearchData(resp);
      } catch (err) {
        console.log(err);
      } finally {
        setGlobalLoading(false);
      }
    })();
  }, [selectedEvent]);

  return (
    <div className="page">
      <EventDetailsModal />
      <MoreDetailsModal data={selectedSearchData?.sales} />
      <AcknowledgementModal />
      <SearchModal />
      <Topbar dark={dark} pagename={translate("events")} userData={userData} />
      <div className={styles.btns}>
        <SmallInput
          disabled={true}
          onClick={handleSearchOpenOnInput}
          dark={dark}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder={translate("Search...")}
        />
        <SelectLabels
          placeholderText={"Events Type"}
          name={"eventTypes"}
          options={EventTypeOptions}
          dark={dark}
          updateValue={(e) => setEventTypes(e)}
          value={eventTypes}
          sm
        />
        <Button
          onClick={() => {
            setShowModal(true);
          }}
          imgSrc={<img src={addSvg} alt="add" />}
          text={`Add New Event`}
        />
      </div>
      <div className={`${styles.container} px`}>
        <div className={styles.left}>
          <Banner
            handleSearchOpenOnInput={handleSearchOpenOnInput}
            data={selectedSearchData}
          />
          <div className={styles.scroll}>
            <div className={styles.twoGrid}>
              <TicketSold data={selectedSearchData?.sections} dark={dark} />
              <SeatStats data={selectedSearchData?.sections} />
            </div>
            <TotalPayments />
          </div>
        </div>
        <div className={`${styles.box} ${styles.right}`}>
          <RecentActivity />
          <LineGraph />
        </div>
      </div>
    </div>
  );
};

export default Events;
