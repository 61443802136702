import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Checkbox } from "@mui/material";
import axios from "axios";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import logo from "../assets/images/logo.png";
import logodark from "../assets/images/logodark.png";
import Input from "../components/general/Input";
import { ErrorToast } from "../components/general/ToastNotification";
import {
  CheckedIcon,
  LoginSVG1,
  LoginSVG2,
  UncheckedIcon,
} from "../constants/Svgs";
import { globalLoadingState } from "../recoil/general";
import { darkModeState } from "../recoil/settings";
import { UserAccessTokenState, UserDataState } from "../recoil/user";
import axiosErrorHandler from "../utils/axiosErrorHandler";
import { isValidEmail } from "../utils/validators";

import inputStyles from "../styles/general/Input.module.css";
import styles from "../styles/Login/Login.module.css";

export default function Login() {
  const dark = useRecoilValue(darkModeState);
  const [otp, setOtp] = useState("");
  const [emailId, setEmailId] = useState("");
  const [isOtpSent, updateIsOtpSent] = useState(false);
  const [checked, setChecked] = useState(false);
  const [isLoading, setLoading] = useRecoilState(globalLoadingState);
  const [userData, setUserData] = useRecoilState(UserDataState);
  const setAccessToken = useSetRecoilState(UserAccessTokenState);
  let history = useNavigate();
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  useEffect(() => {
    if (userData) {
      history("/dash");
    }
  }, [userData, history]);

  const handleGetOtp = async () => {
    if (!isLoading) {
      if (!emailId || !isValidEmail(emailId)) {
        return ErrorToast("Error", "Please enter a valid email");
      }
      if (!checked) {
        return ErrorToast("Error", "Please agree to the Terms and Conditions");
      }
      try {
        setLoading(true);
        const response = await axios.post(
          `
          ${process.env.REACT_APP_ORGANISER_API}/auth/otp`,
          {
            email: emailId,
            scope: "login",
          }
        );
        if (response.status === 200) {
          setOtp(response.data.otp);
          updateIsOtpSent(true);
        } else {
          ErrorToast("Error", "Couldn't send OTP");
        }
      } catch (error) {
        const msg = axiosErrorHandler(error);
        // ErrorToast("Error", msg);
        history("/subscribe");
      } finally {
        setLoading(false);
      }
    }
  };

  const handleVerifyOtp = async () => {
    if (!isLoading) {
      if (!emailId || !isValidEmail(emailId)) {
        return ErrorToast("Error", "Please enter a valid email");
      }
      if (!otp || otp.length !== 6 || isNaN(otp)) {
        return ErrorToast("Error", "Please enter a valid OTP.");
      }
      if (!checked) {
        return ErrorToast("Error", "Please agree to the Terms and Conditions");
      }
      try {
        setLoading(true);
        const response = await axios.post(
          `${process.env.REACT_APP_ORGANISER_API}/auth/login`,
          {
            email: emailId,
            otp,
          }
        );

        if (response.status === 200 && response.data.success) {
          setUserData(response.data);
          setAccessToken(response.data.token);
        } else {
          console.error("Invalid OTP");
        }
      } catch (error) {
        const msg = axiosErrorHandler(error);
        ErrorToast("Error", msg);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className={`flex jc-ce ${styles.loginPage}`}>
      <div className={styles.bg} />
      <LoginSVG1 />
      <LoginSVG2 />
      <div className={styles.container}>
        <img src={dark ? logodark : logo} alt={logo} className="logo" />
        <div
          style={{ paddingTop: !isOtpSent ? "7.6rem" : null }}
          className={styles.inputsWrapper}
        >
          <div className={styles.div}>
            <div className={styles.label}>Email</div>

            <Input
              custom
              name={"email"}
              type={"email"}
              value={emailId}
              updateValue={setEmailId}
              placeholder={"Enter Email"}
              customClass={inputStyles.large}
            />
          </div>

          {isOtpSent && (
            <div className={`${styles.div} ${styles.mt}`}>
              <div className={styles.label}>OTP</div>

              <Input
                custom
                placeholder={"Enter OTP"}
                name={"otp"}
                type={"number"}
                value={otp}
                updateValue={setOtp}
                customClass={inputStyles.large}
              />
              <span className={`${styles.smtext} mt-20`}>
                Didn{"'"}t receive OTP?{" "}
                <span className={styles.bold} onClick={handleGetOtp}>
                  Resend
                </span>
              </span>
            </div>
          )}

          <div className={`flex al-ce jc-ce mt-20`}>
            <div className={styles.checkbox}>
              <Checkbox
                checked={checked}
                onChange={handleChange}
                inputProps={{
                  "aria-label": "controlled",
                }}
                icon={<UncheckedIcon />}
                checkedIcon={<CheckedIcon />}
              />
            </div>

            <span className={styles.smtext}>
              I acknowledge and agree to all the{" "}
              <span
                className={styles.bold}
                onClick={() => {
                  window.open("https://www.ingenium.biz/terms.html", "_blank");
                }}
              >
                terms and conditions.
              </span>
            </span>
          </div>

          <div
            className={`${styles.loginBtn}`}
            onClick={isOtpSent ? handleVerifyOtp : handleGetOtp}
          >
            {isOtpSent ? "Verify" : "Login"}
          </div>
        </div>
      </div>
    </div>
  );
}
