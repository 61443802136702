import React from "react";

import { UserIcon } from "../../constants/Svgs";

import styles from "../../styles/events/RecentActivity.module.css";

export default function RecentActivityItem({ name, activity, timeAgo }) {
  return (
    <div className={styles.recentActivityItem}>
      <div className={styles.userIcon}>
        <UserIcon />
      </div>
      <div>
        <div className={styles.text}>
          <span className={styles.name}>{name}</span> just scanned QR code to{" "}
          <span
            style={{
              color: "#767BFA",
            }}
            className={styles.activity}
          >
            {activity}
          </span>
          .
        </div>
        <div className={styles.timeAgo}>{timeAgo}</div>
      </div>
    </div>
  );
}
