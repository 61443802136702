import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useRecoilValue } from "recoil";

import { Check, HalfSeatSvg, SeatSvg } from "../../constants/Svgs";
import { selectedEventIdState } from "../../recoil/events";
import ConditionalDisplay from "../general/ConditionalDisplay";
import SelectLabels from "../general/Select";

import "react-circular-progressbar/dist/styles.css";
import styles from "../../styles/events/SeatStats.module.css";
import ticketStyles from "../../styles/events/TicketSold.module.css";

const getColor = (value, total) => {
  if (value === total) {
    return "#767BFA";
  }

  if (value / total < 0.5) {
    return "#FF3737";
  }

  return "#FFAA37";
};

function groupDataByCategoryName(data) {
  return _.groupBy(data, "categoryName");
}

export default function SeatStats({ data }) {
  const [selected, setSelected] = useState("");
  const selectedID = useRecoilValue(selectedEventIdState);
  const groupedData = groupDataByCategoryName(data);
  const options = Object.keys(groupedData).map((key) => ({
    label: key,
    value: key,
  }));

  useEffect(() => {
    setSelected("");
  }, [selectedID]);

  return (
    <div className={ticketStyles.ticketSold}>
      <div className={ticketStyles.header}>SEAT STATS</div>
      <div className={styles.box}>
        {data && (
          <>
            <SelectLabels
              placeholderText={"Select ticket type"}
              options={options}
              name={"ticketType"}
              value={selected}
              updateValue={(e) => setSelected(e)}
            />

            <div className={styles.twoGrid}>
              <div className={styles.smHeader}>Section</div>
              <div className={styles.smHeader}>Seats</div>
            </div>
            {groupedData?.[selected]
              ? groupedData?.[selected].map((item, index) => (
                  <QueryAndValue
                    key={index}
                    query={item.sectionName}
                    value={item.originalStock - item.quantity}
                    total={item.originalStock}
                    fill={getColor(
                      item.originalStock - item.quantity,
                      item.originalStock
                    )}
                  />
                ))
              : null}
          </>
        )}
      </div>
    </div>
  );
}

const QueryAndValue = ({ query, value = 0, total = 0, fill }) => {
  const getSeatSvg = (index = 0) => {
    const avg = total / 5;

    if (value < avg * index && value > avg * (index - 1)) {
      return <HalfSeatSvg fill={fill} />;
    }

    if (value < avg * index) {
      return <SeatSvg />;
    }

    return <SeatSvg fill={fill} />;
  };
  return (
    <div className={styles.twoGrid}>
      <div className={styles.row}>
        <div className={styles.query}>{query}</div>
        <div className={styles.value}>
          {value || 0} <span>/ {total || 0}</span>
        </div>
      </div>
      <div className={styles.seatsSvg}>
        {Array(5)
          .fill()
          .map((_, index) => (
            <div key={index} className={styles.seatSvg}>
              {getSeatSvg(index + 1)}
            </div>
          ))}
        <ConditionalDisplay condition={value === total}>
          <Check />
        </ConditionalDisplay>
      </div>
    </div>
  );
};
