const createSubscription = async () => {
  try {
    const requestOptions = {
      headers: {},
      method: "POST",
    };
    const response = await fetch(
      `${process.env.REACT_APP_ORGANISER_API}/subscribe/create-subscription`,
      requestOptions
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    window.location.href = data.url;
  } catch (error) {
    console.error("There was an error!", error);
  }
};

export default createSubscription;
