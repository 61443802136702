import React from "react";

import Button from "../general/Button";
import CircularLoader from "../general/CircularLoader";

import styles from "../../styles/dashboard/Dashboard.module.css";

export default function SubscribePlan({ text = "Change", onClick, loading }) {
  return (
    <div className={styles.planDetails}>
      <img
        className={styles.planDetailsImage}
        src={require("../../assets/plans.webp")}
        alt="plan"
      />
      <div>
        <div className={styles.planDetailsTitle}>Essentials Plan</div>
        <div className={styles.planDetailsSubtitle}>3.4% + 0.5% per ticket</div>
        <div className={styles.planDetailsSubtitle}>$9.99 SGD per month</div>
      </div>
      {loading ? (
        <div className={styles.loaderContainer}>
          <div className={styles.loader}>
            <CircularLoader loading={true} />
          </div>
        </div>
      ) : (
        <Button
          loading={loading}
          disabled={loading}
          onClick={onClick}
          customClass={"planDetailsButton"}
          text={`${text} Plan`}
        />
      )}
    </div>
  );
}
