import { toast } from "react-toastify";

import styles from "../../styles/general/ToastNotification.module.css";

export const SuccessToast = (label, msg) =>
  toast.success(
    <div>
      <div className={styles.label}>{label}</div>
      <div className={styles.msg}>{msg}</div>
    </div>
  );

export const ErrorToast = (label, msg) =>
  toast.error(
    <div>
      <div className={styles.label}>{label}</div>
      <div className={styles.msg}>{msg}</div>
    </div>
  );
