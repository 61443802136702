import React from "react";

import RecentActivity from "./RecentActivity";
import StaffList from "./StaffList";

import styles from "../../styles/staffPass/StaffPass.module.css";

export default function OverviewSection() {
  return (
    <div className="px">
      <div className={styles.overviewContainer}>
        <div className={`box ${styles.staffList}`}>
          <div className={styles.boxHeader}>
            <div>Overview</div>
          </div>
          <StaffList />
        </div>
        <div className={`box box-container ${styles.rightSection}`}>
          <RecentActivity />
        </div>
      </div>
    </div>
  );
}
