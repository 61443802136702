import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useSetRecoilState } from "recoil";

import { selectedFairnessDataState } from "../../recoil/fairness";
import data from "../../utils/countryListData.json";
import DeleteButton from "../general/DeleteButton";
import InputWithSearch from "../general/InputWithSearch";

import styles from "../../styles/events/TicketSold.module.css";

const countryMap = _.keyBy(data, "name");

export default function IPRestriction({ allowedCountries }) {
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [search, setSearch] = useState("");
  const [visible, setVisible] = useState(false);
  const setFairnessData = useSetRecoilState(selectedFairnessDataState);

  useEffect(() => {
    let initCountries = [];
    allowedCountries.forEach((country) => {
      let countryData = countryMap[country];
      if (countryData) initCountries.push(countryData);
    });
    setSelectedCountry(initCountries);
  }, [allowedCountries]);

  return (
    <div className={styles.bannerSection}>
      <div className={styles.header}>IP restriction by country</div>
      <div className={styles.box}>
        <div
          className={styles.twoColumnGrid}
          style={{
            gap: "0.625rem",
          }}
        >
          <InputWithSearch
            containerStyles={{
              width: "100%",
            }}
            placeholder="Search by country"
            onBlur={() => setVisible(false)}
            onFocus={() => setVisible(true)}
            search={search}
            setSearch={setSearch}
          >
            {visible && (
              <div className={styles.countryList}>
                <div className={styles.countryScroll}>
                  {data
                    .filter((item) =>
                      item.name.toLowerCase().includes(search.toLowerCase())
                    )
                    .map((item, id) => (
                      <div
                        className={`${styles.countryItem} ${styles.pointer} ${
                          selectedCountry.includes(item) && styles.selected
                        }`}
                        key={id}
                        onClick={() => {
                          let newCountries = selectedCountry.includes(item)
                            ? selectedCountry.filter((i) => i !== item)
                            : [...selectedCountry, item];
                          setSelectedCountry(newCountries);
                          setFairnessData((prev) => ({
                            ...prev,
                            allowedCountries: newCountries.map((c) => c.name),
                          }));
                        }}
                      >
                        <img src={item.image} alt="country" />
                        <div>{item.name}</div>
                      </div>
                    ))}
                </div>
              </div>
            )}
          </InputWithSearch>
          <div className={styles.info}>
            All country IPs will be blocked by default. Only the chosen country
            IPs will be allow.
          </div>
        </div>

        <div>
          <div className={`${styles.ipGrid} ${styles.headers}`}>
            <div>Allowed Country</div>
            <div>Action</div>
          </div>

          {selectedCountry.map((item, id) => (
            <div key={id} className={styles.ipGrid}>
              <div className={styles.countryItem}>
                <img src={item.image} alt="country" />
                <div>{item.name}</div>
              </div>
              <div>
                <DeleteButton
                  text="Delete"
                  onClick={() => {
                    let newCountries = selectedCountry.filter(
                      (i) => i !== item
                    );
                    setSelectedCountry(newCountries);
                    setFairnessData((prev) => ({
                      ...prev,
                      allowedCountries: newCountries.map((c) => c.name),
                    }));
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
