import React, { useState } from "react";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import { useRecoilState, useRecoilValue } from "recoil";

import Close from "../../../assets/close.svg";
import CloseDark from "../../../assets/closedark.svg";
import { Add } from "../../../constants/Svgs";
import { darkModeState } from "../../../recoil/settings";
import { heroState } from "../../../recoil/webpage";
import Button from "../../general/Button";
import EditThemeHOC from "../EditThemeHOC";

import styles from "../../../styles/modals/Modal.module.css";

export default function EditText1({ positionStyles }) {
  const [open, setOpen] = useState(false);
  const dark = useRecoilValue(darkModeState);
  const [heroData, setHeroText] = useRecoilState(heroState);
  const [text1, setText1] = useState(heroData.primaryText);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    if (text1) {
      setHeroText((prev) => ({
        ...prev,
        primaryText: text1,
      }));
    }

    setOpen(false);
  };

  return (
    <EditThemeHOC>
      <div
        className={styles.buttonContainer}
        style={{
          ...positionStyles,
        }}
      >
        <Button onClick={() => setOpen(true)} text={"Edit Text 1"} />
      </div>

      <Modal
        className={` ${dark ? "dark" : ""}`}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
      >
        <Fade in={open}>
          <div className={styles.webpageModal}>
            <div className={styles.previewHeader}>
              <div>Editing Text 1...</div>
              <img
                onClick={handleClose}
                src={dark ? CloseDark : Close}
                alt=""
              />
            </div>
            <div className={styles.content}>
              <div className={styles.label}>Text 1</div>
              <div className={styles.editContainer}>
                <textarea
                  value={text1}
                  onChange={(e) => setText1(e.target.value)}
                  placeholder="Expert planning, seamless execution."
                  className={styles.editInput}
                />
                <Button text="Save" imgSrc={<Add />} onClick={handleSave} />
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </EditThemeHOC>
  );
}
