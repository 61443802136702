import axios from "axios";

export default async function disableFairnessSystem(access_token, queueId) {
  if (!queueId) return;
  const response = await axios.post(
    `${process.env.REACT_APP_ORGANISER_API}/fairness/disable/${queueId}`,
    {},
    {
      headers: {
        token: access_token,
      },
    }
  );

  if (!response.data) {
    throw new Error("Some error occurred while fetching.");
  }
  if (response.data.error) {
    throw new Error(response.data.error);
  }

  return response.data;
}
