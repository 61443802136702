import React, { useEffect, useState } from "react";
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";

import styles from "../../styles/events/TicketSold.module.css";

const getColor = (index) => {
  return index % 2 === 0 ? "#FC40D3" : "#31A5DA";
};

export default function Progressbar({
  value,
  maxValue,
  color,
  type = "default",
  price = undefined,
  index,
}) {
  return (
    <div className={styles.progressbarContainer}>
      <div className={styles.styledProgressbar}>
        <ProgressProvider valueStart={0} valueEnd={value}>
          {(value) => (
            <CircularProgressbarWithChildren
              value={value}
              counterClockwise
              strokeWidth={4}
              styles={buildStyles({
                pathColor: getColor(index),
                strokeLinecap: "round",
                textColor: color,
                trailColor: "#E4E5ED",
              })}
              maxValue={maxValue}
            >
              <div className={styles.progressbarText}>
                <span>{value || 0}</span>
                <span>/{maxValue || 0}</span>
              </div>
            </CircularProgressbarWithChildren>
          )}
        </ProgressProvider>
      </div>

      <div className={styles.progressbarDetails}>
        <div className={styles.progressbarType}>{type}</div>
        {price !== undefined && (
          <div className={styles.progressbarPrice}>{price}</div>
        )}
      </div>
    </div>
  );
}

const ProgressProvider = ({ valueStart, valueEnd, children }) => {
  const [value, setValue] = useState(valueStart);
  useEffect(() => {
    setValue(valueEnd);
  }, [valueEnd]);

  return children(value);
};
