import { atom } from "recoil";

export const customersDataState = atom({
  default: [],
  key: "customersDataState",
});

export const customersStatState = atom({
  default: {
    totalCustomer: 0,
    totalCustomerSpendings: 0,
    totalCustomerToday: 0,
  },
  key: "customersSummaryState",
});

export const customersFetchKeyState = atom({
  default: 0,
  key: "customersFetchKeyState",
});
