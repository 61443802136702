import React, { useEffect } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import refesh from "../assets/refresh.svg";
import Button from "../components/general/Button";
import {
  ErrorToast,
  SuccessToast,
} from "../components/general/ToastNotification";
import Topbar from "../components/general/Topbar";
import PreviewSection from "../components/webpage/PreviewSection";
import ThemeSection from "../components/webpage/ThemeSection";
import { Coding } from "../constants/Svgs";
import activateTheme from "../functions/webpage/activateTheme";
import deactivateTheme from "../functions/webpage/deactivateTheme";
import getThemeData from "../functions/webpage/getThemeData";
import publishTheme from "../functions/webpage/publishTheme";
import useThemeData from "../hooks/useThemeData";
import { globalLoadingState } from "../recoil/general";
import { darkModeState } from "../recoil/settings";
import { UserAccessTokenState, UserDataState } from "../recoil/user";
import {
  currentThemeDataState,
  currentThemeState,
  isEditEnabled,
  themeFetchKeyState,
} from "../recoil/webpage";
import axiosErrorHandler from "../utils/axiosErrorHandler";

import eventStyles from "../styles/events/Events.module.css";

export default function Webpage() {
  const accessToken = useRecoilValue(UserAccessTokenState);
  const dark = useRecoilValue(darkModeState);
  const userData = useRecoilValue(UserDataState);
  const [editEnabled, setEditEnabled] = useRecoilState(isEditEnabled);
  const setGlobalLoading = useSetRecoilState(globalLoadingState);
  const [themeData, setCurrentThemeData] = useRecoilState(
    currentThemeDataState
  );
  const [themeFetchKey, setThemeFetchKey] = useRecoilState(themeFetchKeyState);
  const activeTheme = useRecoilValue(currentThemeState);
  const { getThemePayload, setThemeData } = useThemeData();

  const refetch = () => setThemeFetchKey((prev) => prev + 1);

  const handleRefresh = () => {
    setEditEnabled(false);
    refetch();
  };

  useEffect(() => {
    (async () => {
      try {
        setGlobalLoading(true);
        const resp = await getThemeData(accessToken);
        setCurrentThemeData(resp);
        setThemeData(resp);
      } catch (err) {
        console.log(err);
      } finally {
        setGlobalLoading(false);
      }
    })();
  }, [themeFetchKey]);

  const isThemeActivated =
    themeData && themeData?.isActive && activeTheme === themeData.theme;

  const handleActivateDeactivate = async () => {
    try {
      setGlobalLoading(true);
      const resp = isThemeActivated
        ? await deactivateTheme(accessToken)
        : await activateTheme(accessToken, activeTheme);
      refetch();
      SuccessToast(
        "Success",
        `Theme ${isThemeActivated ? "Deactivated" : "Activated"}`
      );
    } catch (e) {
      const msg = axiosErrorHandler(e);
      ErrorToast("Error", msg);
    } finally {
      setGlobalLoading(false);
    }
  };

  const handleThemePublish = async () => {
    try {
      setGlobalLoading(true);
      const payload = getThemePayload(activeTheme);
      const resp = await publishTheme(accessToken, payload);
      refetch();
      SuccessToast("Success", `Theme published successfully`);
      setEditEnabled(false);
    } catch (e) {
      const msg = axiosErrorHandler(e);
      ErrorToast("Error", msg);
    } finally {
      setGlobalLoading(false);
    }
  };

  return (
    <div className="page">
      <Topbar dark={dark} pagename={"Webpage"} userData={userData} />
      <div className={eventStyles.btns}>
        <Button
          onClick={handleRefresh}
          imgSrc={<img src={refesh} alt="add" />}
          text={`Refresh`}
        />
        {editEnabled ? (
          <Button
            onClick={handleThemePublish}
            imgSrc={<Coding variant="green" />}
            text={
              <span
                style={{
                  color: "#12C519",
                }}
              >
                Publish
              </span>
            }
          />
        ) : (
          <Button
            onClick={() => {
              setEditEnabled(true);
            }}
            imgSrc={<Coding />}
            text={`Edit Webpage`}
          />
        )}
        <Button
          onClick={handleActivateDeactivate}
          imgSrc={<Coding variant={isThemeActivated ? "red" : "green"} />}
          text={
            <span className={isThemeActivated ? "red" : "green"}>
              {isThemeActivated ? "Deactivate" : "Activate"}
            </span>
          }
        />
      </div>

      <div className="px">
        <ThemeSection />
        <PreviewSection handleLinkSave={handleThemePublish} />
      </div>
    </div>
  );
}
