import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import deleteStaffPass from "../../functions/staffPass/deleteStaffPass";
import listStaffPass from "../../functions/staffPass/listStaffPass";
import { selectedEventIdState } from "../../recoil/events";
import { globalLoadingState } from "../../recoil/general";
import {
  addNewPassModalState,
  staffPassRefetchKeyState,
  toggleViewModeState,
  viewPassModalDataState,
} from "../../recoil/staff";
import { UserAccessTokenState } from "../../recoil/user";
import axiosErrorHandler from "../../utils/axiosErrorHandler";
import { ErrorToast } from "../general/ToastNotification";

import DeletePass from "./DeletePass";

import styles from "../../styles/staffPass/StaffPass.module.css";

const demoStaffData = [
  {
    event: "Black Pink Born Pink SG, Jun, 16 2023 7:00PM",
    id: 1,
    name: "Remus Er",
    role: "Admin",
  },
  {
    event: "Black Pink Born Pink SG, Jun, 16 2023 7:00PM",
    id: 2,
    name: "Remus Er",
    role: "Admin",
  },
];

const StaffList = () => {
  const accessToken = useRecoilValue(UserAccessTokenState);
  const [staffPasses, setStaffPassesData] = useState(demoStaffData);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const setViewModalOpen = useSetRecoilState(addNewPassModalState);
  const toggleViewMode = useSetRecoilState(toggleViewModeState);
  const setGlobalLoading = useSetRecoilState(globalLoadingState);
  const [fetchKey, setFetchKey] = useRecoilState(staffPassRefetchKeyState);
  const selectedEventId = useRecoilValue(selectedEventIdState);
  const setViewPassModalDataState = useSetRecoilState(viewPassModalDataState);

  const handleClose = () => {
    setDeleteModalOpen(false);
    setDeleteId();
  };

  const deletePass = async (id) => {
    try {
      setGlobalLoading(true);
      const resp = await deleteStaffPass(accessToken, id);
      setFetchKey((prev) => prev + 1);
    } catch (e) {
      const msg = axiosErrorHandler(e);
      ErrorToast("Error", msg);
    } finally {
      setGlobalLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      if (selectedEventId) {
        try {
          setGlobalLoading(true);
          const resp = await listStaffPass(accessToken, selectedEventId);
          setStaffPassesData(resp || undefined);
        } catch (e) {
          console.log(e);
          setStaffPassesData(undefined);
        } finally {
          setGlobalLoading(false);
        }
      }
    })();
  }, [selectedEventId, fetchKey]);

  return (
    <div className={styles.staffContainer}>
      <DeletePass
        handleClose={handleClose}
        onConfirm={() => deletePass(deleteId)}
        open={deleteModalOpen}
      />

      <div className={styles.staffContainerHeader}>
        <div>Staff Name</div>
        <div>Role</div>
        <div>Event</div>
        <div>Action</div>
      </div>
      {staffPasses &&
        staffPasses?.map((member) => (
          <div key={member._id} className={styles.staffContainerRow}>
            <div>{member.staffName}</div>
            <div>{member.role}</div>
            <div>{member.eventName}</div>
            <div className={styles.actions}>
              <button
                className={styles.viewButton}
                onClick={() => {
                  setViewPassModalDataState(member);
                  setViewModalOpen(true);
                  toggleViewMode(true);
                }}
              >
                View Pass
              </button>
              <button
                className={styles.deleteButton}
                onClick={() => {
                  setDeleteId(member._id);
                  setDeleteModalOpen(true);
                }}
              >
                Delete Pass
              </button>
            </div>
          </div>
        ))}
    </div>
  );
};

export default StaffList;
