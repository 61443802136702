import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { useFilePicker } from "use-file-picker";

import { FetchKeyState } from "../../recoil/user";

import styles from "../../styles/settings/UploadProfilePicture.module.css";

export default function UploadProfilePicture({ currentImgUrl, setImgBinary }) {
  const [uploading, setUploading] = useState(false);
  const [imgUrl, setImgUrl] = useState("");
  const [isPreview, setIsPreview] = useState(false);
  const fetchKey = useRecoilValue(FetchKeyState);

  useEffect(() => {
    setImgUrl(currentImgUrl);
  }, [currentImgUrl]);

  useEffect(() => {
    setIsPreview(false);
  }, []);

  const [openFileSelectorForProfilePicture, { filesContent }] = useFilePicker({
    accept: "image/*",
    limitFilesConfig: { max: 1 },
    maxFileSize: 50,
    multiple: false,
    onFilesSuccessfulySelected: async ({ plainFiles, filesContent }) => {
      setIsPreview(true);
      setImgUrl(filesContent[0].content);
      setImgBinary(plainFiles[0]);
    },
    readAs: "DataURL",
  });

  return (
    <div className="flex al-ce">
      <div className={styles.imageContainer}>
        {imgUrl && (
          <img
            src={!isPreview ? `${imgUrl}?t=${fetchKey}` : imgUrl}
            alt="profile image"
          />
        )}
      </div>
      <div className={styles.uploadWrapper}>
        <div
          className={styles.uploadText}
          onClick={!uploading ? openFileSelectorForProfilePicture : null}
        >
          {filesContent?.[0]?.name || "Upload Profile Picture"}
        </div>
      </div>
    </div>
  );
}
