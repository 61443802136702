import axios from "axios";

export default async function deleteEventArea(access_token, eventId, areaName) {
  if (!areaName) throw new Error("Provide the area to delete");

  const resp = await axios.delete(
    `${process.env.REACT_APP_ORGANISER_API}/staff-pass/area/delete/${eventId}/${areaName}`,
    {
      headers: {
        token: access_token,
      },
    }
  );

  if (!resp.data) {
    throw new Error("Some error occured");
  }
  if (resp.data.error) {
    throw new Error(resp.data.error);
  }

  return resp.data;
}
