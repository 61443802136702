import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";

import getRecentActivity from "../../functions/getRecentActivity";
import { selectedEventIdState } from "../../recoil/events";
import { UserAccessTokenState } from "../../recoil/user";
import timeAgo from "../../utils/timeAgo";

import RecentActivityItem from "./RecentActivityItem";

import styles from "../../styles/events/RecentActivity.module.css";

export default function RecentActivity() {
  const accessToken = useRecoilValue(UserAccessTokenState);
  const [activityData, setActivityData] = useState();
  const selectedEvent = useRecoilValue(selectedEventIdState);

  useEffect(() => {
    (async () => {
      try {
        const resp = await getRecentActivity(
          accessToken,
          selectedEvent,
          10,
          "ticketPurchased"
        );
        setActivityData(resp);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>RECENT ACTIVITY</div>
      {activityData &&
        activityData.map((activity) => {
          const { timeAgoString } = timeAgo(activity?.payload?.date);
          return (
            <RecentActivityItem
              key={activity._id}
              timeAgo={timeAgoString}
              name={activity?.payload?.buyerName}
              activity={`${activity?.payload?.quantity}  ${activity?.payload?.ticketType}`}
              type={"purchase"}
            />
          );
        })}
    </div>
  );
}
