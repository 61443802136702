import React, { useEffect, useRef } from "react";
import QRCode from "react-qr-code";
import { useReactToPrint } from "react-to-print";
import { useRecoilState, useRecoilValue } from "recoil";

import { PreviewSvg, SquarePreviewSvg } from "../../constants/Svgs";
import {
  ACTIVE_CARD_FACE_VIEW,
  activeViewState,
  addCardInputFieldsState,
  pdfGenerationTriggerState,
  toggleViewModeState,
  viewPassModalDataState,
} from "../../recoil/staff";

import { HorizontalDimension, VerticalDimension } from "./Dimension";

import styles from "../../styles/staffPass/StaffPass.module.css";

const pageStyle = `@page {
  size: 90mm 135mm;
}
`;

export default function CardFrontView() {
  const cardDesignInputs = useRecoilValue(addCardInputFieldsState);
  const isSquare = cardDesignInputs.dimension === "135x135";
  const viewPassModalData = useRecoilValue(viewPassModalDataState);
  const [trigger, setTrigger] = useRecoilState(pdfGenerationTriggerState);
  const activeView = useRecoilValue(activeViewState);
  const isViewMode = useRecoilValue(toggleViewModeState);
  const ref = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => ref.current,
    onAfterPrint: () => setTrigger(false), // Reset trigger after printing
    pageStyle: pageStyle,
  });

  // Effect to listen for the trigger and print
  useEffect(() => {
    if (trigger && handlePrint && activeView === ACTIVE_CARD_FACE_VIEW.FRONT) {
      handlePrint();
    }
  }, [trigger, handlePrint]);

  const passId = viewPassModalData?._id;
  const area = viewPassModalData?.areaName;

  const qrValue = `${passId};${area};STAFFPASS`;

  const getLogo = isViewMode
    ? viewPassModalData?.logoUrl
    : cardDesignInputs.logoUrl;

  return (
    <div
      className={
        styles[
          isSquare
            ? "squareCardDesignPreviewContainer"
            : "cardDesignPreviewContainer"
        ]
      }
    >
      <HorizontalDimension
        isSquare={isSquare}
        dimension={cardDesignInputs.dimension.split("x")[1]}
      />
      {!isSquare && (
        <VerticalDimension
          dimension={cardDesignInputs.dimension.split("x")[0]}
        />
      )}
      <div
        ref={ref}
        style={{
          height: "inherit",
          margin: "auto",
          position: "relative",
          width: "max-content",
        }}
      >
        {!isSquare ? (
          <>
            <PreviewSvg
              accent2={cardDesignInputs.accent2}
              bgColor={cardDesignInputs.bgColor}
            />
          </>
        ) : (
          <>
            <SquarePreviewSvg
              accent2={cardDesignInputs.accent2}
              bgColor={cardDesignInputs.bgColor}
            />
          </>
        )}
        <div className={styles.cardDesignPreviewContent}>
          <div
            style={{
              transform: isSquare ? "translateY(3.5rem)" : null,
            }}
            className={styles.previewLogoContainer}
          >
            <img className={styles.previewLogo} src={getLogo} />
          </div>
          <div
            style={{
              marginTop: isSquare ? "4rem" : "3rem",
            }}
            className={styles.previewNameShortContainer}
          >
            <div
              style={{
                backgroundColor: cardDesignInputs.accent1,
                height: isSquare ? "75px" : "80px",
                width: isSquare ? "75px" : "80px",
              }}
              className={styles.previewNameShort}
            >
              {isViewMode
                ? viewPassModalData?.staffName
                    ?.split(" ")
                    .map((n) => n[0])
                    .join("")
                : "RE"}
            </div>
          </div>
          <div
            style={{
              marginTop: isSquare ? "0" : "1rem",
            }}
            className={styles.previewNameContainer}
          >
            <div
              style={{ color: cardDesignInputs.nameColor }}
              className={styles.previewName}
            >
              {isViewMode ? viewPassModalData?.staffName : "Remus Er"}
            </div>
          </div>
          <div
            style={{
              backgroundColor: cardDesignInputs.roleColor,
              marginTop: isSquare ? "2px" : "1rem",
            }}
            className={styles.adminRole}
          >
            {isViewMode ? viewPassModalData?.role : "Admin"}
          </div>

          <div
            style={{
              borderColor: cardDesignInputs.accent2,
            }}
            className={`${styles.qr} ${
              isSquare ? styles.squareQrCodeContainer : ""
            }`}
          >
            <QRCode
              className={styles.qrCode}
              value={passId ? qrValue : "https://google.com/"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
