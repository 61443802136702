import React, { useState } from "react";

import translate from "../../utils/temp/translate";
import DeleteButton from "../general/DeleteButton";
import SmallInput from "../general/SmallInput";

import styles from "../../styles/events/TicketSold.module.css";

const initialData = [
  {
    name: "Ingenium+ - @ingenium.plus",
  },
];

export default function Instagram() {
  const [data, setData] = useState(initialData);
  const [search, setSearch] = useState("");
  return (
    <div className={`${styles.bannerSection} ${styles.instagram}`}>
      <div className={styles.header}>Instagram followers tracking</div>
      <div className={styles.box}>
        <div>
          <SmallInput
            hideIcon
            inputStyles={{ color: "#000", fontSize: ".8125rem" }}
            containerStyles={{
              marginBottom: "10px",
              minWidth: "300px",
            }}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder={translate("Enter instagram account @")}
          />
          <div className={styles.boldText}>
            Account that must be followed by all buyers in order to join the
            purchase of the event.
          </div>
        </div>

        <div>
          {data.map((item, id) => (
            <div key={id} className={styles.ipGrid}>
              <div className={styles.ipItem}>
                <div className={styles.userDetails}>
                  <div title={item.userName} className={styles.userName}>
                    {item.name}
                  </div>
                </div>
              </div>
              <div>
                <DeleteButton
                  text="Remove"
                  onClick={() => {
                    setData(data.filter((_, index) => index !== id));
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
