import React from "react";

import Button from "../general/Button";

import styles from "../../styles/pos/Pos.module.css";

const IconButton = ({ icon, label, onClick, disabled }) => {
  return (
    <div className={styles.iconButtonWrapper}>
      <img src={icon} className={styles.icon} alt="" />
      <Button
        text={label}
        customClass={"iconButton"}
        onClick={onClick}
        disabled={disabled}
      />
    </div>
  );
};

export default IconButton;
