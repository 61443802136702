import React from "react";

import theme1Cardbg from "../../assets/theme1Bg.webp";
import theme2Cardbg from "../../assets/theme2Bg.webp";
import theme3Cardbg from "../../assets/theme3Bg.webp";
import { ThemeType } from "../../recoil/webpage";

import ThemeCardComponent from "./ThemeCardComponent";

import styles from "../../styles/webpage/Webpage.module.css";

export default function ThemeSection() {
  return (
    <div className={styles.themeSection}>
      <ThemeCardComponent
        theme={ThemeType.THEME1}
        backgroundImg={theme1Cardbg}
      />
      <ThemeCardComponent
        theme={ThemeType.THEME2}
        backgroundImg={theme2Cardbg}
      />
      <ThemeCardComponent
        theme={ThemeType.THEME3}
        backgroundImg={theme3Cardbg}
      />
    </div>
  );
}
