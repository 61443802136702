import React from "react";
import { useRecoilValue } from "recoil";

import { currentThemeState, ThemeType } from "../../recoil/webpage";

import Theme1 from "./Theme1";
import Theme2 from "./Theme2";
import Theme3 from "./Theme3";

import styles from "../../styles/webpage/Webpage.module.css";

export default function WebsitePreview() {
  const currentTheme = useRecoilValue(currentThemeState);
  console.log({ currentTheme });

  function CurrentThemeComponent() {
    switch (currentTheme) {
      case ThemeType.THEME1:
        return <Theme1 />;
      case ThemeType.THEME2:
        return <Theme2 />;
      case ThemeType.THEME3:
        return <Theme3 />;
      default:
        return <Theme1 />;
    }
  }

  return (
    <div className={`box ${styles.websitePreview}`}>
      <div
        style={{
          height: "100%",
          width: "100%",
        }}
      >
        <CurrentThemeComponent />
      </div>
    </div>
  );
}
