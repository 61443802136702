import React, { useContext, useState } from "react";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import Add from "../../../assets/add.svg";
import AddDark from "../../../assets/addark.svg";
import Close from "../../../assets/close.svg";
import CloseDark from "../../../assets/closedark.svg";
import { CurrencyContext } from "../../../context/CurrencyContext";
import addBankAccount from "../../../functions/wallet/addBankAccount";
import {
  addPaymentMethodModalState,
  bankRefetchKeyState,
} from "../../../recoil/modal";
import { darkModeState } from "../../../recoil/settings";
import { UserAccessTokenState } from "../../../recoil/user";
import axiosErrorHandler from "../../../utils/axiosErrorHandler";
import translate from "../../../utils/temp/translate";
import Button from "../../general/Button";
import CircularLoader from "../../general/CircularLoader";
import Input from "../../general/Input";
import Select from "../../general/Select";
import { ErrorToast, SuccessToast } from "../../general/ToastNotification";

import styles from "../../../styles/modals/Modal.module.css";

export default function AddPaymentMethodModal() {
  const dark = useRecoilValue(darkModeState);
  const style = {
    bgcolor: dark ? "#323A52" : "#F6F7FF",
    border: "none",
    borderRadius: "0.9375rem",
    boxShadow: "0rem 0rem 0.625rem rgba(0, 0, 0, 0.15)",
    color: !dark ? "#323A52" : "#F6F7FF",
    left: "50%",
    maxWidth: "48rem",
    outline: "none",
    padding: "1.25rem 0",
    position: "absolute",
    top: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
  };
  const [open, setOpen] = useRecoilState(addPaymentMethodModalState);
  let localCurrency = localStorage.getItem("currency");
  const { rates } = useContext(CurrencyContext);
  const initialValues = {
    accountHolderName: "",
    accountNumber: "",
    bankName: "",
    method: "Bank Transfer",
  };
  const [isDisabled, updateDisabled] = useState(false);
  const [fields, setFields] = useState(initialValues);
  const [loading, toggleLoading] = useState(false);
  const accessToken = useRecoilValue(UserAccessTokenState);
  const setFetchKey = useSetRecoilState(bankRefetchKeyState);

  const refetch = () => {
    setFetchKey((prev) => prev + 1);
  };

  const handleChange = (e) => {
    setFields({
      ...fields,
      [e.target.name]: e.target.value,
    });
  };

  const handleClose = () => {
    setOpen(false);
    setFields(initialValues);
  };

  const handleAddBankAccount = async () => {
    try {
      updateDisabled(true);
      toggleLoading(true);
      await addBankAccount(accessToken, {
        accountHolderName: fields.accountHolderName,
        accountNumber: fields.accountNumber,
        bankName: fields.bankName,
      });
      SuccessToast("Success", "New payout method added");
      handleClose();
      refetch();
    } catch (err) {
      const msg = axiosErrorHandler(err);
      ErrorToast("Error", msg);
    } finally {
      toggleLoading(false);
      updateDisabled(false);
    }
  };

  return (
    <div>
      <Modal
        className={`${styles.modal} ${dark ? "dark" : ""}`}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className={styles.header}>
              <div>{translate("Adding New Payout Method")}...</div>
              <img
                onClick={handleClose}
                src={dark ? CloseDark : Close}
                alt=""
              />
            </div>

            <div className={styles.threeGrid}>
              <div>
                <div className={styles.label}>{translate("Choose Method")}</div>
                <Select
                  dark={dark}
                  name="method"
                  value={fields.method}
                  updateValue={(e) =>
                    setFields({
                      ...fields,
                      method: e,
                    })
                  }
                  options={[
                    {
                      label: "Bank Transfer",
                      value: "Bank Transfer",
                    },
                  ]}
                  placeholderText={translate("Choose method")}
                  disabled
                />
              </div>

              <div className={styles.span2}>
                <div className={styles.label}>
                  Note* Your payout will only be done by bank transfer with a
                  transfer receipt attached to your registered email with
                  Ingenium+.
                </div>
              </div>
            </div>
            <div className={styles.threeGrid}>
              <div>
                <div className={styles.label}>{translate("bankName")}</div>
                <Input
                  dark={dark}
                  name="bankName"
                  type="text"
                  value={fields.bankName}
                  updateValue={handleChange}
                  placeholder={"Enter Name"}
                />
              </div>

              <div className={styles.span2}>
                <div className={styles.label}>{translate("accountNumber")}</div>
                <Input
                  dark={dark}
                  name="accountNumber"
                  type="text"
                  value={fields.accountNumber}
                  updateValue={handleChange}
                  placeholder={"Enter number"}
                />
              </div>
            </div>
            <div className={styles.threeGrid}>
              <div>
                <div className={styles.label}>{translate("accountName")}</div>
                <Input
                  dark={dark}
                  name="accountHolderName"
                  type="text"
                  value={fields.accountHolderName}
                  updateValue={handleChange}
                  placeholder={"Enter account name"}
                />
              </div>

              <div className={`${styles.span2} flex col jc-e`}>
                <div className={`${styles.btns} jus-end mb-0`}>
                  <div>
                    <CircularLoader loading={loading} />
                  </div>

                  <div className={styles.ml10}>
                    <Button
                      disabled={isDisabled}
                      imgSrc={<img src={dark ? AddDark : Add} alt="add" />}
                      onClick={handleAddBankAccount}
                      text={translate("addNew")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
