import React, { useEffect, useState } from "react";

import totalSaleIcon from "../../assets/computer-dynamic-color.webp";
import totalCounterIcon from "../../assets/file-fav-dynamic-color.webp";
import SelectLabels from "../general/Select";

import SummaryCard from "./SummaryCard";
import TxSummaryGrid from "./TxSummaryGrid";

import styles from "../../styles/pos/Pos.module.css";

const transactions = [
  {
    amount: "$100",
    buyer: "John Doe",
    category: "Grocery",
    paymentType: "Cash",
    purchasedTime: "2021-08-12 12:00:00",
    transactionId: "1234",
  },
  {
    amount: "$200",
    buyer: "Jane Doe",
    category: "Electronics",
    paymentType: "Card",
    purchasedTime: "2021-08-12 12:00:00",
    transactionId: "1235",
  },
];

export default function TxSummary({
  filter,
  setFilter,
  totalSale,
  counterList,
  transactions,
}) {
  const counterOptions = counterList?.map((c, i) => ({
    label: `Counter-${String(i + 1).padStart(3, "0")}`,
    value: c?._id,
  }));

  return (
    <div className={`box ${styles.txSummary}`}>
      <div className={styles.headerText}>Transaction Summary</div>
      <div className={styles.summaryCardWrapper}>
        <SummaryCard
          icon={totalCounterIcon}
          value={`S$${totalSale.toFixed(2)}`}
          label="Total Sale On POS"
        />
        <SummaryCard
          icon={totalSaleIcon}
          value={counterList?.length || 0}
          label="Total POS Counter"
        />
      </div>
      <div className={styles.filterWrapper}>
        <SelectLabels
          options={[{ label: "All", value: undefined }, ...counterOptions]}
          sm
          value={filter}
          updateValue={setFilter}
        />
      </div>
      <TxSummaryGrid transactions={transactions} />
    </div>
  );
}
