import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Avatar, IconButton, Popover, Typography } from "@mui/material";
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";

import { FetchKeyState, UserDataState } from "../../recoil/user";

import Button from "./Button";

const UserInfo = ({ userData }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const setUserData = useSetRecoilState(UserDataState);
  const resetUserData = useResetRecoilState(UserDataState);
  const fetchKey = useRecoilValue(FetchKeyState);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "user-info-popover" : undefined;

  let history = useNavigate();

  const handleLogout = () => {
    setUserData(undefined);

    history("/login");
  };

  return (
    <>
      <div style={{ alignItems: "center", display: "flex" }}>
        <Avatar
          sx={{
            backgroundColor: "#777AD4",
          }}
          alt="User Image"
          src={`${userData?.profileImageUrl}?t=${fetchKey}`}
        />
        <div style={{ marginLeft: 10 }}>
          <Typography variant="body1">
            Welcome Back, {userData?.fullName}
          </Typography>
        </div>
        <IconButton
          onClick={handleClick}
          aria-describedby={id}
          style={{ marginLeft: "14px" }}
        >
          <img
            src={require("../../assets/down.svg").default}
            alt="arrow-down"
          />
        </IconButton>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        transformOrigin={{
          horizontal: "center",
          vertical: "top",
        }}
      >
        <div style={{ padding: 0 }}>
          <Button imgSrc={null} text={"Logout"} onClick={handleLogout} />
        </div>
      </Popover>
    </>
  );
};

export default UserInfo;
