import bag from "../../assets/images/bag.png";
import bulb from "../../assets/images/bulb.png";
import target from "../../assets/images/target.png";

import { StatDetails } from "./StatDetails";

import styles from "../../styles/customers/CustomerStats.module.css";

export default function CustomerStats({
  totalAmountSpent = 0,
  newCustomerToday = 0,
  totalCustomers = 0,
}) {
  return (
    <div className={`box ${styles.container} box`}>
      <div className="flex row">
        <StatDetails
          iconSrc={bag}
          header={`$${totalAmountSpent} SGD`}
          subtext={"Total Amount Spent"}
        />
        <div className={styles.vr} />
      </div>
      <div className="flex row">
        <StatDetails
          iconSrc={bulb}
          header={`+${newCustomerToday}`}
          subtext={"New Customer Today"}
        />
        <div className={styles.vr} />
      </div>
      <div className="flex row">
        <StatDetails
          iconSrc={target}
          header={totalCustomers}
          subtext={"Total Customers"}
        />
      </div>
    </div>
  );
}
