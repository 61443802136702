import React from "react";

import useCustomerStats from "../../hooks/useCustomerStats";

import styles from "../../styles/dashboard/Dashboard.module.css";

export default function Customers() {
  const stats = useCustomerStats();

  return (
    <div className={styles.box}>
      <div className={styles.userDetailsItem}>Customers</div>
      <div className={styles.boxContent}>
        <div className={styles.imageContainer}>
          <img
            className={styles.planDetailsImage}
            src={require("../../assets/target.webp")}
            alt="target"
          />
        </div>
      </div>
      <div className={styles.count}>{stats.totalCustomer || 0}</div>
      <div className={styles.increment}>+{stats.totalCustomerToday}</div>
      {/* <div className={styles.lastUpdate}>Last Update: 1 hours ago...</div> */}
    </div>
  );
}
