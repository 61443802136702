import { useRecoilValue } from "recoil";

import { globalLoadingState } from "../../recoil/general";

export default function GlobalLoader() {
  const loading = useRecoilValue(globalLoadingState);

  return loading ? (
    <div className="loader-container">
      <div className="lds-ripple">
        <div />
        <div />
      </div>
    </div>
  ) : null;
}
