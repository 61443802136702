import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import axios from "axios";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import Add from "../../../assets/add.svg";
import AddDark from "../../../assets/addark.svg";
import Close from "../../../assets/close.svg";
import CloseDark from "../../../assets/closedark.svg";
import { CurrencyContext } from "../../../context/CurrencyContext";
import {
  cashOutModalState,
  payoutMethodRefetchKeyState,
} from "../../../recoil/modal";
import { darkModeState } from "../../../recoil/settings";
import { UserAccessTokenState } from "../../../recoil/user";
import translate from "../../../utils/temp/translate";
import Button from "../../general/Button";
import CircularLoader from "../../general/CircularLoader";
import Select from "../../general/Select";
import { ErrorToast, SuccessToast } from "../../general/ToastNotification";

import styles from "../../../styles/modals/Modal.module.css";

export default function CashOutModal({
  bankAccounts = [],
  totalBalance = 0,
  penalty = 0,
  walletSummary = {},
}) {
  const dark = useRecoilValue(darkModeState);
  let localCurrency = localStorage.getItem("currency");
  const { sign, rates } = useContext(CurrencyContext);
  const style = {
    bgcolor: dark ? "#323A52" : "#F6F7FF",
    border: "none",
    borderRadius: "0.9375rem",
    boxShadow: "0rem 0rem 0.625rem rgba(0, 0, 0, 0.15)",
    color: !dark ? "#323A52" : "#F6F7FF",
    left: "50%",
    maxWidth: "48rem",
    outline: "none",
    padding: "1.25rem 0",
    position: "absolute",
    top: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
  };
  const initialValues = {
    cashOutType: "",
    paymentMethod: "",
    show: "",
  };
  const [fields, setFields] = useState(initialValues);
  const [totalCreditAmount, setTotalCreditAmount] = useState(0);
  const [isDisabled, updateDisabled] = useState(false);
  const [loading, toggleLoading] = useState(false);
  const resetKey = useSetRecoilState(payoutMethodRefetchKeyState);
  const accessToken = useRecoilValue(UserAccessTokenState);

  const handleChange = (e) => {
    setFields({
      ...fields,
      [e.target.name]: e.target.value,
    });
  };

  const clearValues = () => {
    setFields(initialValues);
  };

  const [open, setOpen] = useRecoilState(cashOutModalState);
  const [popupOpen, setPopupOpen] = React.useState(false);
  const [popupText, setPopupText] = React.useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setTotalCreditAmount(0);
    setFields(initialValues);
  };

  let token = localStorage.getItem("token");

  const walletSummaryCalc =
    (walletSummary?.availableToCashout || 0) -
    (walletSummary?.totalCashoutFees || 0);

  const refetch = () => {
    resetKey((prev) => prev + 1);
  };

  const cashOutOptions = [
    {
      fees: 2,
      label: translate("1 Working Day Cash Out"),
      value: "1 Working Day",
    },
    {
      fees: 0,
      label: translate("14 Working Day Cash Out"),
      value: "14 Working Day",
    },
  ];

  const bankAccountsList = bankAccounts.map((bankAccount) => {
    return {
      label: `${bankAccount?.bankName} - ${bankAccount?.accountNumber}`,
      value: bankAccount?._id,
    };
  });

  const handleCashOut = async () => {
    const response = await axios.post(
      `${process.env.REACT_APP_ORGANISER_API}/wallet/request-cashout`,
      {
        payoutMethodId: fields.paymentMethod,
      },
      {
        headers: {
          token: accessToken,
        },
      }
    );
  };

  let fees =
    totalCreditAmount *
    (Number(
      cashOutOptions.find((e) => e.value === fields.cashOutType)?.fees ?? 2
    ) /
      100);

  return (
    <div>
      {/* <Popup
        text={popupText}
        show={popupOpen}
        setShow={setPopupOpen}
        dark={dark}
      /> */}
      <Modal
        className={`${styles.modal} ${dark ? "dark" : ""}`}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className={styles.header}>
              <div>{translate("Requesting Cash Out")}...</div>
              <img
                onClick={handleClose}
                src={dark ? CloseDark : Close}
                alt=""
              />
            </div>
            <div className={styles.threeGrid}>
              <div>
                <div className={styles.label}>{translate("Payout Method")}</div>
                <Select
                  dark={dark}
                  name="paymentMethod"
                  value={fields.paymentMethod}
                  updateValue={(e) => {
                    setFields({
                      ...fields,
                      paymentMethod: e,
                    });
                  }}
                  options={bankAccountsList}
                  placeholderText={translate("Choose payout method")}
                  none={false}
                />
              </div>

              <div className={styles.span2}>
                <div className={`${styles.note} purple`}>
                  Note: Payout for tickets will be available 24h after ticket
                  sales (3.5% cashout fee) and the option to cashout 3 days
                  after the event (0.5% cashout fee). We will retain 1% of the
                  ticket sales amount till 3 days after the event for any
                  refunds/charebacks.
                </div>
              </div>
            </div>
            {/* <div className={styles.threeGrid}>
              <div className={styles.span2}>
                <div className={styles.label}>{translate("Cash Out Type")}</div>
                <Select
                  dark={dark}
                  name="cashOutType"
                  value={fields.cashOutType}
                  updateValue={(e) => {
                    setFields({
                      ...fields,
                      cashOutType: e,
                    });
                  }}
                  options={cashOutOptions}
                  placeholderText={translate("Choose cash out type")}
                  none={false}
                />
                <div>
                  <div className={`${styles.label} ${styles.note} red`}>
                    <span>1 working day = 2% Fee of Cash Out Amount</span>
                    <span>14 working days = No Fee</span>
                  </div>
                </div>
              </div>
            </div> */}
            <div className={styles.breakup}>
              <div className={styles.fees}>
                <div>Amount</div>
                <div className="green">
                  {sign[localCurrency]}
                  {(walletSummary?.availableToCashout || 0).toFixed(2)}
                </div>
              </div>
              {/* <div className={styles.fees}>
                <div>
                  {fields.cashOutType || "1 working day"} - Service Fees (
                  {Number(
                    cashOutOptions.find((e) => e.value === fields.cashOutType)
                      ?.fees ?? 2
                  ).toFixed(1)}
                  %)
                </div>
                <div className="red" style={{ fontWeight: "300" }}>
                  -{sign[localCurrency]}
                  {fees.toFixed(2)}
                </div>
              </div> */}

              <div className={styles.fees}>
                <div>Penalty amount</div>
                <div className="red" style={{ fontWeight: "300" }}>
                  {sign[localCurrency]}
                  {(walletSummary?.penalty || 0).toFixed(2)}
                </div>
              </div>

              <div className={styles.fees}>
                <div>New wallet balance</div>
                <div className="purple" style={{ fontWeight: "300" }}>
                  {sign[localCurrency]}
                  {(
                    walletSummary?.totalWalletBalance -
                      walletSummary?.availableToCashout || 0
                  ).toFixed(2)}
                </div>
              </div>

              <div className={`${styles.fees} ${styles.total} mt-10`}>
                <div className={styles.total}>Cash Out Amount</div>
                <div className="green">
                  {sign[localCurrency]}
                  {walletSummaryCalc.toFixed(2)}
                </div>
              </div>
            </div>
            ́
            <div className={`${styles.btns} w-full jus-end mt-10`}>
              <div>
                <CircularLoader loading={loading} />
              </div>

              <div className="ml-10">
                <Button
                  customClass={"conf-cashout"}
                  disabled={isDisabled}
                  imgSrc={<img src={dark ? AddDark : Add} alt="add" />}
                  onClick={async () => {
                    try {
                      toggleLoading(true);
                      updateDisabled(true);
                      if (walletSummaryCalc === 0) {
                        return ErrorToast(
                          "Error",
                          "Not enough balance to cashout"
                        );
                      }
                      if (!fields.paymentMethod) {
                        return ErrorToast(
                          "Error",
                          "Please select a payout method"
                        );
                      }
                      await handleCashOut();
                      SuccessToast(
                        translate("success"),
                        translate("Cash out request sent")
                      );
                      refetch();
                      handleClose();
                      clearValues();
                    } catch (e) {
                      if (
                        e.response &&
                        e.response.data &&
                        e.response.data.error
                      ) {
                        ErrorToast("Error", e.response.data.error);
                      } else {
                        ErrorToast(
                          "Error",
                          "Some error occurred while requesting cash out"
                        );
                      }
                      toggleLoading(false);
                      updateDisabled(false);
                      return;
                    } finally {
                      updateDisabled(false);
                      toggleLoading(false);
                    }
                  }}
                  text={translate("Confirm Cash Out")}
                />
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
