import { atom } from "recoil";

export const ThemeType = {
  THEME1: "theme1",
  THEME2: "theme2",
  THEME3: "theme3",
};

export const currentThemeState = atom({
  default: ThemeType.THEME1,
  key: "currentThemeState",
});

export const themeFetchKeyState = atom({
  default: 0,
  key: "themeFetchKeyState",
});

export const currentThemeDataState = atom({
  default: null,
  key: "currentThemeDataState",
});

export const websiteLinkState = atom({
  default: "",
  key: "websiteLinkState",
});

export const logoState = atom({
  default: "https://placeholder.com/140",
  key: "logoState",
});

export const heroState = atom({
  default: {
    heroImage: "https://placeholder.com/300",
    primaryText: "Expert planning, seamless execution.",
    secondaryText: "We turning dreams into unforgettable experiences.",
  },
  key: "heroState",
});

export const defaultThemeColors = {
  accentColor1: "#FFE973",
  accentColor2: "#fff",
  accentColor3: "#000",
  backgroundColor: "#fff",
  footerBackground: "#E2FAFF",
};

export const themeColorsState = atom({
  default: defaultThemeColors,
  key: "themeColorsState",
});

export const footerLinksState = atom({
  default: {
    facebook: "https://www.facebook.com/",
    instagram: "https://www.instagram.com/",
    twitter: "https://www.twitter.com/",
  },
  key: "footerLinksState",
});

export const isEditEnabled = atom({
  default: false,
  key: "isEditEnabled",
});
