import axios from "axios";

export default async function getFirstEvent(access_token) {
  const response = await axios.get(
    `${process.env.REACT_APP_ORGANISER_API}/event/list?limit=1`,
    {
      headers: {
        token: access_token,
      },
    }
  );

  if (!response.data) {
    throw new Error("Some error occurred while fetching.");
  }
  if (response.data.error) {
    throw new Error(response.data.error);
  }

  return response.data;
}
