import { useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import getFirstEvent from "../functions/getFirstEvent";
import { selectedEventIdState } from "../recoil/events";
import { UserAccessTokenState } from "../recoil/user";

export default function useSelectFirstEvent() {
  const accessToken = useRecoilValue(UserAccessTokenState);
  const [selectedEventId, setSelectedEventId] =
    useRecoilState(selectedEventIdState);
  const pathname = window.location.pathname;

  useEffect(() => {
    (async () => {
      try {
        if (
          (pathname === "/events" ||
            pathname === "/scanner" ||
            pathname === "/fairness") &&
          !selectedEventId
        ) {
          const resp = await getFirstEvent(accessToken);
          if (resp?.events && resp?.events?.length > 0) {
            setSelectedEventId(resp?.events?.[0]?._id);
          }
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);
}
