import React from "react";
import { useRecoilState } from "recoil";

import { ACTIVE_CARD_FACE_VIEW, activeViewState } from "../../recoil/staff";

import styles from "../../styles/staffPass/StaffPass.module.css";

const buttons = [ACTIVE_CARD_FACE_VIEW.FRONT, ACTIVE_CARD_FACE_VIEW.BACK];

export default function SwitchButton() {
  const [switchView, setSwitchView] = useRecoilState(activeViewState);

  return (
    <div className={styles.switchButtonContainer}>
      {buttons.map((button, index) => (
        <div
          key={index}
          className={`${styles.switchButton} ${
            switchView === button ? styles.active : ""
          }`}
          onClick={() => setSwitchView(button)}
        >
          {button} View
        </div>
      ))}
    </div>
  );
}
