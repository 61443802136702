import styles from "../../styles/general/Input.module.css";

const Input = ({
  dark,
  name,
  value,
  updateValue,
  type,
  placeholder,
  customClass,
  custom,
  readOnly,
  prefix,
  suffix,
  ...props
}) => {
  return prefix || suffix ? (
    <div className={`${styles.inputField} flex al-ce`}>
      <span className={styles.prefix}>{prefix}</span>
      <input
        name={name}
        readOnly={readOnly}
        className={`${styles.inputField} ${styles.prefixInput} ${
          customClass ?? ""
        }`}
        type={type}
        placeholder={placeholder.toString()}
        value={value}
        style={type === "date" ? { color: !value ? "#abafd1" : "#000" } : null}
        onChange={(e) => updateValue(custom ? e.target.value : e)}
        {...props}
      />
      <span className={styles.suffix}>{suffix}</span>
    </div>
  ) : (
    <input
      name={name}
      readOnly={readOnly}
      className={`${styles.inputField} ${customClass ?? ""}`}
      type={type}
      placeholder={placeholder.toString()}
      value={value}
      style={
        type === "time" || type === "date"
          ? dark
            ? { color: !value ? "#5a5d74" : "#f6f7ff" }
            : { color: !value ? "#abafd1" : "#000" }
          : null
      }
      onChange={(e) => updateValue(custom ? e.target.value : e)}
      {...props}
    />
  );
};

export default Input;
