import axios from "axios";

export default async function deleteStaffPass(access_token, passId) {
  if (!passId) throw new Error("Provide the Pass Id to delete");

  const resp = await axios.delete(
    `${process.env.REACT_APP_ORGANISER_API}/staff-pass/delete/${passId}`,
    {
      headers: {
        token: access_token,
      },
    }
  );

  if (!resp.data) {
    throw new Error("Some error occured");
  }
  if (resp.data.error) {
    throw new Error(resp.data.error);
  }

  return resp.data;
}
