import React from "react";

import styles from "../../styles/dashboard/Dashboard.module.css";

export default function HeaderGrid() {
  return (
    <div className={styles.gridContainer}>
      <div />
      <div className={styles.header}>Name</div>
      <div className={styles.header}>Venue</div>
      <div className={styles.content}>Date Start</div>
      <div className={styles.content}>Date End</div>
      <div className={styles.content}>Sale Start</div>
      <div className={styles.content}>Sales</div>
      <div />
    </div>
  );
}
