import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import EventDetails from "../components/eventScanner/EventDetails";
import RecentActivity from "../components/eventScanner/RecentActivity";
import ScannedTickets from "../components/eventScanner/ScannedTickets";
import ScannerLink from "../components/eventScanner/ScannerLink";
import TotalScanned from "../components/eventScanner/TotalScanned";
import SmallInput from "../components/general/SmallInput";
import Topbar from "../components/general/Topbar";
import SearchModal from "../components/modals/search/SearchModal";
import getEvent from "../functions/getEvent";
import useSelectFirstEvent from "../hooks/useSelectFirstEvent";
import { selectedEventDataState, selectedEventIdState } from "../recoil/events";
import { globalLoadingState } from "../recoil/general";
import { searchModalState } from "../recoil/modal";
import { darkModeState } from "../recoil/settings";
import { UserAccessTokenState } from "../recoil/user";
import translate from "../utils/temp/translate";

import styles from "../styles/eventScanner/EventScanner.module.css";

export default function EventScanner({ userData }) {
  const [search, setSearch] = useState("");
  const dark = useRecoilValue(darkModeState);
  const setGlobalLoading = useSetRecoilState(globalLoadingState);
  const [selectedSearchData, setSelectedSearchData] = useRecoilState(
    selectedEventDataState
  );
  const toggleState = useSetRecoilState(searchModalState);
  const selectedEvent = useRecoilValue(selectedEventIdState);
  const accessToken = useRecoilValue(UserAccessTokenState);

  useSelectFirstEvent();

  const handleSearchOpenOnInput = () => toggleState(true);

  useEffect(() => {
    (async () => {
      try {
        setGlobalLoading(true);
        const resp = await getEvent(accessToken, selectedEvent);
        setSelectedSearchData(resp);
      } catch (err) {
        console.log(err);
      } finally {
        setGlobalLoading(false);
      }
    })();
  }, [selectedEvent]);

  return (
    <div className="page">
      <SearchModal />
      <Topbar
        dark={dark}
        pagename={translate("Event Scanner")}
        userData={userData}
      />
      <div>
        <div className="add-btns mb-1 px">
          <div className="mr-14">
            <SmallInput
              disabled={true}
              onClick={handleSearchOpenOnInput}
              dark={dark}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder={translate("Search...")}
            />
          </div>
        </div>
        <div className={`${styles.summary} px`}>
          <div className={styles.left}>
            <EventDetails
              handleSearchOpenOnInput={handleSearchOpenOnInput}
              eventName={selectedSearchData?.eventName}
              dateStart={selectedSearchData?.eventStartDate}
              venue={selectedSearchData?.venueName}
              thumbImage={selectedSearchData?.eventBanner}
            />
            <ScannerLink
              selectedEvent={selectedEvent}
              eventName={selectedSearchData?.eventName}
              dateStart={selectedSearchData?.eventStartDate}
              thumbImage={selectedSearchData?.eventBanner}
            />
            <TotalScanned />
            <div className={`box ${styles.span2}`}>
              <ScannedTickets eventId={selectedEvent} />
            </div>
          </div>
          <div className={styles.right}>
            <div className={`box box-container`}>
              <RecentActivity />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
