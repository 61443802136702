import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import deleteEventArea from "../../functions/staffPass/deleteEventArea";
import getEventAreas from "../../functions/staffPass/getEventAreas";
import {
  selectedEventDataState,
  selectedEventIdState,
} from "../../recoil/events";
import { globalLoadingState } from "../../recoil/general";
import {
  configurationDataState,
  eventAreaRefetchKeyState,
} from "../../recoil/staff";
import { UserAccessTokenState } from "../../recoil/user";
import axiosErrorHandler from "../../utils/axiosErrorHandler";
import { ErrorToast } from "../general/ToastNotification";

import AddNewButton from "./AddNewButton";
import Card from "./Card";
import DeleteScanner from "./DeleteScanner";
import NewConfigurationModal from "./NewConfigurationModal";

import styles from "../../styles/staffPass/StaffPass.module.css";

export default function ConfigureSection() {
  const accessToken = useRecoilValue(UserAccessTokenState);
  const [openDelete, setOpenDelete] = useState(false);
  const [data, setData] = useRecoilState(configurationDataState);
  const [selectedIndex, setSelectedIndex] = useState();
  const [openNew, setOpenNew] = useState(false);
  const setGlobalLoading = useSetRecoilState(globalLoadingState);
  const selectedEventId = useRecoilValue(selectedEventIdState);
  const selectedEventData = useRecoilValue(selectedEventDataState);
  const [fetchKey, setFetchKey] = useRecoilState(eventAreaRefetchKeyState);
  const [isEditMode, setIsEditMode] = useState(false);

  const refetch = () => setFetchKey((prev) => prev + 1);

  const deleteCard = async (index) => {
    try {
      setGlobalLoading(true);
      const resp = await deleteEventArea(
        accessToken,
        selectedEventId,
        data?.areaNames[index]
      );
      refetch();
    } catch (e) {
      const msg = axiosErrorHandler(e);
      ErrorToast("Error", msg);
    } finally {
      setGlobalLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      if (selectedEventId) {
        try {
          setGlobalLoading(true);
          const resp = await getEventAreas(accessToken, selectedEventId);
          setData(resp || undefined);
        } catch (e) {
          console.log(e);
          setData(undefined);
        } finally {
          setGlobalLoading(false);
        }
      }
    })();
  }, [selectedEventId, fetchKey]);

  return (
    <div className={`px ${styles.configContainer}`}>
      <NewConfigurationModal
        open={openNew}
        onClose={() => {
          setOpenNew(false);
          setIsEditMode(false);
        }}
        refetch={refetch}
        isEditMode={isEditMode}
        editData={data}
      />
      <DeleteScanner
        headerText={"Scanner"}
        open={openDelete}
        handleClose={() => {
          setOpenDelete(false);
          setSelectedIndex();
        }}
        onConfirm={() => {
          deleteCard(selectedIndex);
          setOpenDelete(false);
        }}
      />
      <AddNewButton
        isEdit={!!data}
        onClick={() => {
          setIsEditMode(!!data);
          setOpenNew(true);
        }}
      />
      {data &&
        data?.areaNames.map((area, i) => (
          <Card
            area={area}
            eventTitle={data?.eventName || ""}
            key={i}
            time={data?.startTime}
            onCopy={() => {}}
            onDelete={() => {
              setSelectedIndex(i);
              setOpenDelete(true);
            }}
          />
        ))}
    </div>
  );
}
