import axios from "axios";

export default async function getHourlySales(
  access_token,
  eventId,
  pastHours,
  time
) {
  if (!eventId) return;
  const response = await axios.post(
    `${process.env.REACT_APP_ORGANISER_API}/event/hourly-sale`,
    {
      eventId,
      hour: Number(pastHours),
      time,
    },
    {
      headers: {
        token: access_token,
      },
    }
  );

  if (!response.data) {
    throw new Error("Some error occurred while fetching.");
  }
  if (response.data.error) {
    throw new Error(response.data.error);
  }

  return response.data;
}
