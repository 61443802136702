import React from "react";
import { useSetRecoilState } from "recoil";

import { selectedFairnessDataState } from "../../recoil/fairness";
import SmallInput from "../general/SmallInput";

import styles from "../../styles/events/TicketSold.module.css";

export default function AccountCreationDate({ timestamp }) {
  const setFairnessData = useSetRecoilState(selectedFairnessDataState);

  return (
    <div
      className={`${styles.banner} ${styles.smHeight} ${styles.accountCreationDate}`}
    >
      <div className={styles.header}>Account Creation Date</div>

      <div className={styles.box}>
        <SmallInput
          containerStyles={{
            minWidth: "12.5rem",
          }}
          type="date"
          hideIcon
          value={new Date(timestamp).toLocaleDateString()}
          onChange={(e) =>
            setFairnessData((prev) => ({
              ...prev,
              accountCreationDate: new Date(e.target.value).getTime(),
            }))
          }
        />

        <div className={styles.boldText}>
          Only allow specific account by creation date to join the purchase of
          the event.
        </div>

        {timestamp && (
          <div className={styles.createDate}>
            Account Created Before : {new Date(timestamp).toLocaleDateString()}
          </div>
        )}
      </div>
    </div>
  );
}
