import React from "react";
import { useRecoilValue } from "recoil";

import { isEditEnabled } from "../../recoil/webpage";

export default function EditThemeHOC({ children }) {
  const editingEnabled = useRecoilValue(isEditEnabled);
  if (!editingEnabled) return null;

  return <>{children}</>;
}
