import { useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";

import lock from "../assets/images/lock.png";
import locker from "../assets/images/locker.png";
import wallet from "../assets/images/wallet.png";
import { StatDetails } from "../components/customers/StatDetails";
import Button from "../components/general/Button";
import { ErrorToast } from "../components/general/ToastNotification";
import Topbar from "../components/general/Topbar";
import AddPaymentMethodModal from "../components/modals/wallet/AddPaymentMethodModal";
import CashOutModal from "../components/modals/wallet/CashOutModal";
import CashoutSummary from "../components/wallet/CashoutSummary";
import FeesSummary from "../components/wallet/FeesSummary";
import PayoutMethods from "../components/wallet/PayoutMethods";
import { Add } from "../constants/Svgs";
import getBankAccounts from "../functions/wallet/getBankAccounts";
import getWalletSummary from "../functions/wallet/getWalletSummary";
import {
  addPaymentMethodModalState,
  bankRefetchKeyState,
  cashOutModalState,
  payoutMethodRefetchKeyState,
} from "../recoil/modal";
import { darkModeState } from "../recoil/settings";
import { UserAccessTokenState } from "../recoil/user";
import axiosErrorHandler from "../utils/axiosErrorHandler";
import translate from "../utils/temp/translate";

import statStyles from "../styles/customers/CustomerStats.module.css";
import styles from "../styles/wallet/Wallet.module.css";

export default function Wallet({ userData }) {
  const dark = useRecoilValue(darkModeState);
  const setAddPaymentMethodModalState = useSetRecoilState(
    addPaymentMethodModalState
  );
  const setCashOutModalState = useSetRecoilState(cashOutModalState);
  const accessToken = useRecoilValue(UserAccessTokenState);
  const [bankAccounts, setBankAccounts] = useState([]);
  const [walletSummary, setWalletSummary] = useState();

  const handleAddPaymentMethodBtnClick = () =>
    setAddPaymentMethodModalState(true);

  const handleCashOutClick = () => {
    if (bankAccounts.length === 0) {
      return ErrorToast("Please add a payout method first.");
    }
    setCashOutModalState(true);
  };
  const fetchKey = useRecoilValue(bankRefetchKeyState);
  const payoutRefetchKey = useRecoilValue(payoutMethodRefetchKeyState);

  useEffect(() => {
    (async () => {
      try {
        const resp = await getBankAccounts(accessToken);
        setBankAccounts(resp);
      } catch (err) {
        const msg = axiosErrorHandler(err);
        ErrorToast("Error", msg);
      }
    })();
  }, [accessToken, fetchKey]);

  useEffect(() => {
    (async () => {
      try {
        const resp = await getWalletSummary(accessToken);
        setWalletSummary(resp);
      } catch (err) {
        const msg = axiosErrorHandler(err);
        ErrorToast("Error", msg);
      }
    })();
  }, [payoutRefetchKey]);

  return (
    <div className="page">
      <Topbar dark={dark} pagename={translate("wallet")} userData={userData} />
      <AddPaymentMethodModal />
      <CashOutModal bankAccounts={bankAccounts} walletSummary={walletSummary} />
      <div className={`${styles.summary} px`}>
        <div className={`${styles.overview} box box-container`}>
          <div className={`${styles.headersection} flex al-ce jc-sp`}>
            <div>{translate("Payout Methods")}</div>
            <Button
              onClick={handleAddPaymentMethodBtnClick}
              imgSrc={<Add dark={dark} />}
              text={translate("addNew")}
            />
          </div>
          <PayoutMethods />
        </div>
        <div className={`${styles.span2} ${styles.topRight}`}>
          <div className="box">
            <div className={`${styles.headersection} flex al-ce jc-sp`}>
              <div className="flex row">
                <div>{translate("Wallet Balance")}</div>
                <span className={`${styles.note}`}>
                  Note: Payout for tickets will be available 24h after ticket
                  sales (3.5% cashout fee) and the option to cashout 3 days
                  after the event (0.5% cashout fee). We will retain 1% of the
                  ticket sales amount till 3 days after the event for any
                  refunds/charebacks.
                </span>
              </div>
              <Button
                text={translate("Cash Out")}
                customClass={"cashout"}
                onClick={handleCashOutClick}
              />
            </div>
            <div className={`${statStyles.container} ${styles.wbpy}`}>
              <div className="flex row">
                <StatDetails
                  iconSrc={locker}
                  header={`S$ ${walletSummary?.totalWalletBalance || 0}`}
                  subtext={"Total Wallet balance"}
                  headerStyle="green"
                />
                <div className={statStyles.vr} />
              </div>
              <div className="flex row">
                <StatDetails
                  iconSrc={wallet}
                  header={`S$ ${walletSummary?.availableToCashout || 0}`}
                  subtext={"Available to cash out"}
                  headerStyle="green"
                />
                <div className={statStyles.vr} />
              </div>
              <div className="flex row">
                <StatDetails
                  iconSrc={lock}
                  header={`S$ ${walletSummary?.penalty || 0}`}
                  subtext={"Penalties"}
                  headerStyle="red"
                />
              </div>
            </div>
          </div>
          <div className="box box-container">
            <div className={`${styles.headersection} flex al-ce jc-sp`}>
              <div>{translate("Cash Out Summary")}</div>
            </div>
            <CashoutSummary />
          </div>
        </div>
        <div className={`${styles.span3} box box-container`}>
          <div className={`${styles.headersection} flex al-ce jc-sp`}>
            <div>{translate("Fees Summary")}</div>
          </div>
          <FeesSummary />
        </div>
      </div>
    </div>
  );
}
