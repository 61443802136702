import React from "react";

export default function Spacer({ height = "1rem" }) {
  return (
    <div
      style={{
        height,
      }}
    />
  );
}
