import React, { useEffect, useState } from "react";
import axios from "axios";
import { format } from "date-fns";
import { useRecoilValue } from "recoil";

import { dateFormat } from "../../constants/dateFormat";
import { payoutMethodRefetchKeyState } from "../../recoil/modal";
import { UserAccessTokenState } from "../../recoil/user";
import axiosErrorHandler from "../../utils/axiosErrorHandler";
import stringShortener from "../../utils/stringShortener";
import { ErrorToast, SuccessToast } from "../general/ToastNotification";

import gridStyles from "../../styles/general/Grid.module.css";
import styles from "../../styles/wallet/CashoutSummary.module.css";

export default function CashoutSummary() {
  const [payoutDetails, setPayoutDetails] = useState([]);
  const accessToken = useRecoilValue(UserAccessTokenState);
  const payoutRefetchKey = useRecoilValue(payoutMethodRefetchKeyState);

  function formatTimestampToCustomFormat(timestamp) {
    const date = new Date(timestamp);
    return format(date, "dd MMM yyyy");
  }

  useEffect(() => {
    (async () => {
      try {
        const resp = await axios.get(
          `
          ${process.env.REACT_APP_ORGANISER_API}/wallet/cashout-requests`,
          {
            headers: {
              token: accessToken,
            },
          }
        );
        setPayoutDetails(resp.data);
      } catch (err) {
        const msg = axiosErrorHandler(err);
        ErrorToast("Error fetching payout methods", msg);
      }
    })();
  }, [accessToken, payoutRefetchKey]);

  const handleCopyTxId = (id) => {
    navigator.clipboard.writeText(id);
    SuccessToast("Transaction ID copied to clipboard");
  };

  return (
    <div className={gridStyles.gridContainer}>
      <div className={`${gridStyles.gridHeaders} ${styles.headerColumns}`}>
        <div>Transaction ID</div>
        <div>Requested Date</div>
        <div>Amount</div>
        <div>Completed Date</div>
        <div>Status</div>
        <div>Receipt Url</div>
      </div>
      <div className={styles.itemRows}>
        {payoutDetails.map((cashoutItem) => (
          <div
            className={`${gridStyles.gridItems} ${styles.itemColumns}`}
            key={cashoutItem._id}
          >
            <div
              className={styles.txId}
              onClick={() => handleCopyTxId(cashoutItem._id)}
            >
              {stringShortener(cashoutItem._id, 5, 7)}
            </div>
            <div>{formatTimestampToCustomFormat(cashoutItem.requestDate)}</div>
            <div>{cashoutItem.totalAmount}</div>
            <div>{cashoutItem.completedDate ?? "to be completed"}</div>
            <div className="green">{cashoutItem.status}</div>
            {cashoutItem.receiptUrl ? (
              <a
                className="link"
                href={cashoutItem.receiptUrl}
                target="_blank"
                rel="noreferrer"
              >
                view receipt
              </a>
            ) : null}
          </div>
        ))}
      </div>
    </div>
  );
}
