import { atom } from "recoil";

export const counterListState = atom({
  default: [],
  key: "counterListState",
});

export const staffListState = atom({
  default: [],
  key: "staffListState",
});

export const staffListModalOpenState = atom({
  default: false,
  key: "staffListModalOpenState",
});

export const staffRefetchKeyState = atom({
  default: 0,
  key: "staffRefetchKeyState",
});

export const counterRefetchKeyState = atom({
  default: 0,
  key: "counterRefetchKeyState",
});

export const CounterStatus = {
  CLOSED: "closed",
  IDLE: "idle",
  OPEN: "open",
};
