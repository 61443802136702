import React, { useRef } from "react";

import styles from "../../styles/general/InputWithSearch.module.css";

const detectOutsideClick = (ref, callback) => {
  const handleClick = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      callback();
    }
  };
  document.addEventListener("mousedown", handleClick);
  return () => {
    document.removeEventListener("mousedown", handleClick);
  };
};

export default function InputWithSearch({
  onBlur,
  onFocus,
  search,
  setSearch,
  placeholder = "Search",
  children,
  containerStyles,
}) {
  const ref = useRef();
  detectOutsideClick(ref, onBlur);

  return (
    <div className={styles.container} style={containerStyles} ref={ref}>
      <div className={styles.search}>
        <img
          className={styles.searchIcon}
          src={require("../../assets/search.svg").default}
        />
        <input
          onFocus={onFocus}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          className={styles.input}
          type="text"
          placeholder={placeholder}
        />
      </div>
      {children}
    </div>
  );
}
