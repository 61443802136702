import React from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import copy from "../../assets/copy.svg";
import { isEditEnabled, websiteLinkState } from "../../recoil/webpage";
import { SuccessToast } from "../general/ToastNotification";

import styles from "../../styles/webpage/Webpage.module.css";

export default function PreviewHeader({ handleLinkSave }) {
  const editingEnabled = useRecoilValue(isEditEnabled);
  const [websiteLink, setWebsiteLink] = useRecoilState(websiteLinkState);

  return (
    <div className={styles.previewHeader}>
      <div className={styles.websiteLink}>
        ingenium.biz/o/
        <input
          disabled={!editingEnabled}
          type="text"
          value={websiteLink}
          onChange={(e) => setWebsiteLink(e.target.value)}
          className={styles.input}
          placeholder="enter webpage address"
        />
      </div>
      <div
        title="Copy webpage link"
        onClick={() => {
          navigator.clipboard.writeText(
            `https://ingenium.biz/o/${websiteLink}`
          );
          SuccessToast("Webpage link copied Successfully");
        }}
        className={styles.copyWrapper}
      >
        <img src={copy} />
      </div>
      {editingEnabled && (
        <button className={styles.saveBtn} onClick={handleLinkSave}>
          Save
        </button>
      )}
      <div className={styles.previewText}>Preview</div>
    </div>
  );
}
