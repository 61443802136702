import { useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import getSectionData from "../../functions/eventScanner/getSectionData";
import { selectedEventIdState } from "../../recoil/events";
import { totalScanDataState } from "../../recoil/scanner";
import { UserAccessTokenState } from "../../recoil/user";
import axiosErrorHandler from "../../utils/axiosErrorHandler";
import translate from "../../utils/temp/translate";
import Progressbar from "../events/Progressbar";
import { ErrorToast } from "../general/ToastNotification";

import styles from "../../styles/eventScanner/EventScanner.module.css";
import scannerLinkStyles from "../../styles/eventScanner/ScannerLink.module.css";

export default function TotalScanned() {
  const selectedEvent = useRecoilValue(selectedEventIdState);
  const accessToken = useRecoilValue(UserAccessTokenState);
  const [data, setData] = useRecoilState(totalScanDataState);

  useEffect(() => {
    (async () => {
      try {
        const resp = await getSectionData(accessToken, selectedEvent);
        setData(resp);
      } catch (e) {
        const msg = axiosErrorHandler(e);
        ErrorToast("Error", msg);
      }
    })();
  }, [selectedEvent]);

  return (
    <div className={scannerLinkStyles.mainContainer}>
      <div className={scannerLinkStyles.header}>
        {translate("Total Tickets Scanned")}
        <span>by category</span>
      </div>
      <div className={`${scannerLinkStyles.box} ${scannerLinkStyles}`}>
        <div className={styles.totalScannedGrid}>
          {data &&
            data.map((item, index) => (
              <Progressbar
                key={index}
                index={index}
                maxValue={item?.totalCount || 0}
                value={item?.usedTicketCount || 0}
                type={item?.section}
              />
            ))}
        </div>
      </div>
    </div>
  );
}
