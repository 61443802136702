import React from "react";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import { useRecoilState, useRecoilValue } from "recoil";

import Close from "../../../assets/close.svg";
import CloseDark from "../../../assets/closedark.svg";
import { previewModalState } from "../../../recoil/modal";
import { previewModalImgState } from "../../../recoil/previewModal";
import { darkModeState } from "../../../recoil/settings";
import translate from "../../../utils/temp/translate";

import styles from "../../../styles/modals/Modal.module.css";

export default function PreviewModal() {
  const dark = useRecoilValue(darkModeState);
  const [open, setOpen] = useRecoilState(previewModalState);
  const imgUrl = useRecoilValue(previewModalImgState);

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <Modal
        className={` ${dark ? "dark" : ""}`}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
      >
        <Fade in={open}>
          <div className={styles.previewBox}>
            <div className={styles.previewHeader}>
              <div>{translate(`Preview`)}</div>
              <img
                onClick={handleClose}
                src={dark ? CloseDark : Close}
                alt=""
              />
            </div>

            <div className={styles.previewImg}>
              <img src={imgUrl} alt="" />
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
