import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";

import logodark from "../../assets/images/logodark.png";
import { darkModeState } from "../../recoil/settings";
import translate from "../../utils/temp/translate";

import styles from "../../styles/general/Sidebar.module.css";

export default function Sidebar() {
  let location = useLocation();
  const dark = useRecoilValue(darkModeState);

  let Links = [
    {
      name: "Dashboard",
      svg: (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.221326 8.99248C-0.0147588 10.5601 -0.292667 14.7333 0.735171 19.4303C1.3021 22.0211 2.77266 23.2096 5.87555 23.6054C10 24.1315 14 24.1315 18.2127 23.6054C21.3166 23.2177 22.8394 22.0397 23.3531 19.4303C24.2569 14.8392 23.9842 10.7454 23.8747 9.1023L23.8674 8.99248C23.7934 7.87377 17.7777 3.28332 14.357 0.759246C12.9796 -0.257135 11.1125 -0.252513 9.73942 0.769785C6.34725 3.2954 0.389923 7.87297 0.221326 8.99248ZM21.4194 9.00872C21.6372 9.22633 21.7935 9.39745 21.8981 9.52338C22.0116 11.2838 22.1941 14.9627 21.3909 19.0423C21.1992 20.0165 20.8747 20.4887 20.5033 20.7871C20.0812 21.1262 19.3444 21.4442 17.9653 21.6164C13.9151 22.1222 10.0831 22.1216 6.12807 21.6171C4.75547 21.442 4.03091 21.1223 3.61281 20.7831C3.23681 20.478 2.90581 19.9928 2.68874 19.0009C1.78797 14.8846 1.96138 11.1972 2.15652 9.60453C2.27081 9.46564 2.44992 9.27105 2.70496 9.01969C3.27852 8.45441 4.09291 7.74149 5.04459 6.95432C6.93905 5.38734 9.24385 3.63575 10.9321 2.37876C11.6001 1.88142 12.5021 1.87961 13.1712 2.37334C14.8733 3.62932 17.1974 5.38214 19.0952 6.94967C20.0492 7.73762 20.8582 8.44824 21.4194 9.00872Z"
            className={styles.activeWhite}
            fill={`${!dark ? "#ABAFD1" : "#5A5D74"}`}
          />
          <path
            d="M9 21.896C8.33504 21.852 7.66893 21.7935 7 21.7204V16.114C7 14.4535 8.34315 13.1074 10 13.1074H14C15.6568 13.1074 17 14.4535 17 16.114L17 21.7278C16.3285 21.7989 15.6624 21.8558 15 21.8985L15 16.114C15 15.5605 14.5523 15.1118 14 15.1118L10 15.1118C9.44772 15.1118 9 15.5605 9 16.114V21.896Z"
            fill="#767BFA"
          />
        </svg>
      ),
      to: "/dash",
    },
    {
      name: translate("Events"),
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="26"
          viewBox="0 0 24 26"
          fill="none"
        >
          <path
            d="M6 15C5.44772 15 5 15.4477 5 16C5 16.5523 5.44772 17 6 17H9C9.55228 17 10 16.5523 10 16C10 15.4477 9.55228 15 9 15H6Z"
            fill="#767BFA"
          />
          <path
            d="M6 19C5.44772 19 5 19.4477 5 20C5 20.5523 5.44772 21 6 21H9C9.55228 21 10 20.5523 10 20C10 19.4477 9.55228 19 9 19H6Z"
            fill="#767BFA"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 26C2.118 26 0 23.882 0 14C0 4.118 2.118 2 12 2C21.882 2 24 4.118 24 14C24 23.882 21.882 26 12 26ZM2.43771 19.6101C2.13254 18.2369 2 16.4249 2 14C2 13.281 2.01165 12.6158 2.03601 12H21.964C21.9883 12.6158 22 13.281 22 14C22 15.1262 21.9714 16.1201 21.9102 16.9994C21.7431 16.9972 21.5637 16.9893 21.3562 16.9802C21.2167 16.9741 21.0644 16.9675 20.8947 16.9616C20.4556 16.9464 19.9501 16.9397 19.4294 16.9825C18.4029 17.0669 17.1843 17.354 16.25 18.2912C15.3186 19.2255 15.0391 20.4085 14.9639 21.4248C14.9261 21.9359 14.9378 22.4343 14.9564 22.8711C14.9624 23.0126 14.9688 23.1436 14.9748 23.2664C14.9866 23.5066 14.9968 23.7153 14.9994 23.9102C14.1202 23.9714 13.1262 24 12 24C9.57515 24 7.76307 23.8675 6.38993 23.5623C5.03949 23.2622 4.23924 22.8223 3.70846 22.2915C3.17768 21.7608 2.73783 20.9605 2.43771 19.6101ZM16.9945 23.6826C17.209 23.6459 17.414 23.6059 17.6101 23.5623C18.9605 23.2622 19.7608 22.8223 20.2915 22.2915C20.8223 21.7608 21.2622 20.9605 21.5623 19.6101C21.6059 19.414 21.6459 19.2089 21.6826 18.9944C21.5292 18.9898 21.3673 18.9828 21.2072 18.9758C21.0771 18.9702 20.948 18.9646 20.8255 18.9604C20.4136 18.9461 19.9986 18.9424 19.5933 18.9758C18.7679 19.0437 18.113 19.2552 17.6664 19.7032C17.2168 20.1542 17.0171 20.78 16.9585 21.5724C16.9294 21.9657 16.937 22.3717 16.9546 22.7863C16.9591 22.8924 16.9645 23.0028 16.97 23.1149C16.9792 23.3037 16.9887 23.4972 16.9945 23.6826ZM21.5623 8.38993C21.6691 8.8704 21.7547 9.40461 21.8209 10H2.17906C2.24529 9.40461 2.33093 8.8704 2.43771 8.38993C2.73783 7.03949 3.17768 6.23924 3.70846 5.70846C4.23924 5.17768 5.03949 4.73783 6.38993 4.43771C7.76307 4.13254 9.57515 4 12 4C14.4249 4 16.2369 4.13254 17.6101 4.43771C18.9605 4.73783 19.7608 5.17768 20.2915 5.70846C20.8223 6.23924 21.2622 7.03949 21.5623 8.38993Z"
            className={styles.activeWhite}
            fill={`${!dark ? "#ABAFD1" : "#5A5D74"}`}
          />
          <path
            d="M8 0C7.44772 0 7 0.447715 7 1V5C7 5.55228 7.44772 6 8 6C8.55228 6 9 5.55228 9 5V1C9 0.447715 8.55228 0 8 0Z"
            fill="#767BFA"
          />
          <path
            d="M16 0C15.4477 0 15 0.447715 15 1V5C15 5.55228 15.4477 6 16 6C16.5523 6 17 5.55228 17 5V1C17 0.447715 16.5523 0 16 0Z"
            fill="#767BFA"
          />
        </svg>
      ),
      to: "/events",
    },
    {
      name: translate("Fairness"),
      svg: (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.66399 7.31111C1.27346 6.92059 1.27346 6.28742 1.66399 5.8969C2.05451 5.50637 2.68768 5.50637 3.0782 5.8969L8.13733 10.956C8.59648 9.25316 10.1518 8 11.9997 8H22.9997V10H15.4646C15.8049 10.5883 15.9997 11.2714 15.9997 12C15.9997 13.1749 15.4932 14.2314 14.6867 14.9632L7.30522 22.3447C6.9147 22.7352 6.28153 22.7352 5.89101 22.3447C5.50048 21.9541 5.50048 21.321 5.89101 20.9304L10.9584 15.8631C10.1932 15.6573 9.51865 15.2303 9.00765 14.6548L1.66399 7.31111ZM10.7097 13.5284C11.058 13.8226 11.5081 14 11.9997 14C12.5023 14 12.9616 13.8146 13.3129 13.5085L13.5082 13.3132C13.8143 12.9619 13.9997 12.5026 13.9997 12C13.9997 10.8954 13.1043 10 11.9997 10C10.8952 10 9.99973 10.8954 9.99973 12C9.99973 12.4916 10.1771 12.9418 10.4713 13.29L10.7097 13.5284Z"
            fill="#767BFA"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 12C0 20.5 3.5 24 12 24C20.5 24 24 20.5 24 12C24 3.5 20.5 0 12 0C3.5 0 0 3.5 0 12ZM2 12C2 16.0386 2.84172 18.3883 4.22671 19.7733C5.61171 21.1583 7.96141 22 12 22C16.0386 22 18.3883 21.1583 19.7733 19.7733C21.1583 18.3883 22 16.0386 22 12C22 7.96141 21.1583 5.61171 19.7733 4.22671C18.3883 2.84172 16.0386 2 12 2C7.96141 2 5.61171 2.84172 4.22671 4.22671C2.84172 5.61171 2 7.96141 2 12Z"
            className={styles.activeWhite}
            fill={`${!dark ? "#ABAFD1" : "#5A5D74"}`}
          />
        </svg>
      ),
      to: "/fairness",
    },
    {
      name: translate("POS"),
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 12C0 21.882 2.118 24 12 24C21.882 24 24 21.882 24 12C24 2.118 21.882 0 12 0C2.118 0 0 2.118 0 12ZM2 12C2 14.4249 2.13254 16.2369 2.43771 17.6101C2.73783 18.9605 3.17768 19.7608 3.70846 20.2915C4.23924 20.8223 5.03949 21.2622 6.38993 21.5623C7.76307 21.8675 9.57515 22 12 22C14.4249 22 16.2369 21.8675 17.6101 21.5623C18.9605 21.2622 19.7608 20.8223 20.2915 20.2915C20.8223 19.7608 21.2622 18.9605 21.5623 17.6101C21.8675 16.2369 22 14.4249 22 12C22 9.57515 21.8675 7.76307 21.5623 6.38993C21.2622 5.03949 20.8223 4.23924 20.2915 3.70846C19.7608 3.17768 18.9605 2.73783 17.6101 2.43771C16.2369 2.13254 14.4249 2 12 2C9.57515 2 7.76307 2.13254 6.38993 2.43771C5.03949 2.73783 4.23924 3.17768 3.70846 3.70846C3.17768 4.23924 2.73783 5.03949 2.43771 6.38993C2.13254 7.76307 2 9.57515 2 12Z"
            fill={`${!dark ? "#ABAFD1" : "#5A5D74"}`}
            className={styles.activeWhite}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 16C5.79086 16 4 14.2091 4 12C4 9.79086 5.79086 8 8 8C10.2091 8 12 9.79086 12 12C12 14.2091 10.2091 16 8 16ZM8 14C6.89542 14 6 13.1046 6 12C6 10.8954 6.89542 10 8 10C9.10458 10 10 10.8954 10 12C10 13.1046 9.10458 14 8 14Z"
            fill="#767BFA"
          />
          <path
            d="M19 9C19.5523 9 20 9.44772 20 10C20 10.5523 19.5523 11 19 11H15C14.4477 11 14 10.5523 14 10C14 9.44772 14.4477 9 15 9H19Z"
            fill="#767BFA"
          />
          <path
            d="M20 14C20 13.4477 19.5523 13 19 13H15C14.4477 13 14 13.4477 14 14C14 14.5523 14.4477 15 15 15H19C19.5523 15 20 14.5523 20 14Z"
            fill="#767BFA"
          />
        </svg>
      ),
      to: "/pos",
    },
    {
      name: translate("Webpage"),
      svg: (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 24C2.11801 24 0 21.882 0 12C0 2.11801 2.11801 0 12 0C21.882 0 24 2.11801 24 12C24 21.882 21.882 24 12 24ZM2.43771 17.6101C2.13254 16.2369 2 14.4249 2 12C2 10.8736 2.02859 9.87939 2.08981 9H5V21.1316C4.43491 20.8906 4.02405 20.6071 3.70847 20.2915C3.17767 19.7607 2.73782 18.9605 2.43771 17.6101ZM7 21.6816C8.28842 21.9023 9.91843 22 12 22C14.4249 22 16.2369 21.8675 17.6101 21.5623C18.9605 21.2622 19.7607 20.8223 20.2915 20.2915C20.8223 19.7607 21.2622 18.9605 21.5623 17.6101C21.8675 16.2369 22 14.4249 22 12C22 10.8736 21.9714 9.87939 21.9102 9H7V21.6816ZM21.5623 6.38992C21.6055 6.58432 21.6452 6.78751 21.6816 7H2.31836C2.35477 6.78751 2.3945 6.58432 2.43771 6.38992C2.73782 5.03949 3.17767 4.23926 3.70847 3.70847C4.23926 3.17767 5.03949 2.73782 6.38992 2.43771C7.76306 2.13254 9.57513 2 12 2C14.4249 2 16.2369 2.13254 17.6101 2.43771C18.9605 2.73782 19.7607 3.17767 20.2915 3.70847C20.8223 4.23926 21.2622 5.03949 21.5623 6.38992Z"
            className={styles.activeWhite}
            fill={`${!dark ? "#ABAFD1" : "#5A5D74"}`}
          />
          <path
            d="M5 5C5 5.55228 5.44772 6 6 6C6.55228 6 7 5.55228 7 5C7 4.44772 6.55228 4 6 4C5.44772 4 5 4.44772 5 5Z"
            fill="#767BFA"
          />
          <path
            d="M8 5C8 5.55228 8.44772 6 9 6C9.55228 6 10 5.55228 10 5C10 4.44772 9.55228 4 9 4C8.44772 4 8 4.44772 8 5Z"
            fill="#767BFA"
          />
          <path
            d="M12 6C11.4477 6 11 5.55228 11 5C11 4.44772 11.4477 4 12 4C12.5523 4 13 4.44772 13 5C13 5.55228 12.5523 6 12 6Z"
            fill="#767BFA"
          />
        </svg>
      ),
      to: "/webpage",
    },
    {
      name: translate("Staff Pass"),
      svg: (
        <svg
          width="26"
          height="24"
          viewBox="0 0 26 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5 6C5 9.31371 7.68629 12 11 12C14.3137 12 17 9.31371 17 6C17 2.68629 14.3137 0 11 0C7.68629 0 5 2.68629 5 6ZM7 6C7 8.20914 8.79086 10 11 10C13.2091 10 15 8.20914 15 6C15 3.79086 13.2091 2 11 2C8.79086 2 7 3.79086 7 6Z"
            fill={`${!dark ? "#ABAFD1" : "#5A5D74"}`}
            className={styles.activeWhite}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 19C0 23.1175 1.9415 24 11 24C20.0585 24 22 23.1175 22 19C22 14.8825 20.0585 14 11 14C1.9415 14 0 14.8825 0 19ZM2 19C2 19.9093 2.11431 20.3794 2.24164 20.6398C2.33196 20.8246 2.47936 21.0182 2.93067 21.2233C3.44734 21.4582 4.28709 21.6691 5.66071 21.8079C7.01516 21.9447 8.75517 22 11 22C13.2448 22 14.9848 21.9447 16.3393 21.8079C17.7129 21.6691 18.5527 21.4582 19.0693 21.2233C19.5206 21.0182 19.668 20.8246 19.7584 20.6398C19.8857 20.3794 20 19.9093 20 19C20 18.0907 19.8857 17.6206 19.7584 17.3602C19.668 17.1754 19.5206 16.9818 19.0693 16.7767C18.5527 16.5418 17.7129 16.3309 16.3393 16.1921C14.9848 16.0553 13.2448 16 11 16C8.75517 16 7.01516 16.0553 5.66071 16.1921C4.28709 16.3309 3.44734 16.5418 2.93067 16.7767C2.47936 16.9818 2.33196 17.1754 2.24164 17.3602C2.11431 17.6206 2 18.0907 2 19Z"
            fill={`${!dark ? "#ABAFD1" : "#5A5D74"}`}
            className={styles.activeWhite}
          />
          <path
            d="M23 7C23 6.44772 22.5523 6 22 6C21.4477 6 21 6.44772 21 7V9H19C18.4477 9 18 9.44772 18 10C18 10.5523 18.4477 11 19 11H21V13C21 13.5523 21.4477 14 22 14C22.5523 14 23 13.5523 23 13V11H25C25.5523 11 26 10.5523 26 10C26 9.44772 25.5523 9 25 9H23V7Z"
            fill="#767BFA"
          />
        </svg>
      ),
      to: "/staff-pass",
    },
    {
      name: translate("eventsScanner"),
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M0.988039 9.94985C0.420445 9.94985 -0.0354527 9.47448 0.00217907 8.90814C0.427072 2.51364 2.51373 0.427047 8.90839 0.00217388C9.47474 -0.0354551 9.95011 0.420442 9.95011 0.988035C9.95011 1.52548 9.52535 1.96476 8.98914 2.00129C7.95281 2.07189 7.07422 2.18715 6.32547 2.35355C4.96931 2.65494 4.16477 3.09685 3.63081 3.63079C3.09685 4.16473 2.65495 4.96924 2.35355 6.32536C2.18716 7.07407 2.07189 7.95261 2.00129 8.98889C1.96476 9.52509 1.52548 9.94985 0.988039 9.94985Z"
            className={styles.activeWhite}
            fill={`${!dark ? "#ABAFD1" : "#5A5D74"}`}
          />
          <path
            d="M0.988033 13.9642C0.42044 13.9642 -0.0354567 14.4395 0.00217355 15.0059C0.427054 21.4004 2.51371 23.4871 8.90839 23.9119C9.47474 23.9496 9.95011 23.4937 9.95011 22.9261C9.95011 22.3886 9.52534 21.9493 8.98914 21.9128C7.95281 21.8422 7.07422 21.727 6.32547 21.5606C4.96931 21.2592 4.16477 20.8173 3.63081 20.2833C3.09685 19.7494 2.65495 18.9449 2.35355 17.5887C2.18715 16.84 2.07189 15.9614 2.00129 14.9251C1.96476 14.3889 1.52548 13.9642 0.988033 13.9642Z"
            className={styles.activeWhite}
            fill={`${!dark ? "#ABAFD1" : "#5A5D74"}`}
          />
          <path
            d="M22.9266 13.9642C22.3892 13.9642 21.9499 14.3889 21.9133 14.9251C21.8427 15.9614 21.7275 16.84 21.5611 17.5887C21.2597 18.9449 20.8178 19.7494 20.2838 20.2833C19.7499 20.8173 18.9453 21.2592 17.5892 21.5606C16.8404 21.727 15.9618 21.8422 14.9255 21.9128C14.3893 21.9493 13.9645 22.3886 13.9645 22.9261C13.9645 23.4937 14.4399 23.9496 15.0062 23.9119C21.4009 23.4871 23.4876 21.4004 23.9125 15.0059C23.9501 14.4395 23.4942 13.9642 22.9266 13.9642Z"
            className={styles.activeWhite}
            fill={`${!dark ? "#ABAFD1" : "#5A5D74"}`}
          />
          <path
            d="M24.0005 11.9895C24.0005 11.9787 24.0005 11.9679 24.0005 11.9571C24.0005 11.9462 24.0005 11.9354 24.0005 11.9246V11.9895Z"
            className={styles.activeWhite}
            fill={`${!dark ? "#ABAFD1" : "#5A5D74"}`}
          />
          <path
            d="M22.9266 9.94985C23.4942 9.94985 23.9501 9.47448 23.9125 8.90814C23.4876 2.51364 21.4009 0.427046 15.0062 0.00217339C14.4399 -0.0354554 13.9645 0.420442 13.9645 0.988034C13.9645 1.52548 14.3893 1.96476 14.9255 2.00129C15.9618 2.07189 16.8404 2.18715 17.5892 2.35355C18.9453 2.65494 19.7499 3.09685 20.2838 3.63079C20.8178 4.16473 21.2597 4.96924 21.5611 6.32536C21.7275 7.07407 21.8427 7.95261 21.9133 8.98889C21.9499 9.52509 22.3891 9.94985 22.9266 9.94985Z"
            className={styles.activeWhite}
            fill={`${!dark ? "#ABAFD1" : "#5A5D74"}`}
          />
          <path
            d="M11.9573 24L11.8587 23.9999H12.0559L11.9573 24Z"
            className={styles.activeWhite}
            fill={`${!dark ? "#ABAFD1" : "#5A5D74"}`}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17 11.8284C17 14.5898 14.7614 16.8284 12 16.8284C9.23858 16.8284 7 14.5898 7 11.8284C7 9.06695 9.23858 6.82837 12 6.82837C14.7614 6.82837 17 9.06695 17 11.8284ZM15 11.8284C15 13.4852 13.6569 14.8284 12 14.8284C10.3431 14.8284 9 13.4852 9 11.8284C9 10.1715 10.3431 8.82837 12 8.82837C13.6569 8.82837 15 10.1715 15 11.8284Z"
            fill="#767BFA"
          />
        </svg>
      ),
      to: "/scanner",
    },
    {
      name: translate("customers"),
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="20"
          viewBox="0 0 32 20"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11 5C11 7.76142 13.2386 10 16 10C18.7614 10 21 7.76142 21 5C21 2.23858 18.7614 0 16 0C13.2386 0 11 2.23858 11 5ZM13 5C13 6.65685 14.3431 8 16 8C17.6569 8 19 6.65685 19 5C19 3.34315 17.6569 2 16 2C14.3431 2 13 3.34315 13 5Z"
            className={styles.activeWhite}
            fill={`${!dark ? "#ABAFD1" : "#5A5D74"}`}
            fillOpacity="0.992157"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 16C8 19.294 9.412 20 16 20C22.588 20 24 19.294 24 16C24 12.706 22.588 12 16 12C9.412 12 8 12.706 8 16ZM10 16C10 16.7179 10.0837 17.0505 10.1512 17.2023C10.1828 17.2735 10.2156 17.3422 10.4239 17.4464C10.7033 17.5861 11.226 17.7432 12.1916 17.8505C13.1391 17.9557 14.3748 18 16 18C17.6252 18 18.861 17.9557 19.8084 17.8505C20.774 17.7432 21.2967 17.5861 21.5761 17.4464C21.7844 17.3422 21.8172 17.2735 21.8488 17.2023C21.9163 17.0505 22 16.7179 22 16C22 15.2821 21.9163 14.9495 21.8488 14.7977C21.8172 14.7265 21.7844 14.6578 21.5761 14.5536C21.2967 14.4139 20.774 14.2568 19.8084 14.1495C18.861 14.0443 17.6252 14 16 14C14.3748 14 13.1391 14.0443 12.1916 14.1495C11.226 14.2568 10.7033 14.4139 10.4239 14.5536C10.2156 14.6578 10.1828 14.7265 10.1512 14.7977C10.0837 14.9495 10 15.2821 10 16Z"
            className={styles.activeWhite}
            fill={`${!dark ? "#ABAFD1" : "#5A5D74"}`}
            fillOpacity="0.992157"
          />
          <path
            d="M23 2C25.4161 2 27 4.01382 27 6C27 7.98618 25.4161 10 23 10C22.4477 10 22 9.55228 22 9C22 8.44772 22.4477 8 23 8C24.1839 8 25 7.01382 25 6C25 4.98618 24.1839 4 23 4C22.4477 4 22 3.55228 22 3C22 2.44772 22.4477 2 23 2Z"
            fill="#767BFA"
          />
          <path
            d="M25 11C24.4477 11 24 11.4477 24 12C24 12.5523 24.4477 13 25 13C26.09 13 26.9736 13.0269 27.6898 13.1017C28.4126 13.1773 28.9042 13.2963 29.2388 13.4473C29.5496 13.5875 29.7053 13.7452 29.803 13.9246C29.9127 14.1262 30 14.4504 30 15C30 15.5456 29.9142 15.8938 29.8055 16.1192C29.7048 16.328 29.5639 16.476 29.3539 16.596C28.8612 16.8776 28.0144 17 26.6 17C26.0477 17 25.6 17.4477 25.6 18C25.6 18.5523 26.0477 19 26.6 19C27.9856 19 29.3388 18.9081 30.3461 18.3325C30.8861 18.024 31.3202 17.5827 31.607 16.9879C31.8858 16.4098 32 15.7401 32 15C32 14.2639 31.8873 13.5702 31.5595 12.9683C31.2197 12.3441 30.7004 11.9125 30.0612 11.6242C29.4458 11.3466 28.7124 11.1977 27.8977 11.1126C27.0764 11.0267 26.11 11 25 11Z"
            fill="#767BFA"
          />
          <path
            d="M9 2C6.58385 2 5 4.01382 5 6C5 7.98618 6.58385 10 9 10C9.55228 10 10 9.55228 10 9C10 8.44772 9.55228 8 9 8C7.81615 8 7 7.01382 7 6C7 4.98618 7.81615 4 9 4C9.55228 4 10 3.55228 10 3C10 2.44772 9.55228 2 9 2Z"
            fill="#767BFA"
          />
          <path
            d="M7 11C7.55228 11 8 11.4477 8 12C8 12.5523 7.55228 13 7 13C5.90995 13 5.02637 13.0269 4.31021 13.1017C3.58737 13.1773 3.09582 13.2963 2.76122 13.4473C2.45036 13.5875 2.29469 13.7452 2.19702 13.9246C2.08726 14.1262 2 14.4504 2 15C2 15.5456 2.08578 15.8938 2.19449 16.1192C2.29517 16.328 2.43611 16.476 2.64614 16.596C3.13885 16.8776 3.98563 17 5.4 17C5.95228 17 6.4 17.4477 6.4 18C6.4 18.5523 5.95228 19 5.4 19C4.01437 19 2.66115 18.9081 1.65386 18.3325C1.11389 18.024 0.679829 17.5827 0.393009 16.9879C0.114219 16.4098 0 15.7401 0 15C0 14.2639 0.112741 13.5702 0.440482 12.9683C0.780311 12.3441 1.29964 11.9125 1.93878 11.6242C2.55418 11.3466 3.28763 11.1977 4.10229 11.1126C4.92363 11.0267 5.89005 11 7 11Z"
            fill="#767BFA"
          />
        </svg>
      ),
      to: "/customers",
    },
    {
      name: translate("Wallet"),
      svg: (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17 11C16.4477 11 16 11.4477 16 12C16 12.5523 16.4477 13 17 13H19C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11H17Z"
            className={styles.activeWhite}
            fill={`${!dark ? "#ABAFD1" : "#5A5D74"}`}
          />
          <path
            d="M21.7694 7H17.0453C16.3827 7 15.7267 7.12933 15.1146 7.38062C14.5024 7.63187 13.9463 8.00018 13.4777 8.46448C13.0092 8.92877 12.6376 9.47995 12.3841 10.0866C12.1305 10.6932 12 11.3434 12 12C12 12.6566 12.1305 13.3068 12.3841 13.9134C12.6376 14.5201 13.0092 15.0712 13.4777 15.5355C13.9463 15.9998 14.5024 16.3681 15.1146 16.6194C15.7267 16.8707 16.3827 17 17.0453 17H21.7694C21.8716 16.4088 21.9476 15.7457 22 15H17.0453C16.6478 15 16.2541 14.9224 15.8869 14.7716C15.5196 14.6209 15.1859 14.3999 14.9048 14.1213C14.6237 13.8427 14.4007 13.512 14.2486 13.148C14.0964 12.7841 14.0181 12.394 14.0181 12C14.0181 11.606 14.0964 11.2159 14.2486 10.852C14.4007 10.488 14.6237 10.1573 14.9048 9.87869C15.1859 9.6001 15.5196 9.37912 15.8869 9.22836C16.2541 9.07761 16.6478 9 17.0453 9H22C21.9476 8.25433 21.8716 7.59122 21.7694 7Z"
            fill="#767BFA"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 12C0 21.882 2.118 24 12 24C21.882 24 24 21.882 24 12C24 2.118 21.882 0 12 0C2.118 0 0 2.118 0 12ZM2 12C2 14.4249 2.13254 16.2369 2.43771 17.6101C2.73783 18.9605 3.17768 19.7608 3.70846 20.2915C4.23924 20.8223 5.03949 21.2622 6.38993 21.5623C7.76307 21.8675 9.57515 22 12 22C14.4249 22 16.2369 21.8675 17.6101 21.5623C18.9605 21.2622 19.7608 20.8223 20.2915 20.2915C20.8223 19.7608 21.2622 18.9605 21.5623 17.6101C21.8675 16.2369 22 14.4249 22 12C22 9.57515 21.8675 7.76307 21.5623 6.38993C21.2622 5.03949 20.8223 4.23924 20.2915 3.70846C19.7608 3.17768 18.9605 2.73783 17.6101 2.43771C16.2369 2.13254 14.4249 2 12 2C9.57515 2 7.76307 2.13254 6.38993 2.43771C5.03949 2.73783 4.23924 3.17768 3.70846 3.70846C3.17768 4.23924 2.73783 5.03949 2.43771 6.38993C2.13254 7.76307 2 9.57515 2 12Z"
            className={styles.activeWhite}
            fill={`${!dark ? "#ABAFD1" : "#5A5D74"}`}
          />
        </svg>
      ),
      to: "/wallet",
    },
    {
      name: translate("settings"),
      svg: (
        <svg
          width="22"
          height="24"
          viewBox="0 0 22 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.60643 3.65554C6.55267 3.94423 6.25616 4.11326 5.98413 4.0171C1.30599 2.36352 -2.16181 8.42001 1.58941 11.6377C1.81102 11.8278 1.81102 12.1722 1.58941 12.3623C-2.16181 15.58 1.30599 21.6365 5.98413 19.9829C6.25616 19.8867 6.55267 20.0558 6.60643 20.3445C7.51399 25.2185 14.486 25.2185 15.3936 20.3445C15.4473 20.0558 15.7438 19.8867 16.0159 19.9829C20.694 21.6365 24.1618 15.58 20.4106 12.3623C20.189 12.1722 20.189 11.8278 20.4106 11.6377C24.1618 8.42001 20.694 2.36352 16.0159 4.0171C15.7438 4.11326 15.4473 3.94423 15.3936 3.65554C14.486 -1.21851 7.51399 -1.21851 6.60643 3.65554ZM8.57055 4.02202C9.07256 1.32599 12.9274 1.32599 13.4295 4.02202C13.7076 5.5159 15.2508 6.40856 16.6811 5.90298C19.2624 4.99056 21.1899 8.33543 19.1106 10.119C17.9584 11.1073 17.9584 12.8927 19.1106 13.881C21.1899 15.6646 19.2624 19.0094 16.6811 18.097C15.2508 17.5914 13.7076 18.4841 13.4295 19.978C12.9274 22.674 9.07256 22.674 8.57055 19.978C8.29239 18.4841 6.74923 17.5914 5.3189 18.097C2.73757 19.0094 0.810125 15.6646 2.88945 13.881C4.04161 12.8927 4.04161 11.1073 2.88945 10.119C0.810125 8.33543 2.73757 4.99056 5.3189 5.90298C6.74923 6.40856 8.29239 5.5159 8.57055 4.02202Z"
            className={styles.activeWhite}
            fill={`${!dark ? "#ABAFD1" : "#5A5D74"}`}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.00391 12C7.00391 14.2091 8.79293 16 10.9998 16C13.2067 16 14.9957 14.2091 14.9957 12C14.9957 9.79086 13.2067 8 10.9998 8C8.79293 8 7.00391 9.79086 7.00391 12ZM9.00186 12C9.00186 13.1046 9.89637 14 10.9998 14C12.1032 14 12.9978 13.1046 12.9978 12C12.9978 10.8954 12.1032 10 10.9998 10C9.89637 10 9.00186 10.8954 9.00186 12Z"
            fill="#767BFA"
          />
        </svg>
      ),
      to: "/settings",
    },
  ];

  return (
    <div className={styles.sidebarContainer}>
      <div className={styles.sidebar}>
        <div className={styles.logo}>
          <img src={logodark} alt="" />
          <span>Organiser’s Portal</span>
        </div>
        {Links.map((link, i) => (
          <Link
            className={`nav-link ${
              location.pathname === link.to ? styles.activeLink : ""
            }`}
            key={i}
            to={link.to}
          >
            <span>{link.svg}</span>
            {link.name}
          </Link>
        ))}
      </div>
    </div>
  );
}
