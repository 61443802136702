import styles from "../../styles/customers/CustomerStats.module.css";

export const StatDetails = ({
  iconSrc,
  header,
  subtext,
  headerStyle = "",
  left = false,
}) => {
  return (
    <div
      className={`${styles.stat} ${!left ? `jc-fs` : `jc-ce`}`}
      style={left ? { margin: 0 } : {}}
    >
      <img src={iconSrc} alt="stat icon" />
      <div className={`flex col ${left ? "al-st" : "al-ce"}`}>
        <div className={`${styles.header} ${headerStyle}`}>{header}</div>
        <div className={styles.subtext}>{subtext}</div>
      </div>
    </div>
  );
};
