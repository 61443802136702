import { useState } from "react";
import { Box, Fade, Modal } from "@mui/material";
import { useRecoilState, useRecoilValue } from "recoil";

import Close from "../../../assets/close.svg";
import CloseDark from "../../../assets/closedark.svg";
import { searchModalState } from "../../../recoil/modal";
import { darkModeState } from "../../../recoil/settings";
import translate from "../../../utils/temp/translate";

import { SearchInput } from "./SearchInput";
import SearchResults from "./SearchResults";

import modalStyles from "../../../styles/modals/Modal.module.css";

export default function SearchModal() {
  const dark = useRecoilValue(darkModeState);
  const [isOpen, toggleState] = useRecoilState(searchModalState);
  const [search, setSearch] = useState("");

  const style = {
    bgcolor: dark ? "#323A52" : "#F6F7FF",
    border: "none",
    borderRadius: "0.9375rem",
    boxShadow: "0rem 0rem 0.625rem rgba(0, 0, 0, 0.15)",
    color: !dark ? "#323A52" : "#F6F7FF",
    left: "50%",
    maxWidth: "40rem",
    outline: "none",
    padding: "1.25rem 0",
    position: "absolute",
    top: "5rem",
    transform: "translate(-50%, 0%)",
    width: "100%",
  };

  return (
    <div>
      <Modal
        className={`modal ${dark ? "dark" : ""}`}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isOpen}
      >
        <Fade in={isOpen}>
          <Box sx={style}>
            <div className={modalStyles.header}>
              <div>{translate("SearchYourEvents")}</div>
              <img
                onClick={() => {
                  toggleState(false);
                }}
                src={dark ? CloseDark : Close}
                alt=""
              />
            </div>
            <div>
              <SearchInput search={search} setSearch={setSearch} />
              <SearchResults
                search={search}
                dark={dark}
                toggleState={toggleState}
              />
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
