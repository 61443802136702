import { atom } from "recoil";

export const addPaymentMethodModalState = atom({
  default: false,
  key: "addPaymentMethodModalState",
});

export const cashOutModalState = atom({
  default: false,
  key: "cashOutModalState",
});

export const addEventModalState = atom({
  default: false,
  key: "addEventModalState",
});

export const searchModalState = atom({
  default: false,
  key: "searchModalState",
});

export const moreEventDetailsModalState = atom({
  default: false,
  key: "moreEventDetailsModalState",
});

export const acknowledgementModalState = atom({
  default: false,
  key: "acknowledgementModalState",
});

export const previewModalState = atom({
  default: false,
  key: "previewModalState",
});

export const bankRefetchKeyState = atom({
  default: 0,
  key: "bankRefetchKeyState",
});

export const payoutMethodRefetchKeyState = atom({
  default: 0,
  key: "payoutMethodRefetchKeyState",
});
