import React from "react";

import getEventStatus from "../../utils/formatDateFromTimestamp";
import getTimeAgo from "../../utils/getTimeDifference";

import styles from "../../styles/events/Banner.module.css";

export default function SeatDetails({ data }) {
  return (
    <div className={styles.seatDetails}>
      <QureyAndValue query="Sale Name" value={data.saleName} />
      <QureyAndValue query="Release Type" value={data.releaseType} />
      <QureyAndValue
        query="Selling starts"
        value={getEventStatus(
          data.saleStartDate,
          <span className={styles.active}>Started</span>
        )}
      />
      <QureyAndValue
        query="Selling end"
        value={getTimeAgo(
          data.saleEndDate,
          <span className={styles.active}>Ended</span>
        )}
      />
      <QureyAndValue query="Total tickets" value={data.ticketForSale} />
      <QureyAndValue
        query="Max. Tix Per Transaction"
        value={
          data.maxTicketPerTransaction > 0
            ? data.maxTicketPerTransaction
            : "No Limit"
        }
      />
    </div>
  );
}

const QureyAndValue = ({ query, value }) => {
  return (
    <div className={styles.seatDetailsItem}>
      <div className={styles.seatDetailsTitle}>{query}</div>
      <div className={styles.seatDetailsValue}>{value}</div>
    </div>
  );
};
